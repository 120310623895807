import * as C from '../constants';

const profileListUpdate = (arr) => ({
  type: C.UPDATE_PROFILE_BOOKMARKS,
  payload: arr,
});
const articleListUpdate = (arr) => ({
  type: C.UPDATE_ARTICLE_BOOKMARKS,
  payload: arr,
});

// Actions with dispatch
export const updateArticleBookmarks = (arr) => (dispatch) => {
  dispatch(articleListUpdate(arr));
};

export const updateProfileBookmarks = (arr) => (dispatch) => {
  dispatch(profileListUpdate(arr));
};
