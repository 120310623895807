import { Button, Flex, Grid, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useState } from "react";

const TestimonialsBlok = ({ blok }) => {
  const [gridCountLimit, setGridCountLimit] = useState(
    parseInt(blok.initialItemsToShow) || 6
  );
  const increaseValue = parseInt(blok.numberOfItemsToShowOnClick) || 3;

  return (
    <Flex flexDir="column" alignItems="center">
      <Grid
        {...storyblokEditable(blok)}
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
        gap={3}
        my={10}
        mx={[5, blok.pageMargins && "10%"]}
      >
        {blok.testimonials?.map((nestedBlok, index) => {
          if (index >= gridCountLimit) {
            return;
          }
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
      </Grid>
      {gridCountLimit < blok.testimonials?.length && (
        <Button
          size="sm"
          fontSize="sm"
          variant="solid"
          w="fit-content"
          px={10}
          py={5}
          mt={5}
          mb={10}
          onClick={() =>
            setGridCountLimit((current) => current + increaseValue)
          }
        >
          Show more
        </Button>
      )}
    </Flex>
  );
};

export default TestimonialsBlok;
