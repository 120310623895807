import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useState } from "react";

const EventsGridBlok = ({ blok }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Stack
      direction="column"
      {...storyblokEditable(blok)}
      mx={[5, blok.pageMargins && "10%"]}
    >
      <Text mb={5} fontWeight="bold" textStyle="h3">
        {blok.title}
      </Text>
      <Stack direction="row" mb={5}>
        <Flex
          bgColor={selectedTab === 0 ? "brand.400" : "white"}
          borderRadius={"20px"}
          h={"40px"}
          w={"fit-content"}
          borderWidth={1}
          borderColor={"brand.400"}
          p={5}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSelectedTab(0)}
          cursor="pointer"
        >
          <Text
            textStyle="button1"
            color={selectedTab === 0 ? "white" : "brand.400"}
          >
            Upcoming Events
          </Text>
        </Flex>
        <Flex
          bgColor={selectedTab === 1 ? "brand.400" : "white"}
          borderRadius={"20px"}
          h={"40px"}
          w={"fit-content"}
          borderWidth={1}
          borderColor={"brand.400"}
          p={5}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSelectedTab(1)}
          cursor="pointer"
        >
          <Text
            textStyle="button1"
            color={selectedTab === 1 ? "white" : "brand.400"}
          >
            Past Events
          </Text>
        </Flex>
      </Stack>
      {selectedTab === 0 ? (
        <Grid
          gridTemplateColumns={[
            "repeat(2, minmax(0, 1fr))",
            "repeat(5, minmax(0, 1fr))",
          ]}
          columnGap={5}
          rowGap={5}
        >
          {blok &&
            blok.upcomingEvents &&
            blok.upcomingEvents.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Grid>
      ) : (
        <Grid
          gridTemplateColumns={[
            "repeat(2, minmax(0, 1fr))",
            "repeat(5, minmax(0, 1fr))",
          ]}
          columnGap={5}
          rowGap={5}
        >
          {blok &&
            blok.pastEvents &&
            blok.pastEvents.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Grid>
      )}
    </Stack>
  );
};

export default EventsGridBlok;
