import {
  FormControl,
  FormErrorMessage,
  Select,
  Text,
} from "@chakra-ui/react";
import { Field, useField } from "formik";

const SelectInput = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <label htmlFor={props.formlabel} className='font-bold text-brand-text'>
        {props.required && (
          <Text display="inline-block" color="red">
            *
          </Text>
        )}
        {props.formlabel}
      </label>
      {props.description && (
        <Text textStyle="footer1" color="brand.200">
          {props.description}
        </Text>
      )}
      <Field className='mt-2' fontSize="inherit" as={Select} {...field} {...props}>
        {children}
      </Field>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default SelectInput;
