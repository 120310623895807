import { Icon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import { FaHandshake } from "react-icons/fa";
import {
  FaCertificate,
  FaComment,
  FaLightbulb,
  FaLock,
  FaStopwatch,
  FaTag,
} from "react-icons/fa6";

const colorMap = {
  black: "black",
  sandDune: "brand.200",
  green: "brand.400",
};

const iconMap = {
  handshake: FaHandshake,
  speechBubble: FaComment,
  padlock: FaLock,
  stopwatch: FaStopwatch,
  lightbulb: FaLightbulb,
  certificate: FaCertificate,
  tag: FaTag,
};

const IconTextBlok = ({ blok }) => {
  return (
    <Flex alignItems="center" px={5} columnGap={3} {...storyblokEditable(blok)}>
      <Icon
        mb={1}
        as={iconMap[blok.icon]}
        boxSize={10}
        color={colorMap[blok.colour] ?? "black"}
      />
      <Text>{blok.text}</Text>
    </Flex>
  );
};

export default IconTextBlok;
