import { Button, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import Link from "next/link";
import { useUtilityDataSelector } from "../utilities/contexts";
import { postData, deleteData } from "../utilities/axios";
import ArticleRecommendedTherapist from "./ArticleRecommendedTherapist";
import ArticleReviewPanel from "./ArticleReviewPanel";
import YouTube from "react-youtube";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileBookmarks } from "../redux/actions/bookmarks";
import * as N from "../../src/utilities/notifications";
import StoryblokClient from "storyblok-js-client";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const ArticleSearchInput = dynamic(() => import("./ArticleSearchInput"));

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
  region: "eu",
});

const searchSeed = Math.floor(Math.random() * 10000);

const ArticleSidebar = ({
  showReview = true,
  overrideVideoId,
  defaultVideoId = "sidebarVideoId",
  context = [],
}) => {
  const { therapistPracticeAreas, therapistPractitionerModalities } =
    useUtilityDataSelector((utilityData) => utilityData);

  const [recommendedTherapists, setRecommendedTherapists] = useState([]);

  const [videoId, setVideoId] = useState(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const toast = useToast();
  const user = useSelector((state) => state.user.userDetails);
  const token = useSelector((state) => state.user.JWTToken);
  const savedProfiles = useSelector((state) => state.bookmarks.profiles);

  useEffect(() => {
    const getTherapists = async (practiceAreas, practitionerModalities) => {
      const { results } = await postData(
        null,
        {
          practiceAreas,
          practitionerModalities,
          distance: 1000000,
          practitionerType: "therapist",
          resultIndex: 0,
          resultItems: 20,
          ignoreUserId: user?.id,
          orderBy: {
            ascending: true,
            value: null,
            seed: searchSeed,
          },
        },
        `${process.env.NEXT_PUBLIC_NEW_SEARCH_URL}search`,
        process.env.NEXT_PUBLIC_NEW_SEARCH_APIKEY
      );
      const therapists = [];
      if (results.length > 0) {
        therapists.push(
          results.splice(Math.floor(Math.random() * results.length), 1)[0]
        );
      }
      if (results.length > 0) {
        therapists.push(
          results.splice(Math.floor(Math.random() * results.length), 1)[0]
        );
      }
      setRecommendedTherapists(therapists);
    };

    let tempPracticeAreas = [];
    let tempPractitionerModalities = [];
    if (therapistPracticeAreas) {
      tempPracticeAreas = therapistPracticeAreas.filter((topic) => {
        if (context.includes(topic.label.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (therapistPractitionerModalities) {
      tempPractitionerModalities = therapistPractitionerModalities.filter(
        (topic) => {
          if (context.includes(topic.label.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        }
      );
    }
    getTherapists(tempPracticeAreas, tempPractitionerModalities);
  }, [user, therapistPracticeAreas, therapistPractitionerModalities]);

  useEffect(() => {
    const getVideoId = async () => {
      const datasourceEntries = await Storyblok.getAll(
        `cdn/datasource_entries`,
        {
          datasource: "sidebarvideoid",
        }
      );
      setVideoId(datasourceEntries[0].value);
    };
    getVideoId();
  }, [defaultVideoId]);

  const addToSavedList = (profile) => {
    const searchQuery = {
      profileId: profile.profileId,
      practitionerType: profile.practitionerType,
      profileImage: profile.profileImage,
      pageName: profile.pageName,
      fullname: profile.fullname,
    };

    try {
      postData(
        token,
        searchQuery,
        `${process.env.NEXT_PUBLIC_NEW_SEARCH_URL}save`,
        process.env.NEXT_PUBLIC_NEW_SEARCH_APIKEY
      ).then(() => {
        dispatch(updateProfileBookmarks([...savedProfiles, searchQuery]));
      });
    } catch (error) {
      console.error("ERROR saving bookmark", error);
      toast(N.genericFail("Failed to save bookmark"));
    }
  };

  const removeFromSavedList = (profile) => {
    const searchQuery = {
      profileId: profile.profileId,
      practitionerType: profile.practitionerType,
      profileImage: profile.profileImage,
      pageName: profile.pageName,
      fullname: profile.fullname,
    };

    try {
      deleteData(
        token,
        searchQuery,
        `${process.env.NEXT_PUBLIC_NEW_SEARCH_URL}save`,
        process.env.NEXT_PUBLIC_NEW_SEARCH_APIKEY
      ).then(() => {
        dispatch(
          updateProfileBookmarks(
            savedProfiles.filter(
              (elem) => Number(elem.profileId) !== Number(profile.profileId)
            )
          )
        );
      });
    } catch (error) {
      console.error("ERROR removing bookmark", error);
      toast(N.genericFail("Failed to remove bookmark"));
    }
  };

  const handleBookmarkClick = (profile) => {
    if (token !== null) {
      if (
        savedProfiles
          .map((therapist) => therapist.profileId)
          .includes(profile.profileId)
      ) {
        removeFromSavedList(profile);
      } else {
        addToSavedList(profile);
      }
    } else {
      router.push("/user-benefits");
    }
  };

  return (
    <Flex flex={1} direction="column" display={{ base: "none", xl: "flex" }}>
      <ArticleSearchInput />
      <ArticleReviewPanel showReview={showReview} />
      <Stack mt="60px" px={{ md: 0, xl: 0 }} w="100%" justifyContent="center">
        <YouTube
          opts={{ width: "100%" }}
          videoId={overrideVideoId ?? videoId}
        />
      </Stack>
      {recommendedTherapists.length > 0 && (
        <Flex mt="60px" direction="column" rowGap="20px">
          <Text textStyle="h3">Our therapists</Text>
          <Flex direction="column" w="100%" rowGap="15px">
            {recommendedTherapists.map((therapist) => (
              <ArticleRecommendedTherapist
                key={therapist?.pageName}
                image={therapist?.profileImage}
                name={therapist?.fullname}
                slug={therapist?.pageName}
                title={therapist?.title}
                isBookmarked={savedProfiles
                  .map((t) => t.profileId)
                  .includes(therapist.profileId)}
                onBookmarkClick={() => handleBookmarkClick(therapist)}
              />
            ))}
          </Flex>
          <Link href="/counselling" prefetch={false}>
            <Button w="100%" m={0}>
              Find more
            </Button>
          </Link>
        </Flex>
      )}
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        bgColor="brand.100"
        borderRadius="30px"
        py="40px"
        px="30px"
        rowGap="30px"
        mt="20px"
      >
        <Text textStyle="h5bold" textAlign="center">
          Sign up as a Welldoing user to claim your free Holly Health app (worth £38.99) and more
        </Text>
        <Link href="/user-benefits" prefetch={false}>
          <Button w="100%">Register</Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default ArticleSidebar;
