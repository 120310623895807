import { Flex, Link, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { Children, isValidElement } from "react";
import {
  MARK_BOLD,
  MARK_LINK,
  NODE_HEADING,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";
import YouTubeEmbed from "./YouTubeEmbedBlok";

const TextBlok = ({ blok, ...props }) => {
  const controlled = {
    ...props,
    ...blok,
  };
  return (
    <Flex
      color={controlled.colour ?? "black"}
      my={controlled.overrideSpacing ?? 5}
      {...storyblokEditable(blok)}
      flexDir={"column"}
      rowGap={5}
      px={[controlled.overrideMobilePadding ? 0 : 5, 0]}
      mx={[0, controlled.pageMargins && "10%"]}
    >
      {render(controlled.text, {
        blokResolvers: {
          ["youtubeEmbed"]: (props) => <YouTubeEmbed blok={props} />,
        },
        markResolvers: {
          [MARK_LINK]: (
            children,
            { linktype, href, target, anchor, uuid, custom }
          ) => (
            <Link
              prefetch={false}
              href={href}
              textDecor={"underline"}
              color={controlled.colour ?? "brand.400"}
            >
              {children}
            </Link>
          ),
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, { level }) => {
            if (level === 1) {
              return <h1 style={{ fontSize: "2.6rem" }}>{children}</h1>;
            } else if (level === 2) {
              return <h2 style={{ fontSize: "2.4rem" }}>{children}</h2>;
            } else if (level === 3) {
              return <h3 style={{ fontSize: "2rem" }}>{children}</h3>;
            } else if (level === 4) {
              return <h4 style={{ fontSize: "1.1rem" }}>{children}</h4>;
            } else if (level === 5) {
              return <h5 style={{ fontSize: "1.6rem" }}>{children}</h5>;
            } else if (level === 6) {
              return <h6 style={{ fontSize: "1.4rem" }}>{children}</h6>;
            } else {
              const Heading = `h${level}`;
              return <Heading>{children}</Heading>;
            }
          },
          [NODE_UL]: (children) => (
            <ul
              style={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {children}
            </ul>
          ),
          [NODE_OL]: (children) => (
            <ol
              style={{
                listStyleType: "decimal",
                listStylePosition: "inside",
              }}
            >
              {children}
            </ol>
          ),
          [NODE_LI]: (children) => {
            const childElements = Children.map(children, (child) => {
              if (isValidElement(child)) {
                if (child.type === "p") {
                  return (
                    <li key={child.key}>
                      <span>{child.props.children}</span>
                    </li>
                  );
                } else if (child.type === "h4") {
                  return (
                    <li key={child.key}>
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        {child.props.children}
                      </span>
                    </li>
                  );
                } else if (child.type === "ol") {
                  return (
                    <ol
                      style={{
                        listStyleType: "decimal",
                        listStylePosition: "inside",
                        marginBlockStart: "1em",
                        marginBlockEnd: "1em",
                        paddingInlineStart: "40px",
                      }}
                    >
                      <span>{child.props.children}</span>
                    </ol>
                  );
                } else if (child.type === "ul") {
                  return (
                    <ul
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                        marginBlockStart: "1em",
                        marginBlockEnd: "1em",
                        paddingInlineStart: "40px",
                      }}
                    >
                      <span>{child.props.children}</span>
                    </ul>
                  );
                }
              }
              return null;
            });

            return <>{childElements}</>;
          },
        },
      })}
    </Flex>
  );
};

export default TextBlok;
