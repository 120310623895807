import { storyblokEditable } from "@storyblok/react";

const TeaserBlok = ({ blok }) => {
  return (
    <h2 style={{ fontSize: 100, color: "black" }} {...storyblokEditable(blok)}>
      {blok.headline}
    </h2>
  );
};

export default TeaserBlok;
