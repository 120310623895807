import * as C from '../constants';

const DEFAULT_STATE = { list: [], recordCount: null };

export const profileList = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.INITIALISE_PROFILE_LIST:
      return {
        ...state,
        list: action.payload.list,
        recordCount: action.payload.recordCount,
      };
    case C.UPDATE_PROFILE_LIST:
      return {
        ...state,
        list: [...action.payload.list],
      };
    case C.UPDATE_RECORD_COUNT:
      return {
        ...state,
        recordCount: action.payload,
      };

    case C.RESET_PROFILE_LIST:
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
