import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import StoryblokClient from "storyblok-js-client";

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
  region: "eu",
});

const TypesOfTherapyBlok = ({ blok }) => {
  const [gridCountLimit, setGridCountLimit] = useState(15);
  const [filterBy, setFilterBy] = useState(null);
  const [alphabet, setAlphabet] = useState([]);
  const [letterHasItems, setLetterHasItems] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    Storyblok.get(`cdn/stories`, {
      starts_with: "types",
      sort_by: "name:asc",
      per_page: 100,
    })
      .then(({ data: { stories } }) => {
        setTypes(stories.filter((story) => story.slug !== "types-of-therapy"));
        const hasItems = [];
        stories.forEach((item) => {
          hasItems.push(item.name[0].toLowerCase());
        });
        setLetterHasItems(hasItems);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const az = [];
    for (let i = 97; i <= 122; i++) {
      az.push(String.fromCharCode(i));
    }
    setAlphabet(az);
  }, []);

  return (
    <Flex
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
      flexDir="column"
      alignItems="center"
    >
      <Grid
        gridTemplateColumns={[
          "repeat(13, minmax(0, 1fr))",
          "repeat(26, minmax(0, 1fr))",
        ]}
        gap={3}
        mt={10}
        w={"100%"}
      >
        {alphabet.length !== 0 &&
          alphabet.map((letter) => (
            <Text
              key={letter}
              fontWeight="bold"
              color={letterHasItems.includes(letter) ? "brand.400" : "grey"}
              cursor={letterHasItems.includes(letter) ? "pointer" : "default"}
              onClick={() => {
                if (letterHasItems.includes(letter)) {
                  setFilterBy(letter);
                }
              }}
            >
              {letter.toUpperCase()}
            </Text>
          ))}
      </Grid>
      <Grid
        gridTemplateColumns={[
          "repeat(2, minmax(0, 1fr))",
          "repeat(3, minmax(0, 1fr))",
        ]}
        gap={3}
        my={10}
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
      >
        {types
          .filter((item) => {
            if (filterBy !== null && item.name[0].toLowerCase() !== filterBy) {
              return;
            } else {
              return item;
            }
          })
          .map((item, index) => {
            if (index >= gridCountLimit) {
              return;
            }
            return (
              <Link key={item.slug} href={`types/${item.slug}`}>
                <Flex
                  borderWidth={1}
                  borderColor="brand.400"
                  borderRadius={10}
                  p={4}
                  h={{ base: 190, md: 175 }}
                  overflow="hidden"
                  flexDir="column"
                >
                  <Text
                    textStyle="h5"
                    fontWeight="bold"
                    mb={2}
                    color={"brand.400"}
                  >
                    {item.name}
                  </Text>
                  <Text textOverflow="ellipsis" noOfLines={4} color={"black"}>
                    {item.content.summary}
                  </Text>
                </Flex>
              </Link>
            );
          })}
      </Grid>
    </Flex>
  );
};

export default TypesOfTherapyBlok;
