import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";

const InsetTextBlok = ({ blok }) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="flex-start"
      bgColor="brand.100"
      borderRadius={[0, "30px"]}
      py="40px"
      px="30px"
      rowGap="30px"
      mt="20px"
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      <Text textStyle="h3">{blok.title}</Text>
      <div>
        {render(blok.body, {
          markResolvers: {
            [MARK_BOLD]: (children) => (
              <strong style={{ color: "#8c8988" }}>{children}</strong>
            ),
          },
        })}
      </div>
    </Flex>
  );
};

export default InsetTextBlok;
