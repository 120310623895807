import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Link,
  Stack,
} from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Children, isValidElement } from "react";
import {
  MARK_LINK,
  NODE_LI,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";

const col1 = [
  "Free certificated CPD and peer-to-peer support",
  "Online and phone customer support",
  "Publish posts and interviews",
  "Connect with new clients",
  "Secure client messaging",
  "Weekly newsletter",
  "Monthly therapist-only newsletter",
  "Press opportunities",
];

const col2 = [
  "Directory listing",
  "Personalised profile",
  "Professional membership verified annually",
  "Closed Facebook therapist group",
  "Promote events or workshops",
  "Optional diary and booking system",
  "Online client payment*",
];

const EditableBenefitsBlok = ({ blok }) => (
  <Flex
    {...storyblokEditable(blok)}
    flexDir={["column", "row"]}
    p={[5, 10]}
    px={[5, "10%"]}
    bgColor="rgba(247, 247, 248, 1)"
    gap={10}
    justifyContent="space-between"
  >
    <VStack w="33%" rowGap={3} alignItems="flex-start">
      <Text textStyle="h3" fontWeight="bold" color="brand.400">
        {blok.leftTitle}
      </Text>
      <Text textStyle="h3">{blok.leftSubtitle}</Text>
      <Stack alignItems="flex-start">
        {blok.leftBody &&
          blok.leftBody.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Stack>
    </VStack>
    <Flex width="66%" flexDir="column" rowGap={3}>
      <Flex
        flex={2}
        bgColor="white"
        flexDir="column"
        py={[5, 10]}
        px={[0, 10]}
        rowGap={0}
      >
        <Text textStyle="h3" px={[5, 0]}>
          {blok.rightTitle}
        </Text>
        <Flex>
          {blok.rightBody &&
            blok.rightBody.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Flex>
      </Flex>
      <Text alignSelf="flex-end">{blok.rightStrapText}</Text>
    </Flex>
  </Flex>
);
export default EditableBenefitsBlok;
