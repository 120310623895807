import { CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const col1 = [
  "Free certificated CPD and peer-to-peer support",
  "Online and phone customer support",
  "Publish posts and interviews",
  "Connect with new clients",
  "Secure client messaging",
  "Weekly newsletter",
  "Monthly therapist-only newsletter",
  "Press opportunities",
];

const col2 = [
  "Directory listing",
  "Personalised profile",
  "Professional membership verified annually",
  "Closed Facebook therapist group",
  "Promote events or workshops",
  "Optional diary and booking system",
  "Online client payment*",
];

const BenefitsBlok = ({ blok }) => (
  <Flex
    {...storyblokEditable(blok)}
    flexDir={["column", "row"]}
    p={[5, 10]}
    px={[5, "10%"]}
    bgColor="rgba(247, 247, 248, 1)"
    gap={10}
  >
    <VStack flex={1} rowGap={3} alignItems="flex-start">
      <Text textStyle="h3" fontWeight="bold" color="brand.400">
        Our benefits
      </Text>
      <Text textStyle="h3">We find jobs for verified therapists</Text>
      <Text>
        We are the UK's leading therapist-matching service for people seeking
        in-person and online therapy. We have been successfully matching people
        with therapists and counsellors since 2014. <br />
        <br /> When you join we verify your professional membership status and
        re-check this every year; this ensures that all our therapists are
        qualified.
      </Text>
    </VStack>
    <Flex flexDir="column" rowGap={3}>
      <Flex
        flex={2}
        bgColor="white"
        flexDir="column"
        py={[5, 10]}
        px={[0, 10]}
        rowGap={0}
      >
        <Text textStyle="h3" px={[5, 0]}>
          Standard benefits when you join Welldoing
        </Text>
        <Flex flexDir={["column", "row"]} columnGap={10} rowGap={5}>
          <VStack alignItems="flex-start">
            {col1.map((line) => (
              <HStack
                key={line}
                w="100%"
                borderBottomWidth={1}
                borderBottomColor={"sanddune.200"}
                borderStyle={"dashed"}
                py={3}
                px={[3, 0]}
              >
                <CheckIcon color="brand.200" />
                <Text>{line}</Text>
              </HStack>
            ))}
          </VStack>
          <VStack alignItems="flex-start">
            {col2.map((line) => (
              <HStack
                key={line}
                w="100%"
                borderBottomWidth={1}
                borderBottomColor={"sanddune.200"}
                borderStyle={"dashed"}
                py={3}
                px={[3, 0]}
              >
                <CheckIcon color="brand.200" />
                <Text>{line}</Text>
              </HStack>
            ))}
          </VStack>
        </Flex>
      </Flex>
      <Text alignSelf="flex-end">
        *online client payments taken through Stripe, subject to a 3.95% charge
        with minimum charge of £2.50 per transaction
      </Text>
    </Flex>
  </Flex>
);
export default BenefitsBlok;
