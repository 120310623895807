import { Flex, Image } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const ThreeImageBlok = ({ blok }) => {
  return (
    <Flex
      height={"100%"}
      minH={["300px", "400px"]}
      w={"100%"}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      columnGap={[3, 5]}
      {...storyblokEditable(blok)}
    >
      <Image
        borderRadius={10}
        h={["280px", "95%"]}
        w={"30%"}
        maxW={blok.imageMaxWidth}
        src={blok.image1.filename}
      />
      <Image
        borderRadius={10}
        w={"30%"}
        h={["280px", "95%"]}
        src={blok.image2.filename}
        alignSelf="flex-end"
        maxW={blok.imageMaxWidth}
      />
      <Image
        borderRadius={10}
        h={["280px", "95%"]}
        w={"30%"}
        maxW={blok.imageMaxWidth}
        src={blok.image3.filename}
      />
    </Flex>
  );
};
export default ThreeImageBlok;
