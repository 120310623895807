import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { IconEmail, IconLI } from "../../../styles/icons";
import { imageServiceTransformer } from "../../utilities/helpers";

const TeamMemberBlok = ({ blok }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const viewTeamMember = () => {
    onOpen();
  };

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        onClick={() => viewTeamMember()}
        {...storyblokEditable(blok)}
        flex={1}
        w={{ base: "100%" }}
      >
        <Box
          width={{ base: "90%", md: "100%" }}
          height="300px"
          overflow="hidden"
          position="relative"
          marginBottom={5}
          borderRadius={{ base: "20px", md: 0 }}
          bgColor="white"
        >
          <Image
            layout="fill"
            alt="practitioner photo"
            objectFit="cover"
            objectPosition="center"
            src={
              imageServiceTransformer(blok.image.filename, "160x300") ?? null
            }
          />
        </Box>
        <Text fontWeight={800} color="brand.200" fontSize={14}>
          {blok.title}
        </Text>
        <Text fontSize={18} fontWeight={800}>
          {blok.name}
        </Text>
        <Flex>
          {blok.linkedIn !== null &&
            blok.linkedIn !== undefined &&
            blok.linkedIn !== "" && (
              <Link href={blok.linkedIn} target="_blank" prefetch={false}>
                <IconButton
                  variant="outline"
                  color="primary.500"
                  aria-label={"LinkedIn"}
                  fontSize="20px"
                  p={0}
                  icon={<IconLI />}
                />
              </Link>
            )}
          {blok.email !== null &&
            blok.email !== undefined &&
            blok.email !== "" && (
              <Link
                href={`mailto:${blok.email}`}
                target="_blank"
                prefetch={false}
              >
                <IconButton
                  variant="outline"
                  color="primary.500"
                  aria-label={"Email"}
                  fontSize="20px"
                  p={0}
                  icon={<IconEmail />}
                />
              </Link>
            )}
        </Flex>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            bgColor="brand.50"
            borderRadius="100%"
            position="absolute"
            zIndex={2}
          />
          <ModalBody p={0}>
            <Flex
              justifyContent="flex-start"
              flexDir={{ base: "column", md: "row" }}
            >
              <Box
                width={{ base: "100%", md: "250px" }}
                height="400px"
                position="relative"
                overflow="hidden"
                borderLeftRadius={{ base: 0, md: "5px" }}
              >
                <Image
                  layout="fill"
                  alt="practitioner photo"
                  objectFit="cover"
                  objectPosition="center"
                  src={blok.image.filename}
                />
              </Box>
              <Flex
                flexDir="column"
                p={{ base: 5, md: 10 }}
                w={{ base: "100%", md: "60%" }}
              >
                <Text textStyle="h3">{blok.name}</Text>
                <Text color="brand.200" fontSize={14} fontWeight="bold">
                  {blok.title}
                </Text>
                <Text>{blok.bio}</Text>
                <Flex>
                  <Link href={blok.linkedIn} target="_blank" prefetch={false}>
                    <IconButton
                      variant="outline"
                      color="primary.500"
                      aria-label={"LinkedIn"}
                      fontSize="20px"
                      p={0}
                      icon={<IconLI />}
                    />
                  </Link>
                  <Link
                    href={`mailto:${blok.email}`}
                    target="_blank"
                    prefetch={false}
                  >
                    <IconButton
                      variant="outline"
                      color="primary.500"
                      aria-label={"Email"}
                      fontSize="20px"
                      p={0}
                      icon={<IconEmail />}
                    />
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TeamMemberBlok;
