import { lighten, getColor, mode } from '@chakra-ui/theme-tools';
import { theme as origTheme } from '@chakra-ui/react';

export default {
  variants: {
    solid: (props) => {
      // changing the toast success colour
      if (props.colorScheme !== 'green') {
        // use original default theme
        return origTheme.components.Alert.variants.solid(props);
      }
      return {
        container: {
          bg: 'primary.400',
        },
      };
    },
  },
};
