import * as C from '../constants';

const pmsSearchUpdate = (userDetails) => ({
  type: C.UPDATE_PMS_SEARCH,
  payload: userDetails,
});

// Actions with dispatch
export const updatePmsSearchDetails = (details) => (dispatch) => {
  dispatch(pmsSearchUpdate(details));
};

export const resetPms = () => dispatch => {
  dispatch({
    type: C.RESET_PMS_SEARCH,
  });
}
