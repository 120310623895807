import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { format } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import YouTube from "react-youtube";
import LogInModal from "../../authentication/LogInModal";
import { imageServiceTransformer } from "../../utilities/helpers";

const CPDInfoBlok = ({ blok, loginCTA, isUpcoming = false }) => {
  const token = useSelector((state) => state.user.JWTToken);
  const user = useSelector((state) => state.user.userDetails);
  const profile = useSelector(
    (state) => state?.user?.userDetails?.therapistProfile
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTherapistSignupModal, setShowTherapistSignupModal] =
    useState(false);

  const clickHandler = () => {
    if (token !== null || isUpcoming) {
      onOpen();
    } else {
      loginCTA();
    }
  };

  const {
    isOpen: isBuyTicketAlertOpen,
    onOpen: onBuyTicketAlertOpen,
    onClose: onBuyTicketAlertClose,
  } = useDisclosure();
  const cancelBuyTicketRef = useRef();

  const {
    isOpen: isNoSubRegisterOpen,
    onOpen: onNoSubRegisterOpen,
    onClose: onNoSubRegisterClose,
  } = useDisclosure();
  const noSubRegisterRef = useRef();

  const isInvalidMembership = useMemo(() => {
    if (!user || !profile) {
      return true;
    }
    return (
      !token ||
      user.accountType !== 2 ||
      !profile.paymentPlan ||
      profile.membershipStatus === "CANCELLED"
    );
  }, [token, user, profile]);

  return (
    <>
      <Flex
        flexDir="column"
        mx={[0, blok.pageMargins && "10%"]}
        w="250px"
        onClick={clickHandler}
        cursor="pointer"
        {...storyblokEditable(blok)}
      >
        <Box
          overflow="hidden"
          width="250px"
          borderRadius="10px"
          height="250px"
          position="relative"
          border="1px"
          borderColor="brand.300"
        >
          <Image
            src={imageServiceTransformer(blok.image.filename, "250x250")}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
        <Text fontWeight="bold" pt={5}>
          {blok.title}
        </Text>
        <Text>{blok.description}</Text>
        <Text>{blok.location}</Text>
        <Text>
          {isUpcoming
            ? format(new Date(blok.dateTime || null), "dd/MM/yyyy kk:mm")
            : format(new Date(blok.dateTime || null), "MMMM yyyy")}
        </Text>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            bgColor="brand.50"
            borderRadius="100%"
            position="absolute"
            zIndex={2}
          />
          <ModalBody p={0}>
            <Flex
              justifyContent="flex-start"
              flexDir={{ base: "column", md: "row" }}
            >
              {blok.videoId ? (
                <Stack
                  px={{ md: 0, xl: 0 }}
                  w="100%"
                  maxW={["100vw", "75vw"]}
                  justifyContent="center"
                  flex={1}
                >
                  <YouTube opts={{ width: "100%" }} videoId={blok.videoId} />
                </Stack>
              ) : (
                <Stack direction="row">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    minH="400px"
                  >
                    <Box
                      width={{ base: "100%", md: "250px" }}
                      height="100%"
                      position="relative"
                      overflow="hidden"
                      borderLeftRadius={{ base: 0, md: "5px" }}
                    >
                      <Image
                        layout="fill"
                        alt="practitioner photo"
                        objectFit="cover"
                        objectPosition="center"
                        src={blok.image.filename}
                      />
                    </Box>
                  </Stack>

                  <Flex
                    flexDir="column"
                    p={{ base: 5, md: 10 }}
                    w={{ base: "100%", md: "60%" }}
                  >
                    <Text textStyle="h2" mb={5}>
                      {blok.title}
                    </Text>
                    <Text>{blok.details}</Text>
                    {blok.registerLink && (
                      <div style={{ marginTop: "10px" }}>
                        {token === null && (
                          <Flex
                            direction="row"
                            w="100%"
                            justifyContent="space-between"
                          >
                            <Button
                              variant="outline"
                              onClick={() => {
                                onClose();
                                setShowTherapistSignupModal(true);
                              }}
                            >
                              Register
                            </Button>
                            {blok.showBuyTicket && (
                              <Button
                                w="fit-content"
                                variant="outline"
                                onClick={() => {
                                  onClose();
                                  onBuyTicketAlertOpen();
                                }}
                              >
                                Buy ticket
                              </Button>
                            )}
                          </Flex>
                        )}
                        {token !== null && !isInvalidMembership && (
                          <Link href={blok.registerLink} target="_blank">
                            <Button variant="outline">Register</Button>
                          </Link>
                        )}
                        {token !== null && isInvalidMembership && (
                          <Flex
                            direction="row"
                            w="100%"
                            justifyContent="space-between"
                          >
                            <Button
                              variant="outline"
                              onClick={() => {
                                onClose();
                                onNoSubRegisterOpen();
                              }}
                            >
                              Register
                            </Button>
                            {blok.showBuyTicket && (
                              <Button
                                w="fit-content"
                                variant="outline"
                                onClick={() => {
                                  onClose();
                                  onBuyTicketAlertOpen();
                                }}
                              >
                                Buy ticket
                              </Button>
                            )}
                          </Flex>
                        )}
                      </div>
                    )}
                  </Flex>
                </Stack>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isBuyTicketAlertOpen}
        leastDestructiveRef={cancelBuyTicketRef}
        onClose={onBuyTicketAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>
            <AlertDialogBody>
              All CPD and community sessions are included in Welldoing
              membership.<br></br>
              <br></br>
              Please note that all events are only for professional counsellors
              and therapists.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex
                direction="row"
                w="100%"
                justifyContent={token !== null ? "center" : "space-between"}
              >
                {token === null && (
                  <Button
                    onClick={() => {
                      onBuyTicketAlertClose();
                      setShowTherapistSignupModal(true);
                    }}
                    ml={3}
                  >
                    Sign up
                  </Button>
                )}
                <Link href={blok.buyTicketLink} target="_blank">
                  <Button ml={3}>Continue to Eventbrite</Button>
                </Link>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isNoSubRegisterOpen}
        leastDestructiveRef={noSubRegisterRef}
        onClose={onNoSubRegisterClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Subscription required
            </AlertDialogHeader>
            <AlertDialogBody>
              All CPD and community sessions are included in Welldoing
              membership.<br></br>
              <br></br>
              Please note that all events are only for professional counsellors
              and therapists.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex direction="row" w="100%" justifyContent="center">
                <Link
                  href={{
                    pathname: "accounts/common-account/account-settings",
                  }}
                >
                  <Button variant="outline">Go to account subscriptions</Button>
                </Link>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {showTherapistSignupModal && (
        <LogInModal
          title=""
          onCloseModal={() => {
            setShowTherapistSignupModal(false);
            onBuyTicketAlertClose();
            onClose();
          }}
          signUpChoice="therapist"
          noRedirect={true}
        />
      )}
    </>
  );
};

export default CPDInfoBlok;
