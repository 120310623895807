import { Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const PullQuoteBlok = ({ blok }) => {
  return (
    <Text
      textStyle="h3"
      style={{ color: "green", fontStyle: "italic" }}
      px={[5, 0]}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      {blok.text}
    </Text>
  );
};

export default PullQuoteBlok;
