import * as C from '../constants';

const incPmsSearchStep = () => ({
  type: C.INCREMENT_PMS_SEARCH_STEP,
});

export const decrementPmsSearchStep = () => ({
  type: C.DECREMENT_PMS_SEARCH_STEP,
});

const setLayoutAction = (val) => ({
  type: C.SET_LAYOUT,
  payload: val,
});

const layoutReset = () => ({
  type: C.RESET_LAYOUT,
});

export const incrementPmsSearchStep = () => (dispatch) => {
  dispatch(incPmsSearchStep());
};

export const setLayout = (val) => (dispatch) => {
  dispatch(setLayoutAction(val));
};

export const resetLayout = () => (dispatch) => {
  dispatch(layoutReset());
};
