import { Flex, Stack } from "@chakra-ui/react";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";
import NestingWrapperBlok from "./NestingWrapperBlok";
import { imageServiceTransformer } from "../../utilities/helpers";

const ReversibleTextImagePairBlok = ({ blok }) => {
  return (
    <Flex
      flexDir={{
        base: "column",
        md: blok.flip ? "row-reverse" : "row",
      }}
      justifyContent="space-between"
      columnGap={10}
      rowGap={10}
      my={10}
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      <Stack>
        <NestingWrapperBlok bloks={blok.body} />
      </Stack>
      <div className="w-full md:w-[45%] min-h-[300px] relative">
        <Image
          className="rounded-2xl"
          src={imageServiceTransformer(
            blok?.image.filename,
            "485x300/filters:quality(60)"
          )}
          alt={blok?.image.alt}
          fill
          objectFit="fill"
          objectPosition="50% 50%"
          priority
          fetchPriority="high"
        />
      </div>
    </Flex>
  );
};

export default ReversibleTextImagePairBlok;
