import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const ImpactTextBlok = ({ blok }) => {
  return (
    <Flex flexDir="column" {...storyblokEditable(blok)} my={5}>
      <Text
        textStyle="h2"
        color={blok.colour ?? "brand.400"}
        textAlign={"center"}
      >
        {blok.mainText}
      </Text>
      <Text textAlign={"center"} color={blok.subTextColour ?? "black"}>
        {blok.subText}
      </Text>
    </Flex>
  );
};

export default ImpactTextBlok;
