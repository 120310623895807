import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import { imageServiceTransformer } from "../../utilities/helpers";

const RecommendedOfTheMonthBlok = ({ blok }) => {
  return (
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      align={["center", "flex-start"]}
      bgColor="brand.100"
      borderRadius={[0, "30px"]}
      py="40px"
      px="30px"
      columnGap={5}
      rowGap={5}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      {blok.bookImage && (
        <Box
          overflow="hidden"
          width={["100%", "175px"]}
          minW="175px"
          borderRadius="10px"
          height={blok.isApp ? "175px" : "300px"}
          position="relative"
          border="1px"
          borderColor="brand.300"
        >
          <Image
            src={imageServiceTransformer(blok.bookImage.filename, "173x298")}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
      )}
      {blok.authorImage && (
        <Box
          overflow="hidden"
          width={["100%", "175px"]}
          minW="175px"
          borderRadius="10px"
          height={blok.isApp ? "175px" : "300px"}
          position="relative"
          border="1px"
          borderColor="brand.300"
        >
          <Image
            src={imageServiceTransformer(blok.authorImage.filename, "173x298")}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
      )}
      <Flex flexDir="column" height="100%" justifyContent="flex-start">
        <Text textStyle="h2" mb={5}>
          {blok.header}
        </Text>
        <Text textStyle="h5">{blok.title}</Text>
        <Text color="brand.200">{blok.secondaryDetails}</Text>
        <Text mt={5}>{blok.description}</Text>
        {blok.readMore && (
          <Flex
            alignSelf="flex-end"
            justifySelf="flex-end"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexGrow={1}
          >
            <Link prefetch={false} href={`${blok.readMore.cached_url}`}>
              <Button w="fit-content">
                <Text textStyle="button1">Read more</Text>
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default RecommendedOfTheMonthBlok;
