import { Box, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const DividerWithTextBlok = ({ blok }) => {
  return (
    <Flex
      flexDir="row"
      {...storyblokEditable(blok)}
      alignItems="center"
      columnGap={2}
      maxW={blok.maxWidth}
    >
      <Box
        w={"100%"}
        opacity={0.3}
        backgroundColor={"brand.300"}
        height={"1px"}
      />
      <Text color={blok.colour ?? "text.50"} textAlign={"center"}>
        {blok.text}
      </Text>
      <Box
        opacity={0.3}
        w={"100%"}
        backgroundColor={"brand.300"}
        height={"1px"}
      />
    </Flex>
  );
};

export default DividerWithTextBlok;
