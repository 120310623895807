import { SimpleGrid, Stack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import YouTube from "react-youtube";

const HeroBlok = ({ blok }) => {
  return (
    <SimpleGrid
      bgImage={blok.backgroundImage.filename}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgColor={blok.backgroundColor}
      columns={[1, 1, 2]}
      spacing={10}
      px={[3, blok.pageMargins ? "10%" : 10]}
      py={10}
      h={"fit-content"}
      mb={10}
      alignItems="center"
      {...storyblokEditable(blok)}
    >
      <Stack
        justifyContent="center"
        alignItems={["center", "flex-start"]}
        flex={2}
      >
        {blok.body &&
          blok.body.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Stack>
      {blok.videoId && (
        <YouTube opts={{ width: "100%" }} videoId={blok.videoId} />
      )}
    </SimpleGrid>
  );
};

export default HeroBlok;
