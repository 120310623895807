import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {getUserObject} from "../redux/actions/user";
import {getSession} from "../authentication/api/login";
import {updateUserJWT} from "../redux/actions/user";
import {useRouter} from "next/router";

let fetching = false;

export const useUser = (redirect = null) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const token = useSelector((state) => state.user.JWTToken);
  const [loadedSession, setLoadedSession] = useState(false);

  const getUser = useCallback(async (token) => {
    await dispatch(getUserObject(token));
    setLoadedSession(true);
    fetching = false;
  }, [dispatch, setLoadedSession])

  useEffect(() => {
    // Actually get the session when loading the page, relying on redux persist is a nightmare...
    if (!loadedSession && !fetching) {
      fetching = true;
      getSession().then((res) => {
        if (res) {
          dispatch(updateUserJWT(res.idToken.jwtToken));
          if(!user) {
            getUser(res.idToken.jwtToken);
          } else {
            setLoadedSession(true);
            fetching = false;
          }
        } else {
          fetching = false;
          if(redirect) {
            router.replace(redirect);
          } else {
            setLoadedSession(true);
          }
        }
      }).catch(e => {
        fetching = false;
        if(redirect) {
          router.replace(redirect);
        }
        else {
          setLoadedSession(true);
        }
      });
    }
  }, [loadedSession, router, dispatch, getUser, user, redirect]);

  return [loadedSession, token, user];
}
