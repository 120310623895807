import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import YouTube from "react-youtube";
import { imageServiceTransformer } from "../../utilities/helpers";

const RecommendedItemBlok = ({ blok }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        flexDir="column"
        mx={[0, blok.pageMargins && "10%"]}
        w="170px"
        cursor="pointer"
        onClick={onOpen}
        {...storyblokEditable(blok)}
      >
        <Box
          overflow="hidden"
          width="170px"
          borderRadius="10px"
          height={blok.isApp ? "170px" : "250px"}
          position="relative"
          border="1px"
          borderColor="brand.300"
        >
          <Image
            src={imageServiceTransformer(
              blok.image.filename,
              "168x248/filters:quality(80)"
            )}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
        <Text fontWeight="bold" pt={5}>
          {blok.title}
        </Text>
        <Text color="brand.200">{blok.secondaryDetails}</Text>
        <Link
          prefetch={false}
          href={blok.link || blok.internalLink}
          legacyBehavior
          target="_blank"
        >
          <Text color="brand.400" cursor="pointer">
            Details
          </Text>
        </Link>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            bgColor="brand.50"
            borderRadius="100%"
            position="absolute"
            zIndex={2}
          />
          <ModalBody p={0}>
            <Flex
              justifyContent="flex-start"
              flexDir={{ base: "column", md: "row" }}
            >
              {blok.videoId ? (
                <Stack
                  px={{ md: 0, xl: 0 }}
                  w="100%"
                  maxW={["100vw", "75vw"]}
                  justifyContent="center"
                  flex={1}
                >
                  <YouTube opts={{ width: "100%" }} videoId={blok.videoId} />
                </Stack>
              ) : (
                <Stack direction="row">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    minH="400px"
                  >
                    <Box
                      width={{ base: "100%", md: "250px" }}
                      height="100%"
                      position="relative"
                      overflow="hidden"
                      borderLeftRadius={{ base: 0, md: "5px" }}
                    >
                      <Image
                        layout="fill"
                        alt="practitioner photo"
                        objectFit="cover"
                        objectPosition="center"
                        src={blok.image.filename}
                      />
                    </Box>
                  </Stack>

                  <Flex
                    flexDir="column"
                    p={{ base: 5, md: 10 }}
                    w={{ base: "100%", md: "60%" }}
                  >
                    <Text textStyle="h2" mb={5}>
                      {blok.title}
                    </Text>
                    <Text color="brand.200">{blok.secondaryDetails}</Text>
                    <Text>{blok.description}</Text>
                    {blok.link && (
                      <Link prefetch={false} href={blok.link} target="_blank">
                        <Button variant="outline">Link</Button>
                      </Link>
                    )}
                  </Flex>
                </Stack>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RecommendedItemBlok;
