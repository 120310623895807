import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { format } from "date-fns";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";

const ArticlePreviewBlok = ({ blok }) => {
  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      py={5}
      mt={5}
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
      columnGap={[1, 5]}
      borderTopWidth={1}
      borderTopColor={"brand.100"}
    >
      <Flex
        direction="column"
        alignItems="flex-start"
        justifyContent="space-between"
        rowGap={2}
        flex={1}
      >
        <Flex direction="row">
          {blok.isPress ? (
            <Text fontWeight="bold" color="brand.400">
              {blok.pressSource}
            </Text>
          ) : (
            <>
              <Text fontWeight="bold" color="brand.200">
                {blok.author}
              </Text>
              <Text color="brand.200">
                &nbsp;•&nbsp;{format(new Date(blok.date), "MMMM yyyy")}
              </Text>
            </>
          )}
        </Flex>
        <Text fontWeight="bold" textStyle="h3" textOverflow="ellipsis">
          {blok.title}
        </Text>
        <Box display={["none", "block"]}>
          {render(blok.description, {
            markResolvers: {
              [MARK_BOLD]: (children) => (
                <strong style={{ color: "#8c8988" }}>{children}</strong>
              ),
            },
          })}
        </Box>
        {blok.isPress && (
          <Flex direction="row">
            <Text fontWeight="bold" color="brand.200">
              {blok.author}
            </Text>
            <Text color="brand.200">
              &nbsp;•&nbsp;{format(new Date(blok.date), "MMMM yyyy")}
            </Text>
          </Flex>
        )}
      </Flex>
      <Image
        borderRadius={[10, 10]}
        w={["100px", "30%"]}
        h={["100px", "25%"]}
        // flex={[0.5, 0.3]}
        src={blok.image.filename}
      />
    </Flex>
  );
};

export default ArticlePreviewBlok;
