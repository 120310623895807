import Image from 'next/image';
/* Re-exporting to make it easy switch out icons */
import { AiFillStar } from "react-icons/ai";
import { BiLinkAlt } from "react-icons/bi";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsArrowRightCircleFill,
  BsCalendarWeekFill,
  BsCheckLg,
  BsCreditCardFill,
  BsEyeFill,
  BsEyeSlashFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
  BsGraphUp,
  BsTelephoneFill,
  BsTag,
} from "react-icons/bs";
import { CgPlayListCheck, CgPlayListRemove, CgReorder } from "react-icons/cg";
import {
  FaBookmark,
  FaFacebookF,
  FaGift,
  FaHandshake,
  FaInstagram,
  FaLeaf,
  FaLinkedinIn,
  FaPeopleArrows,
  FaRegBookmark,
  FaXTwitter,
  FaYoutube,
  FaRegCircleQuestion,
} from "react-icons/fa6";

import {
  FaHandsHelping,
  FaRegMoneyBillAlt,
} from 'react-icons/fa';

import { FcGoogle } from "react-icons/fc";
import {
  FiAlertCircle,
  FiClock,
  FiExternalLink,
  FiSettings,
} from "react-icons/fi";
import { GoBook } from "react-icons/go";
import { GrCertificate } from "react-icons/gr";
import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
} from "react-icons/hi";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import {
  IoMdBriefcase,
  IoMdCheckbox,
  IoMdClose,
  IoMdHeart,
  IoMdHeartEmpty,
  IoMdMenu,
} from "react-icons/io";

import {
  IoCameraOutline,
  IoChatbubbleOutline,
  IoFlagOutline,
  IoLocationSharp,
  IoPerson,
} from "react-icons/io5";
import {
  MdAdd,
  MdAutorenew,
  MdDashboardCustomize,
  MdDeleteForever,
  MdEmail,
  MdMoreHoriz,
  MdOutlineBook,
  MdOutlineComment,
  MdOutlineEdit,
  MdPeople,
  MdSearch,
  MdVideoCameraFront,
} from "react-icons/md";
import { TbDisabled, TbDisabledOff } from "react-icons/tb";
import { SiTrustpilot } from "react-icons/si";
const MobileImg = require("../public/static/icons/Mobile.svg");

export const IconFamily = ({ height=24, width=24}) => {
  return <Image
            src="static/icons/Families.png"
            height={height}
            width={width}
            alt="Family Icon"
          />;
};
export const IconChildren = ({ height=24, width=24}) => {
  return (
    <Image
      src="static/icons/Children.png"
      layout="fill"
      height={height}
      width={width}
      alt="Children Icon"
    />
  );
};
export const IconAdult = ({ height=24, width=24}) => {
  return (
    <Image
      src="static/icons/Man.png"
      height={height}
      width={width}
      color="purple"
      alt="Adult Icon"
    />
  );
};

export const IconAdolescents = ({ height=24, width=24}) => {
  return (
    <Image
      src="static/icons/Adolescents.png"
      layout="fill"
      height={height}
      width={width}
      alt="Adolescents Icon"
    />
  );
};
export const IconCouple = ({ height=24, width=24}) => {
  return <Image
            src="static/icons/Couple.png"
            height={height}
            width={width}
            alt="Couple Icon"
          />;
};
export const IconCompany = ({ height=24, width=24}) => {
  return <Image
            src="static/icons/Company.png"
            height={height}
            width={width}
            alt="Company Icon"
          />;
};
export const IconGroup = ({ height=24, width=24}) => {
  return <Image
            src="static/icons/Group.png"
            height={height}
            width={width}
            alt="Group Icon"
          />;
};
export const IconMobile = ({ height=24, width=24}) => {
  return <Image
            src="static/icons/Mobile.svg"
            height={height}
            width={width}
            alt="Mobile Icon"
          />;
};

export { FiAlertCircle as IconAlertCircle };
export { MobileImg as IconMobile2 };
export { AiFillStar as IconStar };
export { BiLinkAlt as IconLink };
export { BsEyeFill as IconEyeOpen };
export { BsEyeSlashFill as IconEyeClosed };
export { CgReorder as IconOrderBy };
export { ImArrowRight2 as IconRightArrow };
export { ImArrowLeft2 as IconLeftArrow };
export { IoMdClose as IconCross };
//export { IoCalendarNumberOutline as IconCalendar };
export { BsCheckLg as IconCheck };
export { BsArrowRightCircle as IconRightArrowCircle };
export { BsArrowRightCircleFill as IconRightArrowCircleFill };
export { BsArrowLeftCircle as IconLeftArrowCircle };
export { BsCalendarWeekFill as IconCalendar };
export { BsCreditCardFill as IconCreditCard };
export { BsGraphUp as IconGraph };
export { BsTelephoneFill as IconTelephone };
export { BsFillPatchCheckFill as IconVerified };
export { BsFillPlayFill as IconPlayVideo };
export { BsTag as IconTag };
export { CgPlayListCheck as IconAddNotes };
export { CgPlayListRemove as IconListRemove };
export { FaRegBookmark as IconBookmark };
export { FaBookmark as IconBookmarkFilled };
export { FaGift as IconGift };
export { FaPeopleArrows as IconMeetingArrows };
export { FaRegMoneyBillAlt as IconPayment };
export { FaLeaf as IconWelldoing };
export { FiExternalLink as IconExternal };
export { IoChatbubbleOutline as IconChat };
export { IoMdBriefcase as IconCoach };
export { IoMdCheckbox as IconChecked };
export { IoLocationSharp as IconLocationPin };
export { IoPerson as IconPerson };
export { IoCameraOutline as IconCamera };
export { IoFlagOutline as IconFlag };
export { IoMdHeart as IconHeartFilled };
export { IoMdHeartEmpty as IconHeartEmpty };
export { FaInstagram as IconIG };
export { FaHandsHelping as IconTherapist };
export { FaXTwitter as IconTwitter };
export { FaYoutube as IconYT };
export { FaFacebookF as IconFB };
export { FaLinkedinIn as IconLI };
export { FiSettings as IconSettings };
export { FiClock as IconClock };
export { FcGoogle as IconGoogle };
export { SiTrustpilot as IconTrustPilot };
export { FaHandshake as IconNavBarHandShake };
export { GoBook as IconNavBarHub };
export { GrCertificate as IconCertificate };
export { HiChevronUp as IconChevronUp };
export { HiChevronDown as IconChevronDown };
export { HiChevronLeft as IconChevronLeft };
export { HiChevronRight as IconChevronRight };
export { IoMdMenu as IconMenuHamburger };
export { MdAutorenew as IconRenew };
export { MdAdd as IconAdd };
export { MdDashboardCustomize as IconDashboard };
export { MdDeleteForever as IconDelete };
export { MdMoreHoriz as IconMenuMore };
export { MdEmail as IconEmail };
export { MdVideoCameraFront as IconOnline };
export { MdOutlineComment as IconLanguages };
export { MdOutlineBook as IconExperience };
export { MdOutlineEdit as IconEdit };
export { MdPeople as IconPeople };
export { MdSearch as IconSearch };
export { TbDisabled as IconDisabled };
export { TbDisabledOff as IconDisabledOff };
export { FaRegCircleQuestion as IconInfo};
