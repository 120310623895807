import { Select, Stack, Flex, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Icon } from "@chakra-ui/icons";
import Link from "next/link";
import {
  FaMagnifyingGlass,
  FaCertificate,
  FaComment,
  FaLightbulb,
  FaLock,
  FaStopwatch,
  FaTag,
  FaHandshake,
} from "react-icons/fa6";
import { useState } from "react";

const iconMap = {
  magnifyingGlass: FaMagnifyingGlass,
  handshake: FaHandshake,
  speechBubble: FaComment,
  padlock: FaLock,
  stopwatch: FaStopwatch,
  lightbulb: FaLightbulb,
  certificate: FaCertificate,
  tag: FaTag,
};

const PracticeAreaSelectBlok = ({ blok }) => {
  const [practiceArea, setPracticeArea] = useState(null);
  return (
    <Stack
      {...storyblokEditable(blok)}
      direction={blok.reverseOrder ? "column-reverse" : "column"}
    >
      <Select
        bgColor={"white"}
        placeholder={blok.placeholder}
        maxW={blok.maxWidth}
        borderColor={blok.borderColor ?? "lightGrey"}
        borderWidth={1}
        fontSize={"medium"}
        onChange={(e) => setPracticeArea(e.target.value)}
      >
        {blok.options &&
          blok.options.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Select>
      <Link
        prefetch={false}
        href={`/counselling${practiceArea !== null ? `?practiceArea=${practiceArea}` : ""}`}
        {...storyblokEditable(blok)}
      >
        <Flex
          bgColor={blok.backgroundColour ?? "brand.200"}
          borderRadius={blok.squareVariant ? "5px" : "20px"}
          h={blok.squareVariant ? "45px" : "40px"}
          w={blok.expandWidth || blok.squareVariant ? "100%" : "fit-content"}
          maxW={blok.maxWidth}
          p={5}
          justifyContent="center"
          alignItems="center"
          borderColor={blok.btnBorderColor}
          borderWidth={blok.borderWidth ?? 0}
          borderStyle="solid"
        >
          {blok.icon && (
            <Icon
              mr={2}
              as={iconMap[blok.icon]}
              boxSize={4}
              color={blok.iconColor ?? "black"}
            />
          )}
          <Text
            noOfLines={1}
            textStyle="button1"
            color={blok.textColor ?? "white"}
          >
            {blok.text}
          </Text>
        </Flex>
      </Link>
    </Stack>
  );
};

export default PracticeAreaSelectBlok;
