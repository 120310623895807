import { lighten, getColor, mode } from '@chakra-ui/theme-tools';
import theme from '../colors';

const borderColor = theme.brand[400];

export default {
  parts: ['control'],
  variants: {
    primary: {
      control: {
        border: '1px solid',
        borderColor: borderColor,
        _checked: {
          bg: 'white',
          color: borderColor,
          _hover: {
            bg: 'white',
          },
        },
      },
    },
  },
  defaultProps: {},
};
