import { Image, SimpleGrid } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const SponsorGridBlok = ({ blok }) => {
  return (
    <SimpleGrid
      templateColumns={["1fr 1fr", "1fr 50px 1fr 1fr 1fr 50px"]}
      spacing={[5, 7]}
      mx={[5, "20%"]}
      {...storyblokEditable(blok)}
    >
      <Image
        h={"50px"}
        w={"100%"}
        fit="contain"
        src={"/images/homepage/Times.png"}
      />
      <Image
        h={"50px"}
        w={"100%"}
        fit="contain"
        src={"/images/homepage/C4.png"}
      />
      <Image
        h={"50px"}
        w={"100%"}
        fit="contain"
        src={"/images/homepage/Stylist.png"}
      />
      <Image
        h={"50px"}
        w={"100%"}
        fit="contain"
        src={"/images/homepage/DailyMail.png"}
      />
      <Image
        h={"50px"}
        w={"100%"}
        fit="contain"
        src={"/images/homepage/Telegraph.png"}
      />
      <Image
        h={"50px"}
        fit="contain"
        w={"100%"}
        src={"/images/homepage/Red.png"}
      />
    </SimpleGrid>
  );
};

export default SponsorGridBlok;
