import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const BreadcrumbWrapperBlok = ({ blok }) => {
  return (
    <Breadcrumb
      spacing={"8px"}
      separator={<ChevronRightIcon color="brand.400" />}
      {...storyblokEditable(blok)}
      mx={[5, blok.pageMargins && "10%"]}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <BreadcrumbItem
            isCurrentPage={nestedBlok.isCurrentPage}
            color="brand.400"
            key={nestedBlok._uid}
          >
            <BreadcrumbLink
              noOfLines={1}
              textOverflow="ellipsis"
              href={nestedBlok.link.url}
              fontSize="10pt"
            >
              {nestedBlok.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
export default BreadcrumbWrapperBlok;
