import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Box,
  Text,
} from "@chakra-ui/react";

const LoadingSpinner = ({ message = null, absolute = false }) => {
  const scrim = {
    position: absolute ? "absolute" : "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    bg: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box sx={scrim}>
      <Box
        mx="auto"
        my="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text mt="16px" textStyle="h5" color="white">
          {message}
        </Text>
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
