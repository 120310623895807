import * as C from "../constants";

const emptyPms = {
  fullname: "",
  yob: null,
  age: null,
  email: "",
  gender: [],
  otherGenderIdentification: "",
  phone: null,
  symptoms: "",
  occupation: "",
  practitionerModalities: [],
  practitionerTypes: [],
  genderIdentification: [],
  womanOrMan: [],
  sessionTypes: [],
  culture: "",
  religion: [],
  languages: [],
  maxBudget: 250,
  minBudget: 0,
  homeAddress: "",
  officeAddress: "",
  otherAddress: "",
  homeZip: "",
  officeZip: "",
  otherZip: "",
  homeLocation: { lat: null, lng: null },
  officeLocation: { lat: null, lng: null },
  otherLocation: { lat: null, lng: null },
  referred: [],
  referredForThis: [],
  healthInsurers: [],
  medication: "",
  previousExperience: "",
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
  disabledAccess: [],
  mondayAvailability: [9, 17],
  tuesdayAvailability: [9, 17],
  wednesdayAvailability: [9, 17],
  thursdayAvailability: [9, 17],
  fridayAvailability: [9, 17],
  saturdayAvailability: [9, 17],
  sundayAvailability: [9, 17],
  sexuality: "",
  ethnicity: "",
};

const DEFAULT_STATE = {};

export const pmsSearch = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.UPDATE_PMS_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case C.RESET_PMS_SEARCH:
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
