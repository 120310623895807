import { Select, Stack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const DropdownSelectBlok = ({ blok }) => {
  return (
    <Stack {...storyblokEditable(blok)}>
      <Select
        bgColor={blok.backgroundColor}
        placeholder={blok.placeholder}
        maxW={blok.maxWidth}
        borderColor={blok.borderColor ?? "lightGrey"}
        borderWidth={1}
        fontSize={"medium"}
      >
        {blok.options &&
          blok.options.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Select>
    </Stack>
  );
};

export default DropdownSelectBlok;
