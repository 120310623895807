import * as C from '../constants';

const DEFAULT_STATE = {
  updatedProfileValues: {},
};

export const profileEdit = (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case C.UPDATE_PROFILE_VALUE:
      return {
        ...state,
        updatedProfileValues: {
          ...state.updatedProfileValues,
          [action.payload.key]: action.payload.value,
        }
      }
    case C.RESET_PROFILE_UPDATES :
      return {
        ...state,
        updatedProfileValues: {},
      };
    default:
      return state;
  }
}
