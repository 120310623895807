import { Flex, HStack, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { CheckIcon } from "@chakra-ui/icons";

const LineSeparatedListBlok = ({ blok }) => {
  return (
    <Flex
      flexDir={"column"}
      justifyContent="flex-start"
      w="100%"
      rowGap={2}
      my={0}
      {...storyblokEditable(blok)}
    >
      <Text>{blok.title}</Text>
      {blok.items &&
        blok.items.map((nestedBlok) => (
          <HStack
            key={nestedBlok._uid}
            borderBottomWidth={blok.noLines ? 0 : 1}
            borderBottomColor={"sanddune.200"}
            borderStyle={blok.dashedLine ? "dashed" : "solid"}
            py={blok.noLines ? 1 : 3}
          >
            <CheckIcon color={blok.checkColour ?? "brand.200"} />
            <StoryblokComponent blok={nestedBlok} />
          </HStack>
        ))}
    </Flex>
  );
};

export default LineSeparatedListBlok;
