import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { format } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import LogInModal from "../../authentication/LogInModal";
import { imageServiceTransformer } from "../../utilities/helpers";

const NextUpcomingCPDBlok = ({ blok }) => {
  const token = useSelector((state) => state.user.JWTToken);
  const user = useSelector((state) => state.user.userDetails);
  const profile = useSelector(
    (state) => state?.user?.userDetails?.therapistProfile
  );
  const [showTherapistSignupModal, setShowTherapistSignupModal] =
    useState(false);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = useRef();

  const {
    isOpen: isBuyTicketAlertOpen,
    onOpen: onBuyTicketAlertOpen,
    onClose: onBuyTicketAlertClose,
  } = useDisclosure();
  const cancelBuyTicketRef = useRef();

  const {
    isOpen: isNoSubRegisterOpen,
    onOpen: onNoSubRegisterOpen,
    onClose: onNoSubRegisterClose,
  } = useDisclosure();
  const noSubRegisterRef = useRef();

  const isInvalidMembership = useMemo(() => {
    if (!user || !profile) {
      return true;
    }
    return (
      !token ||
      user.accountType !== 2 ||
      !profile.paymentPlan ||
      profile.membershipStatus === "CANCELLED"
    );
  }, [token, user, profile]);

  return (
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      align={["center", "flex-start"]}
      bgColor="brand.100"
      borderRadius={[0, "30px"]}
      py="40px"
      px="30px"
      mt="20px"
      maxW="950px"
      columnGap={5}
      rowGap={5}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      <Box
        overflow="hidden"
        width={["100%", "300px"]}
        minW="300px"
        borderRadius="10px"
        height={"300px"}
        position="relative"
        border="1px"
        borderColor="brand.300"
      >
        <Image
          src={imageServiceTransformer(blok.image.filename, "300x300")}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt="profile header image"
        />
      </Box>
      <Flex flexDir="column">
        <Text textStyle="h2" mb={5}>
          {blok.header}
        </Text>
        <Text textStyle="h5">{blok.title}</Text>
        <Text>{blok.secondaryDetails}</Text>
        <Text>
          {format(new Date(blok.dateTime || null), "dd/MM/yyyy kk:mm")}
        </Text>
        <Text mt={5}>{blok.description}</Text>
        {blok.registerLink && (
          <Stack mt={5}>
            {token === null && (
              <Flex direction="row" columnGap="5px">
                <Button w="fit-content" variant="outline" onClick={onAlertOpen}>
                  Register
                </Button>
                {blok.showBuyTicket && (
                  <Button
                    w="fit-content"
                    variant="outline"
                    onClick={onBuyTicketAlertOpen}
                  >
                    Buy ticket
                  </Button>
                )}
              </Flex>
            )}
            {token !== null && !isInvalidMembership && (
              <Link href={blok.registerLink} target="_blank">
                <Button variant="outline">Register</Button>
              </Link>
            )}
            {token !== null && isInvalidMembership && (
              <Flex direction="row" columnGap="5px">
                <Button variant="outline" onClick={() => onNoSubRegisterOpen()}>
                  Register
                </Button>
                {blok.showBuyTicket && (
                  <Button
                    w="fit-content"
                    variant="outline"
                    onClick={onBuyTicketAlertOpen}
                  >
                    Buy ticket
                  </Button>
                )}
              </Flex>
            )}
          </Stack>
        )}
      </Flex>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Please log in or sign up
            </AlertDialogHeader>
            <AlertDialogBody>
              Please set up an account to access CPD material. <br></br>
              <br></br>Setting up an account only takes a few seconds.<br></br>
              <br></br>Many Thanks.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                onClick={() => {
                  setShowTherapistSignupModal(true);
                  onAlertClose();
                }}
                ml={3}
              >
                Sign up
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isBuyTicketAlertOpen}
        leastDestructiveRef={cancelBuyTicketRef}
        onClose={onBuyTicketAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>
            <AlertDialogBody>
              All CPD and community sessions are included in Welldoing
              membership.<br></br>
              <br></br>
              Please note that all events are only for professional counsellors
              and therapists.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex
                direction="row"
                w="100%"
                justifyContent={token !== null ? "center" : "space-between"}
              >
                {token === null && (
                  <Button
                    onClick={() => {
                      setShowTherapistSignupModal(true);
                      onBuyTicketAlertClose();
                    }}
                    ml={3}
                  >
                    Sign up
                  </Button>
                )}
                <Button
                  onClick={() => {
                    onBuyTicketAlertClose();
                    window.open(blok.buyTicketLink, "_blank");
                  }}
                  ml={3}
                >
                  Continue to Eventbrite
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isNoSubRegisterOpen}
        leastDestructiveRef={noSubRegisterRef}
        onClose={onNoSubRegisterClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Subscription required
            </AlertDialogHeader>
            <AlertDialogBody>
              All CPD and community sessions are included in Welldoing
              membership.<br></br>
              <br></br>
              Please note that all events are only for professional counsellors
              and therapists.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex direction="row" w="100%" justifyContent="center">
                <Link
                  href={{
                    pathname: "accounts/common-account/account-settings",
                  }}
                >
                  <Button variant="outline">Go to account subscriptions</Button>
                </Link>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {showTherapistSignupModal && (
        <LogInModal
          title=""
          onCloseModal={() => {
            setShowTherapistSignupModal(false);
            onAlertClose();
            onBuyTicketAlertClose();
          }}
          signUpChoice="therapist"
          noRedirect={true}
        />
      )}
    </Flex>
  );
};

export default NextUpcomingCPDBlok;
