import * as C from '../constants';

const DEFAULT_STATE = {
  showNavbar: true,
  showSpinner: false,
  pmsSearchStep: 0,
  showLayout: 'main',
};

export const layout = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.SET_LAYOUT:
      return {
        ...state,
        showLayout: action.payload,
      };
    case C.RESET_LAYOUT:
      return DEFAULT_STATE;
    case C.TOGGLE_SHOW_NAVBAR:
      return {
        ...state,
        showNavbar: !state.showNavbar,
      };
    case C.SHOW_NAVBAR:
      return {
        ...state,
        showNavbar: action.payload,
      };
    case C.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: action.payload,
      };
    case C.INCREMENT_PMS_SEARCH_STEP:
      return {
        ...state,
        pmsSearchStep: state.pmsSearchStep + 1,
      };
    case C.DECREMENT_PMS_SEARCH_STEP:
      if (state.pmsSearchStep === 0) return state;
      return {
        ...state,
        pmsSearchStep: state.pmsSearchStep - 1,
      };
    default:
      return state;
  }
};
