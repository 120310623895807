const titleColor = "#3F3B39";

const textStyles = {
  heroTitle: {
    fontFamily: "Montserrat",
    fontSize: ["60px", "60px"],
    fontWeight: 800,
    lineHeight: "65px",
  },
  h1: {
    fontFamily: "Montserrat",
    fontSize: ["40px", "40px"],
    fontWeight: 800,
    lineHeight: "44px",
    color: titleColor,
  },
  h1prim: {
    fontFamily: "Montserrat",
    fontSize: ["40px", "40px"],
    fontWeight: 800,
    lineHeight: "44px",
    color: "brand.400",
  },
  h1Hero: {
    fontFamily: "Montserrat",
    fontSize: ["50px", "50px"],
    fontWeight: 800,
    lineHeight: "auto",
    color: titleColor,
  },
  h2: {
    fontFamily: "Montserrat",
    fontSize: ["25px", "30px"],
    fontWeight: 800,
    lineHeight: "37px",
    color: titleColor,
  },
  h3: {
    fontFamily: "Montserrat",
    fontSize: ["20px", "23px"],
    fontWeight: 800,
    lineHeight: "30px",
    letterSpacing: "",
    color: titleColor,
  },
  h3mobile: {
    fontFamily: "Montserrat",
    fontSize: ["20px", "20px"],
    fontWeight: 800,
    lineHeight: "30px",
    letterSpacing: "",
    color: titleColor,
  },
  h4: {
    fontFamily: "Open Sans",
    fontSize: ["30px", "30px"],
    fontWeight: 500,
    lineHeight: "40px",
    letterSpacing: "",
    color: titleColor,
  },
  h5: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "19px"],
    fontWeight: 500,
    lineHeight: "25px",
    color: titleColor,
  },
  h5mobile: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "16px"],
    fontWeight: 500,
    lineHeight: "25px",
    color: titleColor,
  },
  h5bold: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "16px"],
    fontWeight: 700,
    lineHeight: "25px",
    color: titleColor,
  },
  h6: {
    fontFamily: "Open Sans",
    fontSize: ["14px", "15px"],
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "",
    color: titleColor,
  },

  h7: {
    fontFamily: "Open Sans",
    fontSize: ["13px", "13px"],
    fontWeight: 500,
    lineHeight: "22px",
    color: titleColor,
  },
  body1: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "18px"],
    fontWeight: 400,
    lineHeight: "29px",
    color: titleColor,
  },
  body1mobile: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "16px"],
    fontWeight: 400,
    lineHeight: "29px",
    color: titleColor,
  },
  body2: {
    fontFamily: "Open Sans",
    fontSize: ["14px", "15px"],
    fontWeight: 400,
    lineHeight: "22px",
    color: titleColor,
    textAlign: "left",
  },
  body2mobile: {
    fontFamily: "Open Sans",
    fontSize: ["14px", "14px"],
    fontWeight: 400,
    lineHeight: "22px",
    color: titleColor,
  },
  subcaption: {
    fontFamily: "Open Sans",
    fontSize: ["13px", "13px"],
    fontWeight: 400,
    lineHeight: "10px",
  },
  button1: {
    fontFamily: "Open Sans",
    fontSize: ["16px", "16px"],
    fontWeight: 500,
    lineHeight: "19px",
  },
  footer1: {
    fontFamily: "Open Sans",
    fontSize: ["14px", "14px"],
    fontWeight: 400,
    lineHeight: "20px",
  },
  footer2: {
    fontFamily: "Open Sans",
    fontSize: ["13px", "13px"],
    fontWeight: 400,
    lineHeight: "20px",
  },
  quote1: {
    fontFamily: "Open Sans",
    fontSize: ["26px", "26px"],
    fontWeight: 500,
    lineHeight: "37px",
    fontStyle: "italic",
    color: titleColor,
  },
  quote2: {
    fontFamily: "Open Sans",
    fontSize: ["18px", "18px"],
    fontWeight: 400,
    lineHeight: "29px",
    fontStyle: "italic",
    color: titleColor,
  },
  searchSectionTitle: {
    fontFamily: "Montserrat",
    fontSize: ["19px", "19px"],
    fontWeight: 700,
    lineHeight: "25px",
    letterSpacing: "",
    color: titleColor,
  },
  articleSummary: {
    fontSize: ["18px", "18px"],
    lineHeight: "30px",
    fontWeight: 600,
    fontFamily: "Open Sans",
  }
};

export default textStyles;
