import combinedReducer from "./reducers";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { HYDRATE, createWrapper } from "next-redux-wrapper";

// bind middleware & initiate chrome dev tools if in dev mode
const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("@redux-devtools/extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

//needed for redux-persist
const SET_CLIENT_STATE = "SET_CLIENT_STATE";

// hydrate the reducers
const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return combinedReducer(state, action);
};

const makeConfiguredStore = (reducer) =>
  createStore(reducer, undefined, bindMiddleware([thunkMiddleware]));

// check if client store and if so, use redux-persist
const makeStore = () => {
  const isServer = typeof window === "undefined";

  if (isServer) {
    return makeConfiguredStore(rootReducer);
  } else {
    // add persist to client side store
    const { persistStore, persistReducer } = require("redux-persist");
    const storage = require("redux-persist/lib/storage").default;
    const persistConfig = {
      key: "nextjs",
      whitelist: [
        "bookmarks",
        "pmsSearch",
        "profileList",
        "profileSelected",
        "utilityData",
        'appSettings',
      ], //set in rootreducer above
      blacklist: ["practitionerFilters", "searchPractitionerQuery"],
      storage,
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = makeConfiguredStore(persistedReducer);
    store._persistor = persistStore(store);
    return store;
  }
};

//export the store
export const wrapper = createWrapper(makeStore);

export const setClientState = (clientState) => ({
  type: SET_CLIENT_STATE,
  payload: clientState,
});

