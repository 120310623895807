import { combineReducers } from 'redux';
import { authenticationData } from './authenticationData';
import { bookings } from './bookings';
import { bookmarks } from './bookmarks';
import { clientList } from './clientList';
import { layout } from './layout';
import { messages } from './messages';
import { pmsSearch } from './pmsSearch';
import { profileList } from './profileList';
import { practitionerFilters } from './practitionerFilters';
import { searchPractitionerQuery } from './searchPractitionerQuery';
import { user } from './user';
import { utilityData } from './utilityData';
import {profileEdit} from './profileEdit';
import {appSettings} from './appSettings';

const combinedReducer = combineReducers({
  authenticationData,
  bookings,
  bookmarks,
  clientList,
  layout,
  messages,
  pmsSearch,
  profileList,
  practitionerFilters,
  searchPractitionerQuery,
  user,
  utilityData,
  profileEdit,
  appSettings,
});

export default combinedReducer;
