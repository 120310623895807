import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const TestimonialGridItemBlok = ({ blok }) => {
  return (
    <Flex
      key={blok.name}
      flexDir="column"
      borderRadius="20px"
      p={5}
      bgColor="brand.100"
      {...storyblokEditable(blok)}
    >
      <Text>&quot;{blok.text}&quot;</Text>
      <Text mt={5} color="brand.200" fontWeight="bold">
        {blok.name}
      </Text>
    </Flex>
  );
};

export default TestimonialGridItemBlok;
