import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CPDInfoBlok from "./CPDInfoBlok";

const CPDGridBlok = ({ blok }) => {
  const token = useSelector((state) => state.user.JWTToken);
  const user = useSelector((state) => state.user.userDetails);
  const profile = useSelector(
    (state) => state?.user?.userDetails?.therapistProfile
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = useRef();

  const CTA = () => {
    onAlertOpen();
  };

  const isInvalidMembership = useMemo(() => {
    if (!user || !profile) {
      return true;
    }
    return (
      !token ||
      user.accountType !== 2 ||
      !profile.paymentPlan ||
      profile.membershipStatus === "CANCELLED"
    );
  }, [token, user, profile]);

  return (
    <Stack
      direction="column"
      {...storyblokEditable(blok)}
      mx={[5, blok.pageMargins && "10%"]}
    >
      <Text mb={5} fontWeight="bold" textStyle="h3">
        {blok.title}
      </Text>
      <Stack direction="row" mb={5}>
        <Flex
          bgColor={selectedTab === 0 ? "brand.400" : "white"}
          borderRadius={"20px"}
          h={"40px"}
          w={"fit-content"}
          borderWidth={1}
          borderColor={"brand.400"}
          p={5}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSelectedTab(0)}
          cursor="pointer"
        >
          <Text
            textStyle="button1"
            color={selectedTab === 0 ? "white" : "brand.400"}
          >
            Upcoming
          </Text>
        </Flex>
        <Flex
          bgColor={selectedTab === 1 ? "brand.400" : "white"}
          borderRadius={"20px"}
          h={"40px"}
          w={"fit-content"}
          borderWidth={1}
          borderColor={"brand.400"}
          p={5}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSelectedTab(1)}
          cursor="pointer"
        >
          <Text
            textStyle="button1"
            color={selectedTab === 1 ? "white" : "brand.400"}
          >
            Past
          </Text>
        </Flex>
      </Stack>
      {selectedTab === 0 ? (
        <Grid
          gridTemplateColumns={{
            base: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
            lg: "repeat(3, minmax(0, 1fr))",
            xl: "repeat(5, minmax(0, 1fr))",
          }}
          columnGap={5}
          rowGap={5}
        >
          {blok &&
            blok.upcomingCPD &&
            blok.upcomingCPD.map((nestedBlok) => (
              <CPDInfoBlok
                loginCTA={CTA}
                blok={nestedBlok}
                key={nestedBlok._uid}
                isUpcoming={true}
              />
            ))}
        </Grid>
      ) : (
        <Grid
          gridTemplateColumns={{
            base: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
            lg: "repeat(3, minmax(0, 1fr))",
            xl: "repeat(5, minmax(0, 1fr))",
          }}
          columnGap={5}
          rowGap={5}
        >
          {blok &&
            blok.pastCPD &&
            blok.pastCPD.map((nestedBlok) => (
              <CPDInfoBlok
                loginCTA={CTA}
                blok={nestedBlok}
                key={nestedBlok._uid}
              />
            ))}
        </Grid>
      )}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Please log in or sign up
            </AlertDialogHeader>
            <AlertDialogBody>
              Please set up an account to access CPD material. <br></br>
              <br></br>Setting up an account only takes a few seconds.<br></br>
              <br></br>Many Thanks.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onAlertClose} ml={3}>
                Got it!
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

export default CPDGridBlok;
