import { Loader } from '@googlemaps/js-api-loader';
import {createContext, useState, useEffect} from 'react';

/**
 * @typedef {{
 *  geocoding: google.maps.GeocodingLibrary,
 *  places: google.maps.PlacesLibrary,
 *  [key: string]: unknown,
 * }} TGoogleMapsLibraries
 */

/**
 * @typedef {Object} TGoogleMapContext
 * @property {TGoogleMapsLibraries} googleMaps
 * @property {Boolean} googleMapsLoaded
 */

/**
 * @type {import('react').Context<TGoogleMapContext>}
 */
export const GoogleMapContext = createContext({
    googleMaps: {},
    googleMapsLoaded: false,
});

export function GoogleMapProvider({children}) {
  /**
   * @type {[TGoogleMapsLibraries, React.Dispatch<React.SetStateAction<TGoogleMapsLibraries>>]}
   */
  const [googleMaps, setGoogleMaps] = useState();
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    const getGoogleMaps = async () => {
      setGoogleMapsLoaded(false);
      const loader = new Loader({
        apiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API,
        version: 'weekly',
        region: 'gb',
      });

      const loadLibrary = async (libraryName) => {
        const libary = await loader.importLibrary(libraryName);
        setGoogleMaps(g => ({
          ...g,
          [libraryName]: libary,
        }));
      };

      await loadLibrary('places');
      await loadLibrary('geocoding');
      setGoogleMapsLoaded(true);
    }

    getGoogleMaps();
  }, []);

  return (
    <GoogleMapContext.Provider value={{googleMaps, googleMapsLoaded}}>
      { children }
    </GoogleMapContext.Provider>
  );
}
