import { Icon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import {
  FaMagnifyingGlass,
  FaCertificate,
  FaComment,
  FaLightbulb,
  FaLock,
  FaStopwatch,
  FaTag,
  FaHandshake,
} from "react-icons/fa6";
import LogInModal from "../../authentication/LogInModal";

const iconMap = {
  magnifyingGlass: FaMagnifyingGlass,
  handshake: FaHandshake,
  speechBubble: FaComment,
  padlock: FaLock,
  stopwatch: FaStopwatch,
  lightbulb: FaLightbulb,
  certificate: FaCertificate,
  tag: FaTag,
};

const RegistrationButtonBlok = ({ blok }) => {
  const [showSignupModal, setShowSignupModal] = useState(false);
  return (
    <>
      <Flex
        bgColor={blok.backgroundColour ?? "brand.200"}
        borderRadius={blok.squareVariant ? "5px" : "20px"}
        h={blok.squareVariant ? "45px" : "40px"}
        w={blok.expandWidth || blok.squareVariant ? "100%" : "fit-content"}
        maxW={blok.maxWidth}
        p={5}
        justifyContent="center"
        alignItems="center"
        borderColor={blok.borderColor}
        borderWidth={blok.borderWidth ?? 0}
        borderStyle="solid"
        cursor="pointer"
        onClick={() => setShowSignupModal(true)}
        {...storyblokEditable(blok)}
      >
        {blok.icon && (
          <Icon
            mr={2}
            as={iconMap[blok.icon]}
            boxSize={4}
            color={blok.iconColor ?? "black"}
          />
        )}
        <Text
          noOfLines={1}
          textStyle="button1"
          color={blok.textColor ?? "white"}
        >
          {blok.text}
        </Text>
      </Flex>
      {showSignupModal && (
        <LogInModal
          title=""
          onCloseModal={() => {
            setShowSignupModal(false);
          }}
          signUpChoice="client"
          noRedirect={true}
        />
      )}
    </>
  );
};
export default RegistrationButtonBlok;
