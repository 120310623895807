import {
  authenticationDetails,
  cognitoUser,
  cognitoUserPool,
} from "./cognitoAPI";
import pick from 'lodash/pick';

let user = null;
let sessionAttributes = null;

// Logs in the user given email and password
export const logInUser = async (data) => {
  const { email, password } = data;

  return new Promise((resolve, reject) => {
    user = cognitoUser(email);
    user.authenticateUser(
      authenticationDetails(email, password),
      {
        onSuccess: (session) => {
          const token = session.idToken.jwtToken;
          // Return cognito jwtToken
          resolve(token);
        },

        onFailure: (err) => {
          console.error(err);
          let error = err.message || JSON.stringify(err);
          reject(error);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          sessionAttributes = pick(userAttributes, requiredAttributes);
          reject('newPasswordRequired');
        }
      }
    );
  });
};

export const newPasswordChallenge = async (newPassword) => {
  return new Promise((resolve, reject) => {
    user.completeNewPasswordChallenge(newPassword, sessionAttributes, {
      onSuccess: (session) => {
        const token = session.idToken.jwtToken;
        resolve(token);
      },

      onFailure: (err) => {
        let error = err.message || JSON.stringify(err);
        reject(error);
      },
    })
  });
}

// Used when user clicks forgot password
export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    cognitoUser(email).forgotPassword({
      onSuccess: (result) => {

        resolve(result);
      },

      onFailure: (err) => {
        let returnedError = err.message || JSON.stringify(err);
        console.error("forgotPassword ERROR", returnedError);
        const clientError = returnedError.includes("combination not found")
          ? "We do not have an account registered with that email. Please check and try again."
          : returnedError;
        reject(clientError);
      },
    });
  });
};

// Used when user types in new password
export const completeForgotPassword = async (email, code, password) => {
  return new Promise((resolve, reject) => {
    cognitoUser(email).confirmPassword(code, password, {
      onSuccess: (res) => {
        resolve(res);
      },
      onFailure: (err) => {
        let error = err.message || JSON.stringify(err);
        console.error("completeForgotPassword ERROR", error);
        reject(error);
      },
    });
  });
};

// Used in account settings
export const changePassword = async (email, oldPass, newPass) => {
  return new Promise(async (resolve, reject) => {
    const user = cognitoUserPool.getCurrentUser();
    // For some reason the change password only works in a callback for a getSession call???
    user.getSession((sessionError, session) => {
      if (sessionError) {
        let error = sessionError.message || JSON.stringify(sessionError);
        reject(error);
      } else {
        user.changePassword(oldPass, newPass, (err, result) => {
          if (err) {
            let error = err.message || JSON.stringify(err);
            reject(error);
            return;
          }
          resolve(result);
        });
      }
    });
  });
};

/* xxx */

export const getSession = async () => {
  return await new Promise((resolve, reject) => {
    const user = cognitoUserPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          let authorizedCognitoUser = user;
          resolve(session);
        }
      });
    } else {
      reject();
    }
  });
};

export const refreshSession = async (email) => {
  let currentSession = await getSession();
  return new Promise((resolve, reject) => {
    cognitoUser(email).refreshSession(
      currentSession.getRefreshToken(),
      (err, session) => {
        if (err) {
          let error = err.message || JSON.stringify(err);
          reject(error);
          return;
        }
        currentSession = session;
        resolve(session);
      }
    );
  });
};
