import { Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import Switch from "react-switch";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import { CheckIcon } from "@chakra-ui/icons";
import LogInModal from "../../authentication/LogInModal";

const membership = [
  {
    title: "Quarterly membership",
    color: "brand.200",
  },
  {
    title: "Annual membership",
    color: "brand.400",
  },
];

const MembershipSelectBlok = ({ blok }) => {
  const [checked, setChecked] = useState(false);
  const [showTherapistSignupModal, setShowTherapistSignupModal] =
    useState(false);
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      {...storyblokEditable(blok)}
      my={5}
    >
      {showTherapistSignupModal && (
        <LogInModal
          title=""
          onCloseModal={() => setShowTherapistSignupModal(false)}
          signUpChoice={blok.signupChoice ?? "therapist"}
        />
      )}
      <Text textStyle="h3" mb={5} color="brand.400">
        Welldoing membership
      </Text>
      <Text textStyle="h2" textAlign="center" my={5}>
        Select your Welldoing membership plan
      </Text>
      <Flex
        columnGap={10}
        justifyContent="center"
        alignItems="flex-start"
        my={5}
        px={3}
      >
        <VStack alignItems="center">
          <Text fontWeight="bold" fontSize={[16, 22]} color="brand.200">
            Quarterly
          </Text>
          <Text color="text.50" textAlign="center">
            Cancel anytime
          </Text>
        </VStack>
        <Switch
          checked={checked}
          onChange={() => setChecked((current) => !current)}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#587500"
          offColor="#836B5D"
          height={38}
          width={62}
          handleDiameter={30}
        />
        <VStack alignItems="center">
          <Text fontWeight="bold" fontSize={[16, 22]} color="brand.400">
            Annual
          </Text>
          <Text color="text.50" textAlign="center">
            2 months FREE
          </Text>
        </VStack>
      </Flex>
      <Flex
        boxShadow="0 0 10px 0 rgba(0,0,0,0.1)"
        bgColor="white"
        w="400px"
        maxW="400px"
        minH="400px"
        flexDir="column"
        alignItems="center"
        my={5}
      >
        <Flex
          w="100%"
          h="50px"
          bgColor={checked ? membership[1].color : membership[0].color}
          justifyContent="center"
          alignItems="center"
          mb={5}
        >
          <Text fontWeight="bold" color="white" fontSize="16pt">
            {checked ? membership[1].title : membership[0].title}
          </Text>
        </Flex>
        <Flex alignItems="baseline" gap={1}>
          <Text
            fontSize="28pt"
            fontWeight="bold"
            color={checked ? membership[1].color : membership[0].color}
          >
            £{`${checked ? blok.annualCost : blok.quarterlyCost}`}
          </Text>
          <Text color={checked ? membership[1].color : membership[0].color}>
            +VAT
          </Text>
        </Flex>
        <Text color={checked ? membership[1].color : membership[0].color}>
          Billed {checked ? "Annually" : "Quarterly"}
        </Text>
        <Flex color="text.50" alignItems="center" columnGap={1}>
          <FaCirclePlus />
          <Text>£15+VAT annual verification fee</Text>
        </Flex>
        <Flex w="100%" flexDir="column" px={7} my={5}>
          <Text fontWeight="bold">Standard package benefits include</Text>
          <HStack
            borderBottomWidth={1}
            borderBottomColor={"sanddune.200"}
            borderStyle={"dashed"}
            py={3}
            w="100%"
          >
            <CheckIcon color="brand.200" />
            <Text>Free weekly CPD sessions</Text>
          </HStack>
          <HStack
            borderBottomWidth={1}
            borderBottomColor={"sanddune.200"}
            borderStyle={"dashed"}
            py={3}
            w="100%"
          >
            <CheckIcon color="brand.200" />
            <Text>Integrated booking and payment system</Text>
          </HStack>
          <HStack
            borderBottomWidth={1}
            borderBottomColor={"sanddune.200"}
            borderStyle={"dashed"}
            py={3}
            w="100%"
          >
            <CheckIcon color="brand.200" />
            <Text>Publish content and reach a wide audience</Text>
          </HStack>
        </Flex>
        <Button
          w={"fit-content"}
          bgColor="brand.400"
          my={5}
          onClick={() => setShowTherapistSignupModal(true)}
        >
          <Text textStyle="button1">Register now</Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default MembershipSelectBlok;
