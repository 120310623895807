import { Box, Flex, Stack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

const UnorderedListBlok = ({ blok }) => {
  return (
    <Stack
      alignItems="flex-start"
      {...storyblokEditable(blok)}
      mx={[0, blok.pageMargins && "10%"]}
    >
      <Flex px={[5, 0]}>{render(blok.title)}</Flex>
      <Box px={5}>
        {blok &&
          blok.body.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Box>
    </Stack>
  );
};

export default UnorderedListBlok;
