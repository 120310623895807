import { Container, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";

const PolicyTemplateBlok = ({ blok }) => (
  <Container
    maxW={{ base: "100%", xl: "100%" }}
    boxSizing="border-box"
    overflowX="hidden"
    px={[10, "10%"]}
    minH={"50vh"}
    {...storyblokEditable(blok)}
  >
    <Flex h={"max-content"} mt={10} flexDir={"column"}>
      <Text textStyle="h1">{blok.title}</Text>
      {blok &&
        blok.body.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </Flex>
  </Container>
);

export default PolicyTemplateBlok;
