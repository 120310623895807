import * as C from "../constants";
import { getData } from "../../utilities/axios";
import { updatePmsSearchDetails } from "./pmsSearch";
import { updateArticleBookmarks, updateProfileBookmarks } from "./bookmarks";

const userDetailsUpdate = (userDetails) => ({
  type: C.UPDATE_USER_DETAILS,
  payload: userDetails,
});

const jwtUpdate = (token) => ({
  type: C.UPDATE_USER_JWT,
  payload: token,
});

const userReset = () => ({
  type: C.RESET_USER_DETAILS,
});

// Actions with dispatch
export const updateUserDetails = (details) => (dispatch) => {
  dispatch(userDetailsUpdate(details));
};

export const updateUserJWT = (details) => (dispatch) => {
  dispatch(jwtUpdate(details));
};

export const updateProfileValue = (key, value) => (dispatch) => {
  dispatch({
    type: C.UPDATE_PROFILE_VALUE,
    payload: {
      key,
      value,
    },
  });
};

export const resetProfileUpdates = () => (dispatch) => {
  dispatch({
    type: C.RESET_PROFILE_UPDATES,
  });
};

export const getUserObject = (token) => (dispatch) => {
  // Get user details
  return getData(`${process.env.NEXT_PUBLIC_NEW_USER_URL}profile`, null, token)
    .then((res) => {
      const newObj = {
        JWTToken: token,
        userDetails: res,
      };
      dispatch({
        type: C.UPDATE_USER,
        payload: newObj,
      });
      // update PMS

      dispatch(updateProfileBookmarks(res.savedProfiles));
      dispatch(
        updateArticleBookmarks(
          res.savedArticles.map((article) => article.articleId)
        )
      );

      if (res.pmsFormData && Object.keys(res.pmsFormData).length > 0) {
        dispatch(updatePmsSearchDetails(res.pmsFormData));
      }

      return res;
    })
    .catch((err) => {
      console.error("getUserObject", err);
      let error = err.message || JSON.stringify(err);
      throw new Error(error);
    });
};
