import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

const LozengeBlok = ({ blok }) => {
  return (
    <Link prefetch={false} href={blok.link} {...storyblokEditable(blok)}>
      <Flex
        bgColor={blok.backgroundColour ?? "white"}
        borderRadius={blok.squareVariant ? "5px" : "20px"}
        h={blok.squareVariant ? "45px" : "40px"}
        w={blok.squareVariant ? "100%" : "fit-content"}
        borderWidth={1}
        borderColor={blok.textColor ?? "brand.400"}
        p={5}
        justifyContent="center"
        alignItems="center"
      >
        <Text textStyle="button1" color={blok.textColor ?? "brand.400"}>
          {blok.text}
        </Text>
      </Flex>
    </Link>
  );
};
export default LozengeBlok;
