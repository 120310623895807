// Generic fail
export const genericFail = (title, err = false) => {
  return {
    title: title,
    description: err
      ? err
      : "Please refresh your browser to try again or contact support",
    position: "top",
    status: "error",
    isClosable: true,
  };
};

export const genericWarning = (title, desc = false) => {
  return {
    title: title,
    description: desc
      ? desc
      : "Please refresh your browser to try again or contact support",
    position: "top",
    status: "warning",
    isClosable: true,
  };
};

export const genericSuccess = (title, description = "") => {
  return {
    title,
    description,
    position: "top",
    status: "success",
    isClosable: true,
  };
};

export const signupFail = (err) => {
  return {
    title: "Problem Signing Up",
    description: err,
    position: "top",
    status: "error",
    isClosable: true,
  };
};

// Login
export const loginSuccess = {
  title: "Welcome to Welldoing!",
  description: "",
  position: "top",
  status: "success",
  isClosable: true,
};

export const loginFail = (err) => {
  return {
    title: "Problem Logging In",
    description: err,
    position: "top",
    status: "error",
    isClosable: true,
  };
};

export const expiredToken = () => {
  return {
    title: "Credentials Expired",
    description:
      "Your browser has cached your login credentials but these have now expired. Please refresh your browser to try again or log out and log in again",
    position: "top",
    status: "warning",
    isClosable: true,
  };
};

export const missingLocation = (location) => {
  return {
    title: "Missing Location",
    description: `We couldn't find any locations matching ${location}`,
    position: "top",
    status: "warning",
    isClosable: true,
  };
}

// Logout
export const logoutSuccess = {
  title: "Success",
  description: "Successfully logged out.",
  position: "top",
  status: "success",
  isClosable: true,
};

export const logoutFail = (err) => {
  return {
    title: "Problem Logging Out",
    description: err,
    position: "top",
    status: "error",
    isClosable: true,
  };
};

// Password reset
export const sendCodePasswordResetSuccess = () => {
  return {
    title: "Password reset success",
    description: `Please check your email for instructions on how to reset your password.`,
    position: "top",
    status: "success",
    isClosable: true,
  };
};

export const sendCodePasswordResetFail = (err) => {
  return {
    title: "Problem with password reset",
    description: err,
    position: "top",
    status: "error",
    isClosable: true,
  };
};

export const passwordResetSuccess = {
  title: "Password successfully reset",
  description: "Please log in to continue.",
  position: "top",
  status: "success",
  isClosable: true,
};

export const passwordResetFail = (err) => {
  return {
    title: "Problem resetting password",
    description: err,
    position: "top",
    status: "error",
    isClosable: true,
  };
};

export const updateDetailsSuccess = {
  title: "Details updated",
  position: "top",
  status: "success",
  isClosable: true,
};

export const updateDetailsFail = () => {
  return {
    title: "Problem updating details",
    description: "Please refresh your browser to try again or contact support",
    position: "top",
    status: "error",
    isClosable: true,
  };
};

// Maximum selection in Lozenge click
export const maxSelection = (max) => {
  return {
    title: "Maximum selection reached",
    description: `This field is limited to ${max} items`,
    position: "top",
    status: "warning",
    isClosable: true,
  };
};
