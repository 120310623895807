import { Box, Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const DropdownOptionBlok = ({ blok }) => {
  return (
    <option {...storyblokEditable(blok)} value={blok.value}>
      {blok.text}
    </option>
  );
};

export default DropdownOptionBlok;
