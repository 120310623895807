import {
  FormControl,
  FormErrorMessage,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { Field, useField } from "formik";

const CheckboxInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <Field as={Checkbox} {...field} {...props}>
        <Text textStyle={props.textStyle} py="8px" color={props.color}>
          {props.description}
        </Text>
      </Field>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default CheckboxInput;
