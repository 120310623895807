import * as C from '../constants';

const DEFAULT_STATE = {
  unreadMessages: false,
  messages: null,
  clientMessages: [],
  selectedThread: null,
  participantId: null,
  selectedContact: null,
  clientData: null,
  creatingClient: false,
  viewAppointments: false,
};

export const messages = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.INITIALISE_CLIENT_MESSAGES:
      return {
        ...state,
        clientMessages: action.payload,
      };
    case C.INITIALISE_MESSAGES:
      return {
        ...state,
        unreadMessages: false,
        messages: action.payload,
      };
    case C.NEW_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessages: true,
      };
    case C.CLEAR_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessages: false,
      };
    case C.SELECT_THREAD:
      return {
        ...state,
        selectedThread: action.payload,
      };
    case C.SET_MESSAGES_CLIENT_ID:
      if (action.payload === null){
        return {
          ...state,
          participantId: null,
          selectedThread: null,
        }
      }

      return {
        ...state,
        participantId: action.payload,
      };
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    case C.SELECT_CONTACT:
      return {
        ...state,
        selectedContact: action.payload,
        clientData: null,
      };
    case C.GET_CLIENT_DATA:
      return {
        ...state,
        clientData: action.payload,
      };
    case C.OPEN_CREATE_CLIENT:
      return {
        ...state,
        creatingClient: action.payload,
      };
    case C.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case C.ADD_MESSAGE:
      return {
        ...state,
        messages: state.messages?.concat([action.payload]) ?? null,
      };
    case C.CLEAR_MESSAGES:
      return {
        ...state,
        messages: null,
      };
    case C.VIEW_APPOINTMENTS:
      return {
        ...state,
        viewAppointments: action.payload,
      }
    default:
      return state;
  }
};
