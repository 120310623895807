import * as C from '../constants';

export const DEFAULT_FILTER_STATE = {
  address: '',
  age: 0,
  ageGroups: [],
  availability: [
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
  ],
  clientGroups: [],
  concessions: [],
  distance: 0,
  disabledFriendly: false,
  ethnicity: [],
  freeIntroductoryCall: false,
  genders: [],
  healthInsurers: [],
  isLGBTQAffirmative: false,
  languages: [],
  location: { lat: null, lng: null },
  maxAveragePrice: 1000,
  maxAge: 100,
  minExperience: 0,
  outOfOffice: false,
  practitionerType: [
    { value: 1, label: 'Therapist', selected: true },
    { value: 2, label: 'Coach', selected: false },
  ],
  practitionerName: '',
  priceBrackets: [
    { value: 50, label: '< £50' },
    { value: 100, label: '£50 < £100' },
    { value: 1000, label: '£100+' },
  ],
  religion: [],
  sessionTypes: [],

  therapistPracticeAreas: [],
  therapistPractitionerModalities: [],

  practiceStartDate: '2020-10-25',

  rating: [],

  verified: true,
  profileActive: true,
  profileComplete: true,
  showHelp: false,
  resultIndex: 0,
  resultItems: 20,
  orderBy: {
    value: 'name',
    ascending: true,
  },
};

export const practitionerFilters = (state = DEFAULT_FILTER_STATE, action) => {
  switch (action.type) {
    case C.UPDATE_SEARCH_PRACTITIONER:
      return {
        ...state,
        ...action.payload,
      };
    case C.RESET_SEARCH_PRACTITIONER:
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_FILTER_STATE;
    default:
      return state;
  }
};
