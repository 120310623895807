// theme/index.js
import { extendTheme, withDefaultVariant } from '@chakra-ui/react';

// Global style overrides
import colors from './colors';
import fonts from './fonts';
import textStyles from './textStyles';

// Foundational style overrides
//import borders from './foundations/borders';

// Component style overrides
import Alert from './components/alert';
import Button from './components/button';
import Input from './components/input';
import Select from './components/select';
import FormLabel from './components/formlabel';
import Textarea from './components/textarea';
import Tag from './components/tag';
import Radio from './components/radio';
import Checkbox from './components/checkbox';

const overrides = {
  colors,
  fonts,
  textStyles,
  components: {
    Alert,
    Button,
    Input,
    Select,
    FormLabel,
    Textarea,
    Tag,
    Radio,
    Checkbox,
    // Other components go here
  },
};

export default extendTheme(
  overrides,
  withDefaultVariant({
    variant: 'filled',
    components: ['Input', 'Select', 'Textarea'],
  })
);
