import { Button, Flex, SimpleGrid, Stack, Text, Wrap } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import StoryblokClient from "storyblok-js-client";
import { useEffect, useState } from "react";
import chunk from "lodash/chunk";

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
  region: "eu",
});

const RecommendationGridBlok = ({ blok }) => {
  const [tags, setTags] = useState([]);
  const [numberTagsToShow, setNumberTagsToShow] = useState(
    blok.numberOfTagsToShow ?? 12
  );
  const [showAllTags, setShowAllTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const [chunkedItemArray, setChunkedItemArray] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    Storyblok.getAll(`cdn/datasource_entries`, {
      datasource: "tags",
    })
      .then((datasourceEntries) => {
        setTags(datasourceEntries);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (blok.items) {
      let theseItems = blok.items;
      if (selectedTag !== null) {
        theseItems = blok.items.filter((item) =>
          item.tags?.includes(selectedTag.value)
        );
      }
      const chunked = chunk(theseItems, 20);
      setChunkedItemArray(chunked);
    }
  }, [blok.items, selectedTag]);

  return (
    <>
      <Stack direction="column" rowGap={5} mx={"10%"} mb={10}>
        <Text color="black" textStyle="h3">
          Filter books by:
        </Text>
        <Wrap direction="row">
          {tags.map((tag, index) => {
            if (!showAllTags && index + 1 > numberTagsToShow) {
              return;
            }
            return (
              <Flex
                key={`${tag}+${index}`}
                bgColor={
                  selectedTag?.value === tag.value ? "brand.400" : "white"
                }
                borderRadius={"20px"}
                h={"40px"}
                w={"fit-content"}
                borderWidth={1}
                borderColor={"brand.400"}
                p={5}
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  if (selectedTag !== null && selectedTag.value === tag.value) {
                    setSelectedTag(null);
                  } else {
                    setSelectedTag(tag);
                  }
                }}
              >
                <Text
                  textStyle="button1"
                  color={
                    selectedTag?.value === tag.value ? "white" : "brand.400"
                  }
                >
                  {tag.name}
                </Text>
              </Flex>
            );
          })}
          <Flex
            bgColor={"brand.400"}
            borderRadius={"20px"}
            h={"40px"}
            w={"fit-content"}
            borderWidth={1}
            borderColor={"brand.400"}
            p={5}
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={() => setShowAllTags((current) => !current)}
          >
            <Text textStyle="button1" color={"white"}>
              {showAllTags ? "Show fewer tags" : "Show all tags"}
            </Text>
          </Flex>
        </Wrap>
      </Stack>
      <SimpleGrid
        columns={[2, 4]}
        spacing={[5, 5]}
        mx={[5, blok.pageMargins && "10%"]}
        {...storyblokEditable(blok)}
      >
        {chunkedItemArray.length > 0 &&
          chunkedItemArray[page].map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </SimpleGrid>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mx={[5, blok.pageMargins && "10%"]}
        my="40px"
      >
        {page > 0 && (
          <Button
            w="145px"
            onClick={() => {
              setPage((current) => current - 1);
              window.scrollTo({
                top: 500,
                behavior: "smooth",
              });
            }}
          >
            Previous Page
          </Button>
        )}
        <Text
          textAlign="center"
          verticalAlign="center"
          height="100%"
        >{`Page ${page + 1} of ${chunkedItemArray.length}`}</Text>
        {chunkedItemArray[page + 1]?.length > 0 && (
          <Button
            w="145px"
            onClick={() => {
              setPage((current) => current + 1);
              window.scrollTo({
                top: 500,
                behavior: "smooth",
              });
            }}
          >
            Next Page
          </Button>
        )}
      </Flex>
    </>
  );
};

export default RecommendationGridBlok;
