import { useBreakpointValue } from "@chakra-ui/react";
import haversine from "haversine-distance";

export const isJSONEmpty = (obj) => {
  return !!obj && Object.keys(obj).length === 0;
};

export const filterSelectedOnly = (arr) => {
  if (!arr) return;
  return arr?.filter((item) => item.selected);
};

export const checkAvailability = (arr) => {
  let availabilitySet = arr
    .map((elem) => elem.filter((elem) => elem !== false))
    .filter((elem) => elem.length !== 0).length;
  return availabilitySet ? arr : [];
};

export const imageServiceTransformer = (url, param = "") => {
  return url.replace(
    "https://a.storyblok.com",
    `https://img2.storyblok.com/${param}`
  );
};

export const IsMobileView = () => {
  return useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "base",
    }
  );
};

export const getSortOrder = (prop, dir = 1) => {
  return function (a, b) {
    if (dir === 1) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
    } else {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
    }
    return 0;
  };
};

export const showMessageDay = (renderedDate) => {
  let dt = new Date(renderedDate);
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  }).format(dt);
};

export const showMessageTime = (renderedDate) => {
  let dt = new Date(renderedDate);
  return (
    <>
      {new Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
      }).format(dt)}
    </>
  );
};

export const METERS_TO_MILES = 0.000621371;

/**
 *
 * @param {google.maps.GeocoderResult} location Location to find bounds radius for
 * @returns {number} Returns the distance in meters between `location`'s center
 * and the most distant bounding point. If the `location` doesn't have any bounding
 * points set then 0 is returned
 */
export const calculateLocationRadius = (location) => {
  if (!location.geometry.bounds) {
    return 0;
  }
  const center = location.geometry.location.toJSON();
  const bounds = [
    location.geometry.bounds.getNorthEast().toJSON(),
    location.geometry.bounds.getSouthWest().toJSON(),
  ];
  const distances = bounds.map((bound) => haversine(center, bound));
  return Math.max(...distances);
};
