import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { refreshSession } from '../authentication/api';

let configHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const axiosClient = axios.create({
  timeout: 50000,
});

export const BOOKING_API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NEW_BOOKING_URL,
  key: process.env.NEXT_PUBLIC_NEW_BOOKING_APIKEY,
  timeout: 50000,
});

export const postData = async (token, req, url, key = '') => {
  if (!!token) {
    //Check expiry

    let validToken = await checkTokenExpiry(token);
    configHeaders.Authorization = `Bearer ${validToken}`;
  }
  if (!!key) {
    configHeaders = { ...configHeaders, 'x-api-key': key };
  }

  try {
    let response = await axiosClient.post(url, req, {
      headers: configHeaders,
    });
    return response?.data;
  } catch (error) {
    console.error('ERROR in POST DATA', JSON.stringify(error.message));
    throw Error(error);
  }
};

export const getData = async (url, key = '', token = null) => {
  if (!!token) {
    //Check expiry

    let validToken = await checkTokenExpiry(token);
    configHeaders.Authorization = `Bearer ${validToken}`;
  }

  if (!!key) {
    configHeaders = { ...configHeaders, 'x-api-key': key };
  }
  try {
    let response = await axios.get(url, {
      headers: configHeaders,
    });
    //let data = await response.json();
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      // console.error(error.response.status);
      // console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    console.error(error.config);
    throw new Error(error.message);
  }
};

export const deleteData = async (token, req, url, key = '') => {
  if (!!token) {
    //Check expiry
    let validToken = await checkTokenExpiry(token);
    configHeaders.Authorization = `Bearer ${validToken}`;
  }
  if (!!key) {
    configHeaders = { ...configHeaders, 'x-api-key': key };
  }

  try {
    let response = await axios.delete(url, {
      data: req,
      headers: configHeaders,
    });
    return response.data;
  } catch (error) {
    console.error('ERROR in DELETE DATA', JSON.stringify(error));
    throw Error(error);
  }
};

export const patchData = async (token, req, url, key = '') => {
  if (!!token) {
    //Check expiry

    let validToken = await checkTokenExpiry(token);
    configHeaders.Authorization = `Bearer ${validToken}`;
  }

  if (!!key) {
    configHeaders = { ...configHeaders, 'x-api-key': key };
  }

  try {
    let response = await axiosClient.patch(url, req, {
      headers: configHeaders,
    });
    return response.data;
  } catch (error) {
    console.error('ERROR in PATCH DATA', JSON.stringify(error));
    throw Error(error);
  }
};

const checkTokenExpiry = async (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const tokenExpires = decodedToken.exp;

    const now = new Date(new Date().getTime());

    if (!!tokenExpires && tokenExpires < now / 1000) {
      // See if we can renew sessions
      let newSession = await refreshSession(decodedToken.email);
      return newSession.idToken.jwtToken;
      // TODO need to do this and update
      //dispatch(updateUserJWT(newSession.idToken.jwtToken));
      //    await saveTokenToLocalStorage(newSession.idToken.jwtToken);
    } else {
      return token;
    }
  } catch (error) {
    console.error('%cPROBLEM REFRESHING TOKEN IN POST', error);
  }
};
