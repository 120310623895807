//auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_EXISTING_USER = 'LOGOUT_EXISTING_USER';
export const LOGIN_SUCCESS_JWT = 'LOGIN_SUCCESS_JWT';

// authorisationdata
export const ADD_AUTHENTICATION_DATA = 'ADD_AUTHENTICATION_DATA';

//user
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_JWT = 'UPDATE_USER_JWT';
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE_VALUE = 'UPDATE_PROFILE_VALUE';
export const RESET_PROFILE_UPDATES = 'RESET_PROFILE_UPDATES';

// layout
export const SET_LAYOUT = 'SET_LAYOUT';
export const RESET_LAYOUT = 'RESET_LAYOUT';
export const TOGGLE_SHOW_NAVBAR = 'TOGGLE_SHOW_NAVBAR';
export const SHOW_NAVBAR = 'SHOW_NAVBAR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const INCREMENT_PMS_SEARCH_STEP = 'INCREMENT_PMS_SEARCH_STEP';
export const DECREMENT_PMS_SEARCH_STEP = 'DECREMENT_PMS_SEARCH_STEP';

//notification
export const SHOW_LOGIN_SUCCESS_NOTIFICATION =
  'SHOW_LOGIN_SUCCESS_NOTIFICATION';

//pmsSearch
export const UPDATE_PMS_SEARCH = 'UPDATE_PMS_SEARCH';
export const RESET_PMS_SEARCH = 'RESET_PMS_SEARCH';

//searchPractioners
export const UPDATE_SEARCH_PRACTITIONER = 'UPDATE_SEARCH_PRACTITIONER';
export const RESET_SEARCH_PRACTITIONER = 'RESET_SEARCH_PRACTITIONER';
export const TOGGLE_RELOAD_SEARCH_PRACTITIONER =
  'TOGGLE_RELOAD_SEARCH_PRACTITIONER';

//searchPractionerQuery
export const UPDATE_SEARCH_PRACTITIONER_QUERY =
  'UPDATE_SEARCH_PRACTITIONER_QUERY';
export const RESET_SEARCH_PRACTITIONER_QUERY =
  'RESET_SEARCH_PRACTITIONER_QUERY';
export const TOGGLE_RELOAD_SEARCH_PRACTITIONER_QUERY =
  'TOGGLE_RELOAD_SEARCH_PRACTITIONER_QUERY';

//profileList
export const UPDATE_PROFILE_LIST = 'UPDATE_PROFILE_LIST';
export const UPDATE_RECORD_COUNT = 'UPDATE_RECORD_COUNT';

export const INITIALISE_PROFILE_LIST = 'INITIALISE_PROFILE_LIST';
export const RESET_PROFILE_LIST = 'RESET_PROFILE_LIST';

//profileSelected
export const SELECT_PROFILE = 'SELECT_PROFILE';

// utilityData
export const UPDATE_UTILITY_DATA = 'UPDATE_UTILITY_DATA';

//bookmarks
export const UPDATE_PROFILE_BOOKMARKS = 'UPDATE_PROFILE_BOOKMARKS';
export const UPDATE_ARTICLE_BOOKMARKS = 'UPDATE_ARTICLE_BOOKMARKS';

//bookings
export const UPDATE_CLIENT_BOOKINGS = 'UPDATE_CLIENT_BOOKINGS';
export const UPDATE_USER_BOOKINGS = 'UPDATE_USER_BOOKINGS';
export const SELECT_USER_BOOKINGS = 'SELECT_USER_BOOKINGS';
export const SELECT_BOOKING = 'SELECT_BOOKING';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const ARCHIVE_BOOKING = 'ARCHIVE_BOOKING';
export const DISPLAY_LINKED_BOOKINGS = 'DISPLAY_LINKED_BOOKINGS';
export const CLOSE_LINKED_BOOKINGS = 'CLOSE_LINKED_BOOKINGS';

//messages
export const INITIALISE_MESSAGES = 'INITIALISE_MESSAGES';
export const INITIALISE_CLIENT_MESSAGES = 'INITIALISE_CLIENT_MESSAGES';
export const NEW_UNREAD_MESSAGE = 'NEW_UNREAD_MESSAGE';
export const CLEAR_UNREAD_MESSAGE = 'CLEAR_UNREAD_MESSAGE';
export const SELECT_THREAD = 'SELECT_THREAD';

export const GET_MESSAGES = 'GET_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const SET_MESSAGES_CLIENT_ID = 'SET_MESSAGES_CLIENT_ID';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const VIEW_APPOINTMENTS = 'VIEW_APPOINTMENTS';

// clientList
export const UPDATE_CLIENT_LIST = 'UPDATE_CLIENT_LIST';
export const OPEN_CREATE_CLIENT = 'OPEN_CREATE_CLIENT';

// socket
export const UPDATE_SOCKET_USER_LIST = 'UPDATE_SOCKET_USER_LIST';

export const TOGGLE_BANNER_DISMISS = 'TOGGLE_BANNER_DISMISS';
