import * as C from "../constants";

const DEFAULT_STATE = {
  JWTToken: null,
  userDetails: null,
};

export const user = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    case C.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };

    case C.UPDATE_USER_JWT:
      return {
        ...state,
        JWTToken: action.payload,
      };
    case C.RESET_USER_DETAILS:
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

/* default state */
/*
{
  "id": 9304,
  "fullname": "David Lane",
  "username": "davidlane1982+10@gmail.com",
  "contactPhone": null,
  "gender": [],
  "yearOfBirth": null,
  "accountType": 2,
  "created_at": "2022-09-09T12:27:28.000Z",
  "updated_at": "2022-09-09T12:27:28.000Z",
  "firstLogin": true,
  "sexuality": [],
  "savedArticles": [],
  "savedProfiles": [],
  "notifications": {
      "clientNotifications": false,
      "contentNotifications": false,
      "promotionsNotifications": false
  },
  "notifications": [],
  "therapistProfile": {
      "profileId": 9298,
      "fullname": "David Lane",
      "contactEmail": "davidlane1982+10@gmail.com",
      "contactPhone": null,
      "yearsExperience": 0,
      "verified": true,
      "summary": null,
      "profileHeaderImage": null,
      "profileImage": null,
      "practiceNumber": null,
      "profileVideo": null,
      "aboutMe": null,
      "practitionerType": 1,
      "pageName": "9298",
      "website": null,
      "updated_at": "2022-09-09T12:27:28.000Z",
      "concessions": [],
      "insurances": [],
      "religions": [],
      "languages": [
          {
              "label": "English",
              "value": 25
          }
      ],
      "qualifications": [],
      "links": [],
      "clientGroups": [],
      "sessionTypes": [],
      "addresses": [],
      "pronoun": [],
      "availability": [
          [
              false,
              false,
              false,
              false,
              false,
              false,
              false
          ],
          [
              false,
              false,
              false,
              false,
              false,
              false,
              false
          ],
          [
              false,
              false,
              false,
              false,
              false,
              false,
              false
          ],
          [
              false,
              false,
              false,
              false,
              false,
              false,
              false
          ],
          [
              false,
              false,
              false,
              false,
              false,
              false,
              false
          ]
      ],
      "bodies": [
          {
              "label": "Association for Cognitive Analytic Therapy",
              "value": 29
          }
      ],
      "status": "REGISTERED",
      "practitionerModalities": [],
      "practiceAreas": []
  },
  "coachProfile": {}
}
*/
