import { lighten, getColor, mode } from '@chakra-ui/theme-tools';

export default {
  baseStyle: {
    cursor: 'pointer',
    textStyle: 'button1',
    rounded: 'full',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      boxShadow: 'lg',
    },
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 4,
      m: 1,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
      m: 1,
    },
  },
  variants: {
    social: {
      bg: 'whiteAlpha.800',
      rounded: 'full',

      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background 0.3s ease',
      _hover: {
        bg: 'whiteAlpha.900',
      },
    },
    carousel: {
      bg: 'white',
      rounded: 'full',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background 0.3s ease',
      _hover: {
        bg: 'whiteAlpha.900',
        boxShadow: '0px 0px 10px 2px teal',
      },
    },
    delete: {
      bg: 'red',
      color: 'white',
      rounded: 'full',
      //display: 'inline-flex',
      //alignItems: 'center',
      //justifyContent: 'center',
      //transition: 'background 0.3s ease',
      _hover: {
        transform: 'scale(0.98)',
      },
    },
    menu: {
      bg: 'none',
      color: 'brand.400',
      px: '16px',
      _active: {},
      _hover: {
        transform: 'scale(0.98)',
        bg: 'gray.100',
      },
      _focus: {},
    },

    solid: {
      bgGradient: 'linear(to-r, brand.400, brand.400)',

      color: 'white',
      _isLoading: {
        bg: 'red.900',
      },
      _active: {},
      _hover: {
        transform: 'scale(0.99)',
        bgGradient: 'linear(to-r, primary.500, primary.400)',
      },
      _focus: {},
    },
    solidWhite: {
      bg: 'white',

      color: 'brand.400',

      _active: {},
      _hover: {
        transform: 'scale(0.99)',
        //bgGradient: 'linear(to-r, primary.500, primary.400)',
      },
      _focus: {},
    },
    solidGray: {
      bg: 'gray.100',

      color: 'gray.500',

      _active: {},
      _hover: {
        transform: 'scale(0.99)',
        //bgGradient: 'linear(to-r, primary.500, primary.400)',
      },
      _focus: {},
    },
    solidSecondary: {
      bg: 'brand.300',

      color: 'white',
      _isLoading: {
        bg: 'red.900',
      },
      _active: {},
      _hover: {
        transform: 'scale(0.99)',
        bgGradient: 'linear(to-r, primary.500, primary.400)',
      },
      _focus: {},
    },
    solidTertiary: {
      bg: 'brand.100',

      color: 'brand.200',
      _isLoading: {
        bg: 'red.900',
      },
      _active: {},
      _hover: {
        transform: 'scale(0.99)',
        bgGradient: 'linear(to-r, primary.500, primary.400)',
        color: 'brand.100',
      },
      _focus: {},
    },
    solid_square: {
      bg: 'brand.400',

      borderWidth: '0.5px',
      borderRadius: '5px',
      color: 'white',
      rounded: 'none',
      field: {},
    },
    outline: {
      borderColor: 'brand.400',
      color: 'brand.400',
      field: {
        _hover: {
          borderColor: 'red.700',
        },
      },
    },
    outline_bold: {
      borderColor: 'brand.400',
      borderWidth: '1px',

      color: 'brand.400',
      _hover: {
        color: 'white',
        bg: 'brand.400',
        transform: 'scale(0.99)',
      },
    },
    outline_no_border: {
      border: 'none',
    },
    outline_square: {
      borderColor: 'brand.400',
      borderWidth: '0.5px',
      borderRadius: '5px',
      color: 'brand.400',
      rounded: 'none',
      field: {},
    },
    outline_white: {
      borderColor: '#FFFFFF',
      borderWidth: 1,
      color: 'white',
      field: {
        _hover: {
          borderColor: 'white',
        },
      },
    },
    outline_delete: {
      color: 'red.500',
      borderWidth: 1,
      borderColor: 'red.500',
      field: {
        _hover: {
          borderColor: 'red.700',
        },
      },
    },
    outline_info: {
      color: 'blue.500',
      borderWidth: 1,
      borderColor: 'blue.500',
      field: {
        _hover: {
          borderColor: 'blue.700',
        },
      },
    },
    link: {
      borderRadius: 'none',
      _hover: {
        boxShadow: 'none',
      },
    },
    gradient: (props) => {
      const { theme, fromcolor, tocolor } = props;
      const lgFrom = getColor(theme, fromcolor);
      const lgTo = getColor(theme, tocolor);
      const bgColor = getColor(theme, mode('white', 'gray.800')(props));
      const bgColorHover = getColor(theme, mode('gray.100', 'gray.800')(props));

      return {
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 'full',
        background: `linear-gradient(${bgColor}, ${bgColor}) padding-box, 
        linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
        '> *': {
          background: `linear-gradient(135deg, ${lgFrom}, ${lgTo})`,
          backgroundClip: 'text',
          textFillColor: 'transparent',
        },
        _hover: {
          transform: 'scale(0.98)',
          background: `linear-gradient(${bgColorHover}, ${bgColorHover}) padding-box, 
          linear-gradient(315deg, ${lgFrom}, ${lgTo}) border-box`,
          '> *': {
            background: `linear-gradient(315deg, ${lgFrom}, ${lgTo})`,
            backgroundClip: 'text',
          },
        },
      };
    },
  },
  defaultProps: {},
};
