import { VStack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const VerticalStackBlok = ({ blok }) => {
  return (
    <VStack
      alignItems={blok.center ? "center" : "flex-start"}
      justifyContent={blok.justify ?? "center"}
      px={{ base: 0, xl: 0 }}
      rowGap={2}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </VStack>
  );
};

export default VerticalStackBlok;
