import * as C from '../constants';

const DEFAULT_STATE = {
  bannerDismiss: false,
};

export const appSettings = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.TOGGLE_BANNER_DISMISS:
      return {
        bannerDismiss: !state.bannerDismiss,
      };
    default:
      return state;
  }
};
