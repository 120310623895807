import { Grid, Stack, Text } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const RelatedGridBlok = ({ blok }) => {
  return (
    <Stack
      direction="column"
      {...storyblokEditable(blok)}
      mx={[5, blok.pageMargins && "10%"]}
    >
      <Text mb={5} fontWeight="bold" textStyle="h3">
        {blok.title}
      </Text>
      <Grid
        gridTemplateColumns={[
          "repeat(2, minmax(0, 1fr))",
          "repeat(5, minmax(0, 1fr))",
        ]}
        columnGap={5}
        rowGap={5}
      >
        {blok &&
          blok.items &&
          blok.items.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Grid>
    </Stack>
  );
};

export default RelatedGridBlok;
