import { logout } from "./logout";

import { cognitoUserPool, cognitoUser } from "./cognitoAPI";
import { getData } from "../../utilities/axios";

export const checkEmailInUse = (email) => async () => {
  const { inUse } = await getData(
    `${process.env.NEXT_PUBLIC_NEW_USER_URL}availableEmail/${email}`
  );
  return inUse;
};

/* Sign Up New User */
export const signUpUser = (data) => async (dispatch) => {
  const { email, password } = data;

  const attributeList = [];

  // Ensure auth tokens are reset when signing up
  dispatch(logout())
    .then(() => {})
    .catch((err) => {
      if (err.message !== "Not logged in") {
        console.error("ERROR FROM LOGOUT", err.name, err.message, err.cause);
      }
    });

  // Add host to metadata
  let metadata = {
    ...data.metadata,
    host: process.env.NEXT_PUBLIC_HOST_URL,
  };

  return new Promise((resolve, reject) => {
    cognitoUserPool.signUp(
      email,
      password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          let error =
            err.message ===
            "PreSignUp failed with error Username must be unique."
              ? "That email is already in use"
              : err.message || JSON.stringify(err);
          reject(error);
          return;
        }

        resolve(result);
      },
      metadata
    );
  });
};

// Confirms that the user is coming from a correct email and completes the sign up process
export const confirmCode = (email, code) => () => {
  let user = cognitoUser(email);
  return new Promise((resolve, reject) => {
    user.confirmRegistration(code, false, function (err, result) {
      if (result) {
        resolve();
      } else {
        reject(err);
      }
    });
  });
};

export const resendConfirmation = (email) => {
  let user = cognitoUser(email);
  return new Promise((resolve, reject) => {
    user.resendConfirmationCode((err, result) => {
      if (err) {
        let error = err.message || JSON.stringify(err);
        reject(error);
        return;
      }
      resolve(result);
    });
  });
};
