import { Flex, SimpleGrid } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const RecommendationCategoryBlok = ({ blok }) => {
  return (
    <SimpleGrid
      columns={[2, 5]}
      spacing={[5, 5]}
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </SimpleGrid>
  );
};

export default RecommendationCategoryBlok;
