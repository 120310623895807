import * as C from "../constants";

const DEFAULT_STATE = { clientList: [], selectedViewed: null };

export const clientList = (state = DEFAULT_STATE, action) => {

  switch (action.type) {
    case C.UPDATE_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload,
      };
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    case C.SELECT_USER_BOOKINGS:
      return {
        ...state,
        selectedViewed: action.payload,
      };
    default:
      return state;
  }
};
