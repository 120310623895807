import { Box, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const VerticalSpacerBlok = ({ blok }) => {
  return (
    <Box
      h={blok.amount ?? 0} //NOTE: base was set to none for mobile views
      {...storyblokEditable(blok)}
    />
  );
};

export default VerticalSpacerBlok;
