import * as C from '../constants';

const DEFAULT_STATE = {
  address: '',
  availability: [
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
  ],
  clientGroups: [],
  coachPracticeAreas: [],
  coachPractitionerModalities: [],
  concessions: [],
  distance: 0,
  genders: [],
  languages: [],
  location: { lat: null, lng: null },
  maxSessionPrice: 1000,
  orderBy: {
    value: 'practitionerName',
    ascending: true,
  },
  outOfOffice: false,
  practiceAreas: [],
  practiceStartDate: new Date(),
  practitionerName: '',
  practitionerType: [{ value: 1, label: 'Therapist', selected: true }],
  rating: [],
  resultIndex: 0,
  resultItems: 20,
  sessionTypes: [],
  showHelp: false,
  therapistPracticeAreas: [],
  therapistPractitionerModalities: [],
  verified: true,
};

export const DEFAULT_QUERY_STATE = { ...DEFAULT_STATE };

export const searchPractitionerQuery = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.UPDATE_SEARCH_PRACTITIONER_QUERY:
      return {
        ...state,
        ...action.payload,
      };
    case C.RESET_SEARCH_PRACTITIONER_QUERY:
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
