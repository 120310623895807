import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

/* Get Cognito User Pool */
export const cognitoUserPool = new CognitoUserPool({
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
});

/* Get Cognito User */
export const cognitoUser = (email) => {
  return new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });
};

/* Get Authentication Details */
export const authenticationDetails = (email, password) => {
  return new AuthenticationDetails({
    Username: email,
    Password: password,
  });
};

/* Get the current Session */
export const getSession = async (user) => {
  return await new Promise((resolve, reject) => {
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.error('Problem getting session', err);
          reject();
        } else {
          //authorizedCognitoUser = user;
          resolve(session);
        }
      });
    } else {
      reject();
    }
  });
};

/* Refresh the Current Session */
export const refreshSession = async (email) => {
  const user = cognitoUserPool.getCurrentUser(email);
  let currentSession = await getSession(user);
  return new Promise((resolve, reject) => {
    user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
      if (err) {
        let error = err.message || JSON.stringify(err);
        reject(error);
        return;
      }
      currentSession = session;
      resolve(session);
    });
  });
};
