import { Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const HeaderBlok = ({ blok }) => {
  return (
    <Text
      textStyle="h3"
      color={blok.colour ?? "black"}
      my={5}
      mx={[0, blok.pageMargins && "10%"]}
      textAlign={[blok.overrideMobileAlignment ? "left" : "center", "left"]}
      {...storyblokEditable(blok)}
    >
      {blok.text}
    </Text>
  );
};

export default HeaderBlok;
