import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import { Field, useField } from "formik";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      {props.formlabel && (
        <FormLabel textStyle="h6" htmlFor={props.formlabel}>
          {props.required && (
            <Text display="inline-block" color="red">
              *
            </Text>
          )}
          {props.formlabel}
        </FormLabel>
      )}
      {props.icon && (
        <InputGroup>
          <InputLeftElement pointerEvents="none">{props.icon}</InputLeftElement>
          <Field
            as={Input}
            marginLeft="32px"
            {...field}
            {...props}
            ref={props.innerRef}
          />
        </InputGroup>
      )}
      {!props.icon && (
        <Field as={Input} {...field} {...props} ref={props.innerRef} />
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TextInput;
