import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { format } from "date-fns";
import Image from "next/image";
import Link from "next/link";

const EventInfoBlok = ({ blok }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        flexDir="column"
        mx={[0, blok.pageMargins && "10%"]}
        w="100%"
        onClick={() => onOpen()}
        cursor="pointer"
        {...storyblokEditable(blok)}
      >
        <Box
          overflow="hidden"
          width="100%"
          borderRadius="10px"
          height={blok.isApp ? "170px" : "250px"}
          position="relative"
          border="1px"
          borderColor="brand.300"
        >
          <Image
            src={blok.image.filename}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
        <Text fontWeight="bold" pt={5}>
          {blok.title}
        </Text>
        <Text color="brand.200">{blok.location}</Text>
        <Text color="brand.200">
          {format(new Date(blok.dateTime), "dd/MM/yyyy kk:mm")}
        </Text>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            bgColor="brand.50"
            borderRadius="100%"
            position="absolute"
            zIndex={2}
          />
          <ModalBody p={0}>
            <Flex
              justifyContent="flex-start"
              flexDir={{ base: "column", md: "row" }}
            >
              <Box
                width={{ base: "100%", md: "250px" }}
                height="400px"
                position="relative"
                overflow="hidden"
                borderLeftRadius={{ base: 0, md: "5px" }}
              >
                <Image
                  layout="fill"
                  alt="practitioner photo"
                  objectFit="cover"
                  objectPosition="center"
                  src={blok.image.filename}
                />
              </Box>
              <Flex
                flexDir="column"
                p={{ base: 5, md: 10 }}
                w={{ base: "100%", md: "60%" }}
              >
                <Text textStyle="h3">{blok.name}</Text>
                <Text color="brand.200" fontSize={14} fontWeight="bold">
                  {blok.title}
                </Text>
                <Text>{blok.details}</Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EventInfoBlok;
