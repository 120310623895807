import { Box } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { imageServiceTransformer } from "../../../src/utilities/helpers";

const FullWidthImageBlok = ({ blok }) => {
  return (
    <Box
      mx={{ base: 0, md: "auto" }}
      my={blok.marginY ?? 10}
      width={["100%", blok.pageMargins ? "80%" : "100%"]}
      h={blok.height ? blok.height : "400px"}
      className="w-full relative"
      {...storyblokEditable(blok)}
    >
      <Image
        src={imageServiceTransformer(
          blok.image.filename,
          blok.imageServiceOverride ?? "1600x800/filters:quality(75)"
        )}
        alt={blok.image.alt}
        fill
        objectFit="contain"
        objectPosition="50% 50%"
        priority
        fetchPriority="high"
      />
    </Box>
  );
};

export default FullWidthImageBlok;
