import { Flex, Icon, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { FaCalendarAlt, FaUserMd } from "react-icons/fa";
import { FaClipboard, FaClipboardList } from "react-icons/fa6";

const STUDENT_TEXT = [
  "Answer a few quick questions to help us match you to the right therapist or counsellor for your needs, budget and schedule. This will only take 3 minutes.",
  "Our mental health expert will review your profile and select the therapist offering low-cost online therapy (£35) to students who is best suited to your needs.",
  "Once the match is approved we'll make a booking* that suits you. And if you are not happy with your first session we'll find another match for free.",
  "*There is a £2.50 booking fee charge to clients of the Student Personalised Matching Service",
];

const SteppedExplainerBlok = ({ blok }) => {
  return (
    <Flex
      flexDir="column"
      {...storyblokEditable(blok)}
      alignItems="center"
      rowGap={10}
      my={10}
      bgColor="rgba(247, 247, 248, 1)"
      py="40px"
      px={[5, "10%"]}
    >
      <Text textStyle="h3">How it works</Text>
      <Flex
        flexDir={["column", "row"]}
        flexWrap="nowrap"
        justifyContent={"center"}
        pos="relative"
        px={{ base: 0, xl: "40px" }}
        rowGap={5}
        columnGap={5}
      >
        <Flex
          flexDir={["row", "column"]}
          justifyContent={["space-between", "flex-start"]}
          alignItems={["flex-start", "center"]}
          textAlign="center"
          width={{ base: "100%", lg: "33.3%", xl: "33.3%" }}
          mx={{ base: 0, xl: "20px" }}
          columnGap={10}
        >
          <Flex
            borderRadius="100px"
            minH={{ base: "70px", xl: "140px" }}
            minW={{ base: "70px", xl: "140px" }}
            zIndex={2}
            border="3px solid"
            borderColor={blok.colour}
            mb="40px"
            alignItems="center"
            justifyContent="center"
            bgColor="rgba(247, 247, 248, 1)"
          >
            <Icon
              as={FaClipboardList}
              boxSize={{ base: 6, xl: 12 }}
              color={blok.colour}
            />
          </Flex>
          <Flex
            flexDir="column"
            pos="relative"
            justifyContent={{ base: "flex-start" }}
            alignItems={{ base: "center" }}
          >
            <Flex
              bgColor={blok.colour}
              h={["100%", "3px"]}
              left={["-75px", "50%"]}
              w={["3px", "100%"]}
              pos="absolute"
              top={{
                base: "40px",
                sm: "-80px",
                md: "-80px",
                lg: "-80px",
                xl: "-110px",
              }}
            ></Flex>
            <Text
              fontSize={{ base: "14pt", lg: "16pt", xl: "16pt" }}
              fontWeight="bold"
              mb="10px"
              color={blok.colour}
            >
              1. Tell us about yourself
            </Text>
            <Text>{blok.step1Text}</Text>
          </Flex>
        </Flex>
        <Flex
          flexDir={["row", "column"]}
          justifyContent={["space-between", "flex-start"]}
          alignItems={["flex-start", "center"]}
          textAlign="center"
          width={{ base: "100%", lg: "33.3%", xl: "33.3%" }}
          mx={{ base: 0, xl: "20px" }}
          columnGap={10}
        >
          <Flex
            borderRadius="100px"
            minH={{ base: "70px", xl: "140px" }}
            minW={{ base: "70px", xl: "140px" }}
            zIndex={2}
            border="3px solid"
            borderColor={blok.colour}
            mb="40px"
            alignItems="center"
            justifyContent="center"
            bgColor="rgba(247, 247, 248, 1)"
          >
            <Icon
              as={FaUserMd}
              boxSize={{ base: 6, xl: 12 }}
              color={blok.colour}
            />
          </Flex>
          <Flex
            flexDir="column"
            pos="relative"
            justifyContent={{ base: "flex-start" }}
            alignItems={{ base: "center" }}
          >
            <Flex
              bgColor={blok.colour}
              h={["100%", "3px"]}
              left={["-75px", "50%"]}
              w={["3px", "100%"]}
              pos="absolute"
              top={{
                base: "40px",
                sm: "-80px",
                md: "-80px",
                lg: "-80px",
                xl: "-110px",
              }}
            ></Flex>
            <Text
              fontSize={{ base: "14pt", lg: "16pt", xl: "16pt" }}
              fontWeight="bold"
              mb="10px"
              color={blok.colour}
            >
              2. We find your therapist
            </Text>
            <Text>{blok.step2Text}</Text>
          </Flex>
        </Flex>
        <Flex
          flexDir={["row", "column"]}
          justifyContent={["space-between", "flex-start"]}
          alignItems={["flex-start", "center"]}
          textAlign="center"
          width={{ base: "100%", lg: "33.3%", xl: "33.3%" }}
          mx={{ base: 0, xl: "20px" }}
          columnGap={10}
        >
          <Flex
            borderRadius="100px"
            minH={{ base: "70px", xl: "140px" }}
            minW={{ base: "70px", xl: "140px" }}
            zIndex={2}
            border="3px solid"
            borderColor={blok.colour}
            mb="40px"
            alignItems="center"
            justifyContent="center"
            bgColor="rgba(247, 247, 248, 1)"
          >
            <Icon
              as={FaCalendarAlt}
              boxSize={{ base: 6, xl: 12 }}
              color={blok.colour}
            />
          </Flex>
          <Flex
            flexDir="column"
            pos="relative"
            justifyContent={{ base: "flex-start" }}
            alignItems={{ base: "center" }}
          >
            <Text
              fontSize={{ base: "14pt", lg: "16pt", xl: "16pt" }}
              fontWeight="bold"
              mb="10px"
              color={blok.colour}
            >
              3. We book a session
            </Text>
            <Text>{blok.step3Text}</Text>
            <Text mt="3px" fontSize="10pt" color="gray.500">
              {blok.step3Subtext}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SteppedExplainerBlok;
