import { Accordion } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const AccordionBlok = ({ blok }) => {
  return (
    <Accordion
      allowToggle
      {...storyblokEditable(blok)}
      mx={[0, blok.pageMargins && "10%"]}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </Accordion>
  );
};

export default AccordionBlok;
