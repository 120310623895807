import jwtDecode from 'jwt-decode';
const USER_SESSION_TOKEN = 'wdUserToken';

/*
USER_SESSION_TOKEN {
    jwtToken: idToken from Cognito
    expiry: expiry date in seconds
}
*/

export const isAuthenticated = () => {
  const token = getToken();

  if (!token) {
    return false;
  }

  if (Date.now() / 1000 - 24 * 60 * 60 < jwtDecode(token).iat) {
    return token;
  } else {
    return false;
  }
};

export const saveTokenToLocalStorage = (token) => {
  const storageAvailable = (type) => {
    let storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  };

  let item = '';
  if (typeof token === 'object') {
    item = JSON.stringify(token);
  } else {
    item = token;
  }

  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(USER_SESSION_TOKEN, item);
  }
  return;
};

export const getToken = () => {
  return JSON.parse(window.localStorage.getItem(USER_SESSION_TOKEN));
};

export const removeToken = () => {
  return window.localStorage.removeItem(USER_SESSION_TOKEN);
};

export const getUserIdFromToken = () => {
  const token = window.localStorage.getItem(USER_SESSION_TOKEN);
  if (token) {
    //return jwtDecode(token).userId;
    return true;
  } else {
    return null;
  }
};
