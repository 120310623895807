import { Box, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";

const AuthorBioBlok = ({ blok }) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="flex-start"
      borderRadius={[0, "10px"]}
      borderWidth={1}
      borderColor="brand.400"
      p={5}
      rowGap={5}
      my={10}
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      <Flex alignItems="center" columnGap={5}>
        <Box
          overflow="hidden"
          width="100px"
          borderRadius="100px"
          height="100px"
          position="relative"
        >
          <Image
            src={blok.image.filename}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="profile header image"
          />
        </Box>
        <Text fontWeight="bold" fontSize="16pt">
          {blok.name}
        </Text>
      </Flex>
      <Text>{blok.bio}</Text>
    </Flex>
  );
};

export default AuthorBioBlok;
