import * as C from '../constants';

const DEFAULT_STATE = { profiles: [], articles: [] };

export const bookmarks = (state = DEFAULT_STATE, action) => {

  switch (action.type) {
    case C.UPDATE_PROFILE_BOOKMARKS:
      return {
        ...state,
        profiles: action.payload,
      };
    case C.UPDATE_ARTICLE_BOOKMARKS:

      return {
        ...state,
        articles: action.payload,
      };
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
