export default {
  baseStyle: {
    color: 'brand.300',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 4,
      m: 1,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
      m: 1,
    },
  },
};
