import { Flex, Stack, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import YouTube from "react-youtube";

const YouTubeEmbedBlok = ({ blok }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flex={1}
      {...storyblokEditable(blok)}
    >
      {blok.videoId && (
        <Stack
          px={{ md: 100, xl: 100 }}
          maxW={["100vw", "75vw"]}
          justifyContent="center"
          flex={1}
        >
          <YouTube opts={{ width: "100%" }} videoId={blok.videoId} />
        </Stack>
      )}
    </Flex>
  );
};

export default YouTubeEmbedBlok;
