import { CheckIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  Heading,
  Stack,
  Text,
  Stat,
  HStack,
  StatNumber,
  Tooltip,
  StatHelpText,
} from "@chakra-ui/react";

const AnnualSavingsRibbon = () => {
  return (
    <Tooltip
      hasArrow
      label="Save £30 versus paying quarterly"
      bg="primary.200"
      color="brand.300"
      placement="top"
    >
      <div class="absolute right-0 top-0 h-16 w-16">
        <div class="absolute transform rotate-45 bg-brand text-center text-white font-semibold py-1 right-[-48px] top-[18px] w-[170px]">
          Save £30
        </div>
      </div>
    </Tooltip>
  );
};

const PaymentSubscriptionCard = (props) => {
  const { plan, selectedPlan } = props;
  return (
    <Card
      maxW="sm"
      minW="300px"
      margin="8px"
      //height="sm"
      border="2px solid white"
      bg={plan.value === selectedPlan.value ? "gray.100" : "white"}
      borderColor={plan.value === selectedPlan.value ? "brand.400" : "white"}
      onClick={() => {
        props.setPaymentPlan(plan);
      }}
      overflow="hidden"
      cursor="pointer"
    >
      <CardBody>
        <Stack spacing="3">
          <Heading size="md">
            {plan.label}
            {plan.value === selectedPlan.value ? (
              <CheckIcon color="green" paddingLeft="8px" />
            ) : (
              ""
            )}
          </Heading>
          <Text color="brand.200">{plan.description}</Text>
        </Stack>
        <Stat>
          <HStack align={"end"}>
            <StatNumber>£{Number(plan.price).toFixed(2)}</StatNumber>
            <Text
              color="text.50"
              fontSize="xs"
              style={{
                // Has to be done inline because of some global override style
                marginInlineStart: 0,
              }}
            >
              + VAT/{plan.label.slice(0, -2)}
            </Text>
          </HStack>
          {!!plan.verificationFee && (
            <Tooltip
              hasArrow
              label="We verify every practitioner with their accreditation body once per year to ensure that all of our practitioners are experienced professionals."
              bg="primary.200"
              color="brand.300"
              placement="bottom"
            >
              <StatHelpText>
                +£{plan.verificationFee} + VAT/year verification fee
              </StatHelpText>
            </Tooltip>
          )}
        </Stat>
        {plan.label === "Annually" && <AnnualSavingsRibbon />}
      </CardBody>
    </Card>
  );
};

export default PaymentSubscriptionCard;
