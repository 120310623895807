import * as C from '../constants';

// Actions with dispatch
export const addAuthenticationData = (data) => (dispatch) => {
  dispatch({
    type: C.ADD_AUTHENTICATION_DATA,
    payload: data,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: C.LOGOUT_EXISTING_USER,
  });
};
