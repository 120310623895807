import * as C from '../constants';

const DEFAULT_STATE = {
  clientBookings: [],
  userBookings: [],
  linkedBookings: null,
  bookings: null,
  selectedBooking: null,
  mode: 'view',
};

export const bookings = (state = DEFAULT_STATE, action) => {

  switch (action.type) {
    case C.UPDATE_CLIENT_BOOKINGS:
      return {
        ...state,
        clientBookings: action.payload,
      };
    case C.UPDATE_USER_BOOKINGS:
      return {
        ...state,
        userBookings: action.payload,
      };
    case C.LOGOUT_EXISTING_USER:
      return DEFAULT_STATE;
    case C.GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      }
    case C.SELECT_BOOKING:
      return {
        ...state,
        selectedBooking: action.payload.booking,
        mode: action.payload.mode,
      };
    case C.DISPLAY_LINKED_BOOKINGS:
      return {
        ...state,
        linkedBookings: action.payload,
      };
    case C.CLOSE_LINKED_BOOKINGS:
      return {
        ...state,
        linkedBookings: null,
      }
    case C.ARCHIVE_BOOKING:
      if(!state.bookings) {
        return state;
      }

      return {
        ...state,
        bookings: {
          upcoming: state.bookings.upcoming.filter(booking => booking.bookingId !== action.payload.booking.bookingId),
          past: state.bookings.past.filter(booking => booking.bookingId !== action.payload.booking.bookingId),
          archived: state.bookings.archived.concat([{
            ...action.payload.booking,
            removeFromUserList: action.payload.userId,
            removeFromPractitionerList: action.payload.userId,
          }]).sort((a, b) => {
            if(a.bookingDate > b.bookingDate) {
              return -1;
            }

            if(a.bookingDate < b.bookingDate) {
              return 1;
            }
            return 0;
          }),
        }
      }
    default:
      return state;
  }
};
