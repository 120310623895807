/* maketintandshades.com */

export default {
  // purple 5f00d6
  wdpurple: {
    50: '#F3E9FC',
    100: '#DEC1F6',
    200: '#C999F0',
    300: '#B471EA',
    400: '#9E49E4',
    500: '#8922DD',
    600: '#6E1BB1',
    700: '#521485',
    800: '#370D59',
    900: '#1B072C',
  },
  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },
  footer: {
    700: '#004E71',
  },
  text: {
    50: '#8EA8B4', // light grey
    100: '#004E71', //teal
    200: '#5f00d6', // purple
  },
  primary: {
    // Verdun Green
    50: '#eef1e6',
    100: '#dee3cc',
    200: '#bcc899',
    300: '#9bac66',
    400: '#799133',
    500: '#587500', //Verdun Green
    600: '#465e00',
    700: '#354600',
    800: '#232f00',
    900: '#121700',
  },
  secondary: {
    // Masala
    50: '#ecebeb',
    100: '#d9d8d7',
    200: '#b2b1b0',
    300: '#8c8988',
    400: '#656261',
    500: '#3f3b39',
    600: '#322f2e',
    700: '#262322',
    800: '#191817',
    900: '#0d0c0b',
  },
  sanddune: {
    50: '#f3f0ef',
    100: '#e6e1df',
    200: '#cdc4be',
    300: '#b5a69e',
    400: '#9c897d',
    500: '#836b5d',
    600: '#69564a',
    700: '#4f4038',
    800: '#342b25',
    900: '#1a1513',
  },
  brand: {
    50: '#ffffff', // white
    100: '#F6F4EF', // spring wood
    200: '#836B5D', // Sand Dune/Dawn Pink
    300: '#3F3B39', // Masala
    400: '#587500', //Verdun Green
  },
  /*
  brand: {
    50: "#f8f8ff", // light purple used in page backgrounds
    100: "#004e71", // teal
    200: "#f3f3f3", //sand
    300: "#8b26de", //redpurple
    400: "#85c3e0", //cornflower
    500: "#5f00d6", // purple
    600: "#eef6f9", // light blue used in messages
  },
    primary: {
    // wdpurple
    50: '#F3E9FC',
    100: '#DEC1F6',
    200: '#C999F0',
    300: '#B471EA',
    400: '#9E49E4',
    500: '#8922DD',
    600: '#6E1BB1',
    700: '#521485',
    800: '#370D59',
    900: '#1B072C',
  },
  secondary: {
    //teal
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    //500: '#319795',
    500: '#004E71',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },
  oldbrand 600 to new 100
  oldbrand 500 to new 400
  oldbrand 100 to new 300

  oldtext 100 to newbrand 300
  oldtext 50 to new brand 200

  brand.300 to brand 300
  */
};
