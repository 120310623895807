import {
  VStack,
  HStack,
  Button,
  Box,
  useToast,
  Tooltip,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";

import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../components/forms/TextInput";
import PasswordInput from "../components/forms/PasswordInput";
import SelectInput from "../components/forms/SelectInput";
import SignUpFooter from "./SignUpFooter";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef } from "react";

import * as N from "../utilities/notifications";
import { logInUser, signUpUser } from "./api";
import { postData, patchData, getData } from "../utilities/axios";
import { getUserObject } from "../redux/actions/user";
import CheckboxInput from "../components/forms/CheckboxInput";
import PaymentSubscriptionCard from "../components/forms/PaymentSubscriptionCard";
import { useUtilityData } from "../utilities/contexts";
import Link from "next/link";
import { checkEmailInUse } from "./api/signup";

const SignUpTherapistForm = ({ noBorder = "false", close = null }) => {
  const utilityData = useUtilityData();
  const token = useSelector((state) => state.user.JWTToken);
  const cancelRef = useRef();
  const dispatch = useDispatch();
  const toast = useToast();

  const [chkConfirmFeeValue, setChkConfirmFeeValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [step, setStep] = useState(0);
  const [paymentPlan, setPaymentPlan] = useState(
    utilityData.paymentPlans.filter(
      (plan) => plan.available === 1 && plan.groupId === -1
    )[0]
  );
  const [profile, setProfile] = useState(null);
  const [currToken, setCurrToken] = useState(null);
  const [associations, setAssociations] = useState(
    utilityData.therapistAssociations
  );

  const {
    isOpen: isVerificationAlertOpen,
    onOpen: onVerificationAlertOpen,
    onClose: onVerificationAlertClose,
  } = useDisclosure();

  const passwordCriteria =
    "Password must be at least 8 characters long and include an uppercase letter, a number and a symbol such as !@£$%^*";

  const updateAssociationSelection = (val, action) => {
    let arrNew;
    if (action === "add") {
      arrNew = associations.map((elem) => {
        return Number(elem.value) === Number(val)
          ? { ...elem, selected: true }
          : elem;
      });
    } else {
      arrNew = associations.map((elem) => {
        return Number(elem.value) === Number(val)
          ? { ...elem, selected: false }
          : elem;
      });
    }
    setAssociations(arrNew);
  };

  const handleSubmitPayment = async () => {
    const practitionerType = "therapist";

    // construct query
    let queryJSON = {
      profileId: profile.profileId,
      practitionerType: "therapist",
      bodies: associations
        .filter((elem) => elem.selected === true)
        .map((elem) => ({ value: elem.value, label: elem.label })),
    };

    // Construct new profile with paymentPlan

    let json = {
      paymentPlan: paymentPlan.stripeId,
      isRegistration: true,
    };
    setLoading(true);

    try {
      await patchData(
        token,
        json,
        `${process.env.NEXT_PUBLIC_NEW_USER_URL}paymentPlan`,
        `${process.env.NEXT_PUBLIC_NEW_USER_APIKEY}`
      );

      await postData(
        token,
        queryJSON,
        `${process.env.NEXT_PUBLIC_NEW_USER_URL}bodies`,
        `${process.env.NEXT_PUBLIC_NEW_USER_APIKEY}`
      );

      let req = {
        email: signUpEmail,
        practitionerType,
        redirectUrl: `${process.env.NEXT_PUBLIC_HOST_URL}/accounts/practitioner-account/dashboard`,
      };

      await dispatch(getUserObject(currToken));

      if (close) {
        close();
      } else {
        const res = await postData(
          token,
          req,
          `${process.env.NEXT_PUBLIC_NEW_PAYMENT_URL}subscription/register`
        );
        window.location.replace(res.url);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast(N.signupFail(err));
    } finally {
      setLoading(false);
    }
  };

  const renderAlertBox = () => (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onVerificationAlertClose}
      isOpen={isVerificationAlertOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Proceed with verification payment</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          By clicking Proceed below, you will be transferred to Stripe to pay
          your verification fee. <br></br>
          <br></br>This is a non refundable annual fee which authorises
          Welldoing to check your professional accreditation. This may take up
          to 48 hours. <br></br>
          <br></br>
          We will verify you against the following association(s).<br></br>
          <b>
            {associations
              .filter((elem) => elem.selected === true)
              .map((elem) => elem.label)
              .join(", ")}
          </b>
          <br></br>
          <br></br>Once you have been verified, your subscription will commence.{" "}
          <br></br>
          <br></br>In the meantime, welcome to the Welldoing community and we
          invite you to begin filling in your profile.
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={onVerificationAlertClose}
            variant="ghost"
          >
            Back
          </Button>
          <Button
            //colorScheme="red"
            ml={3}
            onClick={() => {
              onVerificationAlertClose();
              handleSubmitPayment();
            }}
          >
            Proceed to payment
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  const VerifyBodiesPage = () => {
    return (
      <Formik
        validateOnBlur={false}
        initialValues={{
          professionalBody: "",
          chkConfirmFee: false,
        }}
        onSubmit={async (values, { setErrors }) => {
          // Check for association choice
          let selectedAssociations = associations.filter(
            (elem) => elem.selected === true
          );

          if (selectedAssociations.length === 0) {
            setErrors({
              professionalBody:
                "Please select at least one professional body to be verified against",
            });
          } else {
            if (!chkConfirmFeeValue) {
              setErrors({
                chkConfirmFee: "Please authorise your verification fee",
              });
            } else {
              setStep(2);
            }
          }
        }}
      >
        {(formik) => (
          <>
            <div className="flex flex-col mb-2 gap-2">
              <Text textStyle="h3" id="therapist_registration">
                Start the verification process
              </Text>
              <p className="text-brand-alt">
                At Welldoing we independently verify members against their
                professional bodies to ensure all our therapists are
                professionally qualified. Verification usually takes 1-2 working
                days and is carried out every 12 months from your sign up date.
                We will email you if we need more information.
              </p>
              <span className="text-brand-alt">
                The fee for the annual verification process is £15 + VAT per
                year.
              </span>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-col items-start"
            >
              <SelectInput
                name="professionalBody"
                variant="filled"
                placeholder="Please choose"
                formlabel="Please select each body which you would like to be verified
              against"
                onChange={(e) =>
                  updateAssociationSelection(e.target.value, "add")
                }
              >
                {associations
                  ?.filter((elem) => elem.selected !== true)
                  .map((item, idx) => {
                    return (
                      <option key={idx} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
              </SelectInput>
              <Box maxWidth="100%" wordBreak="break-word">
                {associations
                  ?.filter((elem) => elem.selected === true)
                  .map(({ selected, value, label }, idx) => (
                    <Tag
                      margin="4px"
                      key={`tag${idx}`}
                      cursor="pointer"
                      size="lg"
                      borderRadius="full"
                      variant={selected ? "solid" : "unselected"}
                      bg={selected ? "brand.100" : "brand.100"}
                      color={selected ? "brand.300" : "brand.300"}
                      border="none"
                    >
                      <TagLabel wordBreak="break-word">{label}</TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          updateAssociationSelection(value, "remove");
                        }}
                      />
                    </Tag>
                  ))}
              </Box>
              <CheckboxInput
                name="chkConfirmFee"
                description="I confirm I am happy for Welldoing Ltd to charge an annual verification fee. This fee is non-refundable."
                isChecked={chkConfirmFeeValue}
                onChange={() => setChkConfirmFeeValue(!chkConfirmFeeValue)}
                textStyle="footer1"
                color="brand.200"
                colorScheme="green"
              />
              <Box p="1rem 0 0 0" w="100%">
                <Button
                  w="full"
                  variant="solid"
                  //onClick={(e) => formik.handleSubmit(e)}
                  type="submit"
                >
                  Next step
                </Button>
              </Box>
              <SignUpFooter />
            </form>
          </>
        )}
      </Formik>
    );
  };

  const PaymentPage = () => {
    return (
      <>
        <Box mx="12px">
          <VStack align="flex-start">
            <Text textStyle="h3">Choose your subscription</Text>
          </VStack>
        </Box>
        {loading && <LoadingSpinner message="Transferring to payment... " />}

        <VStack
          maxW="full"
          padding={noBorder === "true" ? "8px" : "20px"}
          mx="auto"
          spacing={4}
          alignItems="flex-start"
          bg="white"
          borderRadius={{ base: 0, lg: 20 }}
          boxShadow={noBorder ? "none" : "lg"}
          overflow="hidden"
          position="relative"
        >
          {renderAlertBox()}

          <Box p="1rem 0 0 0" w="100%">
            <div className="flex flex-col sm:flex-row w-full jusitfy-around my-5">
              {utilityData.paymentPlans
                .filter((plan) => plan.available === 1 && plan.groupId === -1)
                .sort((a, b) =>
                  a.label > b.label ? -1 : a.label < b.label ? 1 : 0
                )
                .map((plan) => (
                  <PaymentSubscriptionCard
                    key={plan.value}
                    plan={{ ...plan, verificationFee: 15 }}
                    selectedPlan={paymentPlan}
                    setPaymentPlan={setPaymentPlan}
                  />
                ))}
            </div>
            <div className="my-5 mx-3 font-bold">
              You have selected {paymentPlan.label}
            </div>
            <Divider />
            <HStack marginTop="24px">
              <Button
                w="full"
                variant="outline"
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
              <Button
                w="full"
                variant="solid"
                onClick={onVerificationAlertOpen}
              >
                Next step
              </Button>
            </HStack>
          </Box>
        </VStack>
      </>
    );
  };

  switch (step) {
    case 0:
      return (
        <Formik
          key="Sign up"
          enableReinitialize={false}
          initialValues={{
            fullname: "",
            email: "",
            confirmEmail: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            fullname: Yup.string().required("Full name required"),
            email: Yup.string().email().required("Email required"),
            confirmEmail: Yup.string()
              .email()
              .required("Please confirm your email")
              .oneOf([Yup.ref("email")], "Email does not match"),
            password: Yup.string()
              .required("Password required")
              .min(8, passwordCriteria)
              .matches(
                /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
                passwordCriteria
              ),
            confirmPassword: Yup.string()
              .required("Please confirm your password")
              .min(8, passwordCriteria)
              .matches(
                /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
                passwordCriteria
              )
              .oneOf([Yup.ref("password")], "Your passwords do not match"),
          })}
          onSubmit={async (values) => {
            // setSubmitting(true);
            setLoading(true);
            const { email, password } = values;

            // Create metadata object
            let metadata = { ...values };

            delete metadata.password;
            delete metadata.confirmPassword;
            delete metadata.professionalBody;
            metadata.type = "practitioner";
            metadata.subType = "therapist";

            // Create data object
            const data = {
              email,
              password,
              metadata,
              accountType: 2,
            };

            try {
              const inUse = await dispatch(checkEmailInUse(data.email));
              if (inUse) {
                toast(N.signupFail(`This email address is already in use.`));
              } else {
                await dispatch(signUpUser(data));
                setSignUpEmail(data.email);

                let newToken = await logInUser({
                  email: data.email,
                  password: data.password,
                });

                setCurrToken(newToken);
                const objUser = await getData(
                  `${process.env.NEXT_PUBLIC_NEW_USER_URL}profile`,
                  null,
                  newToken
                );
                setProfile(objUser);

                setStep(1);
              }
            } catch (err) {
              toast(
                N.signupFail(
                  `There was a problem signing up. ${err} Please check your details and try again.`
                )
              );
            } finally {
              // setSubmitting(false);
              setLoading(false);
            }
          }}
        >
          {(formik) => (
            <>
              <Box mx="12px">
                <VStack align="flex-start">
                  <Text textStyle="h3">Sign up today</Text>
                  <Text
                    textStyle="body1"
                    color="brand.200"
                    paddingBottom="20px"
                  >
                    Join our community of professionally verified therapists
                  </Text>
                </VStack>
              </Box>
              {loading && (
                <LoadingSpinner message="Registering your email... " />
              )}

              <VStack
                as="form"
                maxW="full"
                py={noBorder ? 2 : 7}
                px={noBorder ? 2 : 5}
                mx="auto"
                spacing={4}
                alignItems="flex-start"
                bg="brand.50"
                borderRadius={{ base: 0, lg: 20 }}
                onSubmit={formik.handleSubmit}
                borderColor="brand.200"
                borderWidth={noBorder ? 0 : { base: 0, lg: 0.5 }}
                overflow="hidden"
                position="relative"
              >
                <TextInput
                  name="fullname"
                  formlabel="Full name (including title if relevant)"
                  type="text"
                  placeholder="Robin"
                />

                <TextInput
                  name="email"
                  formlabel="Email"
                  type="email"
                  placeholder="e.g. robin@yourmail.com"
                />
                <TextInput
                  name="confirmEmail"
                  formlabel="Confirm email"
                  type="email"
                  placeholder="e.g. robin@yourmail.com"
                />
                <Box
                  display={{ base: "block", lg: "flex" }}
                  w="100%"
                  justifyContent="space-between"
                >
                  <Tooltip
                    hasArrow
                    label="Password must be at least 8 characters long and include an uppercase & lowercase letter, a number and a symbol such as !@£$%^*"
                    bg="primary.200"
                    color="brand.300"
                    placement="bottom"
                  >
                    <Box w={{ base: "100%", lg: "48%" }}>
                      <PasswordInput
                        name="password"
                        placeholder="8+characters"
                        formlabel="Password"
                      />
                    </Box>
                  </Tooltip>
                  <Box w={{ base: "100%", lg: "48%" }} mt={[4, 4, 0]}>
                    <PasswordInput
                      name="confirmPassword"
                      formlabel="Confirm password"
                      placeholder="Re-enter password"
                    />
                  </Box>
                </Box>

                <Box p="1rem 0 0 0" w="100%">
                  <Button
                    isDisabled={Object.keys(formik.errors).length > 0}
                    w="full"
                    variant="solid"
                    type="submit"
                  >
                    Next step
                  </Button>
                </Box>
                <Link
                  prefetch={false}
                  href="/registration"
                  target="_blank"
                  className="w-full flex justify-center"
                >
                  <Button variant="link" fontSize="0.75rem" color="brand.400">
                    Click here to find out more
                  </Button>
                </Link>
                <SignUpFooter />
              </VStack>
            </>
          )}
        </Formik>
      );
    case 1:
      return <VerifyBodiesPage />;
    case 2:
      return <PaymentPage />;
    default:
      return <Text>Sorry, there is a problem</Text>;
  }
};

export default SignUpTherapistForm;
