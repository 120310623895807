import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { MARK_LINK, NODE_UL, render } from "storyblok-rich-text-react-renderer";

const AccordionItemBlok = ({ blok }) => {
  return (
    <AccordionItem {...storyblokEditable(blok)}>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {blok.title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {render(blok.body, {
          markResolvers: {
            [MARK_LINK]: (
              children,
              { linktype, href, target, anchor, uuid, custom }
            ) => (
              <Link prefetch={false} href={href} color="brand.400">
                {children}
              </Link>
            ),
          },
          nodeResolvers: {
            [NODE_UL]: (children) => (
              <ul style={{ listStyleType: "disc" }}>{children}</ul>
            ),
          },
        })}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionItemBlok;
