export default {
  variants: {
    filled: {
      field: {
        bg: 'brand.100', //sand
        color: 'brand.300', // teal
        _focus: {
          bgColor: 'brand.100',
        },
      },
    },
  },
  defaultProps: {},
};
