import { Button, Flex, Grid, HStack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";

const ShowHideBlok = ({ blok }) => {
  const [gridCountLimit, setGridCountLimit] = useState(6);
  return (
    <Flex flexDir="column" alignItems="stretch">
      <Grid
        {...storyblokEditable(blok)}
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={3}
        my={10}
        mx={[5, blok.pageMargins && "10%"]}
      >
        {blok &&
          blok.items.map((nestedBlok, index) => {
            if (index >= gridCountLimit) {
              return;
            }
            return (
              <HStack
                key={nestedBlok._uid}
                borderBottomWidth={blok.noLines ? 0 : 1}
                borderBottomColor={"sanddune.200"}
                borderStyle={"solid"}
                py={blok.noLines ? 1 : 3}
              >
                <CheckIcon color={blok.checkColour ?? "brand.200"} />
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              </HStack>
            );
          })}
      </Grid>
      {gridCountLimit < blok.items.length && (
        <Button
          size="sm"
          fontSize="sm"
          variant="solid"
          w="fit-content"
          alignSelf="center"
          px={10}
          py={5}
          mt={5}
          mb={10}
          onClick={() => setGridCountLimit(blok.items.length)}
        >
          Show more
        </Button>
      )}
      {gridCountLimit >= blok.items.length && (
        <Button
          size="sm"
          fontSize="sm"
          variant="solid"
          w="fit-content"
          alignSelf="center"
          px={10}
          py={5}
          mt={5}
          mb={10}
          onClick={() => setGridCountLimit(6)}
        >
          Show fewer
        </Button>
      )}
    </Flex>
  );
};

export default ShowHideBlok;
