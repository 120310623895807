import { Box, Text, Container, Flex, SimpleGrid } from '@chakra-ui/react';
import Image from 'next/legacy/image';

const SignUpChoiceCards = ({ setChoice }) => {
  const cardData = [
    {
      key: 1,
      image: '/images/authentication/clientSignUp.svg',
      title: 'Sign up as a Client',
      subtitle: 'Find and book verified, professional therapists.',
      page: 'client',
    },
    {
      key: 2,
      image: '/images/authentication/therapistSignUp.svg',
      title: 'Sign up as a Therapist',
      subtitle: 'Take bookings and manage your schedule.',
      page: 'therapist',
    },
  ];

  return (
    <SimpleGrid columns={2} spacing={4}>
      {cardData.map((card, i) => (
        <Container
          key={i}
          w="100%"
          cursor="pointer"
          bg="brand.100"
          onClick={() => setChoice(card.page)}
          _hover={{
            boxShadow: 'xl',
            transform: 'scale(0.98)',
            transition: 'scale 0.9s ease',
          }}
          borderRadius={10}
          p="20px"
          margin="24px 0px 24px 0px"
        >
          <Flex direction="row" alignItems="center">
            <Image
              height="48px"
              width="48px"
              src={card.image}
              alt="sign up illustration"
            />
            <Flex
              direction="column"
              ml="1rem"
              justifyContent="start"
              align="start"
            >
              <Text textStyle="h5">{card.title}</Text>
            </Flex>
          </Flex>
          <Text textStyle="body2" color="brand.200" paddingTop="20px">
            {card.subtitle}
          </Text>
        </Container>
      ))}
    </SimpleGrid>
  );
};

export default SignUpChoiceCards;
