import { HStack, Stack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const HorizontalStackBlok = ({ blok }) => {
  return (
    <Stack
      direction={[blok.overrideMobileColumnView ? "row" : "column", "row"]}
      justifyContent={blok.layout ?? "center"}
      alignItems={blok.alignment ?? "center"}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
      py={blok.paddingVertical ?? 0}
      maxW={"100%"}
      bgColor={blok.backgroundColor ?? "white"}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </Stack>
  );
};

export default HorizontalStackBlok;
