import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { MARK_BOLD, render } from "storyblok-rich-text-react-renderer";
import NestingWrapperBlok from "./NestingWrapperBlok";

const HighlightBoxBlok = ({ blok }) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="flex-start"
      bgColor={blok.backgroundColour ?? "brand.400"}
      borderRadius={[0, "30px"]}
      py="40px"
      px="30px"
      {...storyblokEditable(blok)}
    >
      <Text textStyle="h3" color={blok.colour}>
        {blok.title}
      </Text>
      <NestingWrapperBlok bloks={blok.body} />
    </Flex>
  );
};

export default HighlightBoxBlok;
