import { Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const TitleBlok = ({ blok, ...props }) => {
  const controlled = {
    ...props,
    ...blok,
  };

  return (
    <Text
      textStyle="h1"
      mx={[0, controlled.pageMargins && "10%"]}
      mb={controlled.marginBottom ?? 30}
      color={controlled.colour ? controlled.colour : "secondary.500"}
      textAlign={["center", "left"]}
      display={{ base: "block", xl: "block" }} //NOTE: base was set to none for mobile views
      {...(blok ? storyblokEditable(blok) : {})}
    >
      {controlled.text}
    </Text>
  );
};

export default TitleBlok;
