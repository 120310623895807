import { Flex } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const WrappingFlexBlok = ({ blok }) => {
  return (
    <Flex
      direction={"row"}
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems={"center"}
      columnGap={[2, 5]}
      rowGap={[2, 5]}
      mx={[2, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </Flex>
  );
};

export default WrappingFlexBlok;
