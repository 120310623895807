import { lighten, getColor, mode } from '@chakra-ui/theme-tools';

export default {
  variants: {
    unselected: {
      container: {
        bg: 'whiteAlpha.800',
        rounded: 'full',
        border: '1px',
        color: 'brand.200',
        borderColor: 'brand.300',

        //display: 'inline-flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        transition: 'background 0.3s ease',
        _hover: {
          transform: 'scale(0.99)',
          borderColor: 'brand.300',
          color: 'brand.300',
        },
      },
    },
    unmatched: {
      container: {
        bg: 'brand.100',
        rounded: 'full',
        //border: '1px',
        color: 'brand.300',
        //borderColor: 'brand.300',

        //display: 'inline-flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        transition: 'background 0.3s ease',
        _hover: {
          transform: 'scale(0.99)',
          borderColor: 'brand.300',
          color: 'brand.300',
        },
      },
    },
    selected: {
      container: {
        color: 'white',
      },
    },
  },

  baseStyle: {
    label: {
      fontFamily: 'Open Sans',
      //fontSize: '15px',
      //fontWeight: 500,
      //lineHeight: '22px',
      //overflow: 'visible',
    },
  },
};
