import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Field, useField } from 'formik';
import { IconEyeClosed, IconEyeOpen } from '../../../styles/icons';

const PasswordInput = ({ label, ...props }) => {
  // Formik
  const [field, meta] = useField(props);

  // Use eye icon
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <FormLabel htmlFor="password" textStyle="h6">
        {props.formlabel ? props.formlabel : null}
      </FormLabel>
      <InputGroup>
        <Field
          as={Input}
          {...field}
          {...props}
          type={show ? 'text' : 'password'}
        />
        <InputRightElement width="4.5rem">
          <IconButton
            bg="brand.100"
            variant="filled"
            color="brand.200"
            aria-label="Show Password"
            fontSize="20px"
            h={6}
            w={6}
            p={0}
            icon={show ? <IconEyeOpen /> : <IconEyeClosed />}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default PasswordInput;
