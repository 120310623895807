import { ArrowForwardIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";

const ArticleNavBoxBlok = ({ blok }) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="flex-start"
      borderRadius={[0, "10px"]}
      borderWidth={1}
      borderColor="brand.400"
      maxW="450px"
      py={3}
      px={5}
      rowGap={2}
      my={10}
      mx={[5, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
    >
      <Text fontWeight="bold" color="brand.400">
        {blok.navText}
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text maxW={"70%"} fontWeight="bold" textOverflow="ellipsis">
          {blok.text}
        </Text>
        <Flex
          h="40px"
          w="40px"
          justifyContent="center"
          alignItems="center"
          bgColor="brand.400"
          borderRadius="100px"
        >
          <ArrowForwardIcon boxSize={6} color="white" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ArticleNavBoxBlok;
