import { cognitoUserPool } from './cognitoAPI';
import {
  logout as logoutAction
} from '../../redux/actions/authenticationData';
import { removeToken } from './tokenManagement';

export const logout = (userDetails = {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const user = cognitoUserPool.getCurrentUser();
      // 1) sign out of cognito
      if (user) {
        user.signOut();
      }

      // 2) remove local storage
      removeToken();

      // 3) reset redux object
      try {
        dispatch(logoutAction());
      } catch (error) {
        reject(new Error('Problem resetting redux user object'));
      }
      resolve(true);
    });
  };
}
