import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import TextBlok from "./TextBlok";
import LogInModal from "../../authentication/LogInModal";
import { useState } from "react";

const col1 = [
  "Match your expertise with suitable clients",
  "Promote the articles you write",
  "Use our online booking system",
  "Receive quick online payments",
];

const JoinUsBlok = ({ blok }) => {
  const [showTherapistSignupModal, setShowTherapistSignupModal] =
    useState(false);

  return (
    <Flex
      {...storyblokEditable(blok)}
      flexDir={["column", "row"]}
      p={[5, 10]}
      px={[5, "10%"]}
      bgColor="rgba(247, 247, 248, 1)"
      gap={10}
    >
      {showTherapistSignupModal && (
        <LogInModal
          title=""
          onCloseModal={() => setShowTherapistSignupModal(false)}
          signUpChoice={blok.signupChoice ?? "therapist"}
        />
      )}
      <Flex flexDir="column" flex={1} rowGap={3}>
        <Flex
          bgColor="white"
          flexDir="column"
          py={[5, 10]}
          px={[0, 10]}
          rowGap={0}
        >
          <Text textStyle="h3" px={[5, 0]}>
            Let us help you find clients and improve the way you work
          </Text>
          <Flex flexDir={["column", "row"]} columnGap={10} rowGap={5}>
            <VStack alignItems="flex-start">
              {col1.map((line) => (
                <HStack
                  key={line}
                  w="100%"
                  borderBottomWidth={1}
                  borderBottomColor={"sanddune.200"}
                  borderStyle={"dashed"}
                  py={3}
                  px={[3, 0]}
                >
                  <CheckIcon color="brand.200" />
                  <Text>{line}</Text>
                </HStack>
              ))}
            </VStack>
          </Flex>
          <Button
            w={"fit-content"}
            bgColor="brand.400"
            mt={5}
            onClick={() => setShowTherapistSignupModal(true)}
          >
            <Text textStyle="button1">Register now</Text>
          </Button>
        </Flex>
      </Flex>
      <VStack flex={3} rowGap={3} alignItems="flex-start">
        <Text textStyle="h3" fontWeight="bold" color="brand.400">
          Welldoing is the UK's leading therapist-matching platform
        </Text>
        <Text textStyle="h3">Why you should join us</Text>
        <Text>
          The Welldoing platform helps therapists and counsellors across the UK
          find the best clients for their practice, saving time and letting
          therapists focus on what's important.
        </Text>
        <Flex
          flexDir="column"
          color="white"
          bgColor="brand.400"
          borderRadius="10px"
          w="100%"
          p={5}
        >
          <Text color="white" textStyle="h3">
            Therapist benefits
          </Text>
          <TextBlok text={blok.therapistBenefits} />
        </Flex>
      </VStack>
    </Flex>
  );
};
export default JoinUsBlok;
