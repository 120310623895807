import { Box, Flex } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const TwoColumnBlok = ({ blok }) => {
  return (
    <Flex
      flexDir={{ base: "column", md: "row", lg: "row", xl: "row" }}
      gap={[blok.overrideMobileGap ? 0 : 5, 10]}
      mx={[5, blok.pageMargins ? "10%" : 0]}
      flexBasis={"100%"}
      maxW={"100%"}
      justifyContent={blok.columnSpacing ?? "space-between"}
      {...storyblokEditable(blok)}
    >
      <Box
        flex={1}
        maxW={blok.column1MaxWidth}
        justifyContent={blok.column1Justify}
      >
        {blok.column1 &&
          blok.column1.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Box>
      <Box
        flex={1}
        maxW={blok.column2MaxWidth}
        justifyContent={blok.column2Justify}
      >
        {blok.column2 &&
          blok.column2.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </Box>
    </Flex>
  );
};

export default TwoColumnBlok;
