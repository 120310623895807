import ButtonBlok from "./ButtonBlok";
import FullWidthImageBlok from "./FullWidthImageBlok";
import HeaderBlok from "./HeaderBlok";
import HeroBlok from "./HeroBlok";
import HorizontalStackBlok from "./HorizontalStackBlok";
import IconTextBlok from "./IconTextBlok";
import InsetTextBlok from "./InsetTextBlok";
import LineSeparatedListBlok from "./LineSeparatedListBlok";
import MembershipSelectBlok from "./MembershipSelectBlok";
import PageBlok from "./PageBlok";
import PullQuoteBlok from "./PullQuoteBlok";
import ReversibleTextImagePairBlok from "./ReversibleTextImagePairBlok";
import SteppedExplainerBlok from "./SteppedExplainerBlok";
import TeaserBlok from "./TeaserBlok";
import TestimonialBubbleBlok from "./TestimonialBubbleBlok";
import TextBlok from "./TextBlok";
import TitleBlok from "./TitleBlok";
import TwoColumnBlok from "./TwoColumnBlok";
import UnorderedListBlok from "./UnorderedListBlok";
import VerticalSpacerBlok from "./VerticalSpacerBlok";
import VerticalStackBlok from "./VerticalStackBlok";
import YouTubeEmbedBlok from "./YouTubeEmbedBlok";
import AccordionBlok from "./AccordionBlok";
import AccordionItemBlok from "./AccordionItemBlok";
import BenefitsBlok from "./BenefitsBlok";
import JoinUsBlok from "./JoinUsBlok";
import ImpactTextBlok from "./ImpactTextBlok";
import TeamMemberBlok from "./TeamMemberBlok";
import HighlightBoxBlok from "./HighlightBoxBlok";
import TestimonialsBlok from "./TestimonialsBlok";
import RecommendedItemBlok from "./RecommendedItemBlok";
import RecommendationCategoryBlok from "./RecommendationCategoryBlok";
import RecommendedOfTheMonthBlok from "./RecommendedOfTheMonthBlok";
import RecommendationGridBlok from "./RecommendationGridBlok";
import LozengeBlok from "./LozengeBlok";
import WrappingFlexBlok from "./WrappingFlexBlok";
import BreadcrumbWrapperBlok from "./BreadcrumbWrapperBlok";
import BreadcrumbBlok from "./BreadcrumbBlok";
import AuthorBioBlok from "./AuthorBioBlok";
import ArticleNavBoxBlok from "./ArticleNavBoxBlok";
import PolicyTemplateBlok from "./PolicyTemplateBlok";
import ShowHideBlok from "./ShowHideBlok";
import ArticlePreviewBlok from "./ArticlePreviewBlok";
import WhatsWorryingYouBlok from "./WhatsWorryingYouBlok";
import WorryBlok from "./WorryBlok";
import EventsGridBlok from "./EventsGridBlok";
import EventInfoBlok from "./EventInfoBlok";
import EditableBenefitsBlok from "./EditableBenefitsBlok";
import DropdownSelectBlok from "./DropdownSelectBlok";
import DropdownOptionBlok from "./DropdownOptionBlok";
import ThreeImageBlok from "./ThreeImageBlok";
import SponsorGridBlok from "./SponsorGridBlok";
import SimpleFlexBlok from "./SimpleFlexBlok";
import DividerWithTextBlok from "./DividerWithTextBlok";
import PracticeAreaSelectBlok from "./PracticeAreaSelectBlok";
import RelatedGridBlok from "./RelatedGridBlok";
import RelatedItemBlok from "./RelatedItemBlok";
import CPDGridBlok from "./CPDGridBlok";
import CPDInfoBlok from "./CPDInfoBlok";
import NextUpcomingCPDBlok from "./NextUpcomingCPDBlok";
import TestimonialGridItemBlok from "./TestimonialGridItemBlok";
import TypesOfTherapyBlok from "./TypesOfTherapyBlok";
import LoginSignupButtonBlok from "./LoginSignupButtonBlok";
import RegistrationButtonBlok from "./RegistrationButtonBlok";

const storyblokComponents = {
  teaser: TeaserBlok,
  page: PageBlok,
  textBlock: TextBlok,
  pullQuote: PullQuoteBlok,
  insetBlock: InsetTextBlok,
  twoColumns: TwoColumnBlok,
  title: TitleBlok,
  header: HeaderBlok,
  fullWidthImage: FullWidthImageBlok,
  blokUnorderedList: UnorderedListBlok,
  verticalSpacer: VerticalSpacerBlok,
  hero: HeroBlok,
  reversibleTextImagePair: ReversibleTextImagePairBlok,
  button: ButtonBlok,
  lineSeparatedList: LineSeparatedListBlok,
  iconText: IconTextBlok,
  horizontalStack: HorizontalStackBlok,
  verticalStack: VerticalStackBlok,
  testimonialBubble: TestimonialBubbleBlok,
  accordion: AccordionBlok,
  accordionItem: AccordionItemBlok,
  steppedExplainer: SteppedExplainerBlok,
  youtubeEmbed: YouTubeEmbedBlok,
  benefitsBlok: BenefitsBlok,
  membershipSelect: MembershipSelectBlok,
  joinUsBlok: JoinUsBlok,
  impactText: ImpactTextBlok,
  teamMember: TeamMemberBlok,
  highlightBox: HighlightBoxBlok,
  testimonials: TestimonialsBlok,
  recommendedItem: RecommendedItemBlok,
  recommendationCategory: RecommendationCategoryBlok,
  recommendedOfTheMonth: RecommendedOfTheMonthBlok,
  recommendationGrid: RecommendationGridBlok,
  lozenge: LozengeBlok,
  wrappingFlex: WrappingFlexBlok,
  breadcrumbWrapper: BreadcrumbWrapperBlok,
  breadcrumb: BreadcrumbBlok,
  authorBio: AuthorBioBlok,
  articleNavBox: ArticleNavBoxBlok,
  policyTemplate: PolicyTemplateBlok,
  showHide: ShowHideBlok,
  articlePreview: ArticlePreviewBlok,
  whatsWorryingYou: WhatsWorryingYouBlok,
  worry: WorryBlok,
  eventsGrid: EventsGridBlok,
  eventInfo: EventInfoBlok,
  editableBenefitsBlok: EditableBenefitsBlok,
  dropdownSelect: DropdownSelectBlok,
  dropdownOption: DropdownOptionBlok,
  threeImageGroup: ThreeImageBlok,
  sponsorGrid: SponsorGridBlok,
  simpleFlexBlok: SimpleFlexBlok,
  dividerWithTextBlok: DividerWithTextBlok,
  practiceAreaSelect: PracticeAreaSelectBlok,
  relatedGridBlok: RelatedGridBlok,
  relatedItemBlok: RelatedItemBlok,
  cpdGridBlok: CPDGridBlok,
  cpdInfoBlok: CPDInfoBlok,
  nextUpcomingCpdBlok: NextUpcomingCPDBlok,
  testimonialGridItemBlok: TestimonialGridItemBlok,
  typesOfTherapyBlok: TypesOfTherapyBlok,
  loginSignupButtonBlok: LoginSignupButtonBlok,
  registrationButton: RegistrationButtonBlok,
};

export default storyblokComponents;
