import { Box, Text, Button } from "@chakra-ui/react";
import { Fragment } from "react";
import Link from "next/link";

const SignUpFooter = () => (
  <Fragment>
    <Box align="center" w="100%">
      <Text fontSize="0.75rem" color="brand.300">
        By registering for this service you agree to the
        <Link href="/terms-and-conditions" target="_blank" prefetch={false}>
          <Button variant="link" fontSize="0.75rem" color="brand.400">
            Terms of Use
          </Button>
        </Link>
        ,
        <Link href="/privacy-policy" target="_blank" prefetch={false}>
          <Button variant="link" fontSize="0.75rem" color="brand.400">
            Privacy Policy
          </Button>
        </Link>
        and
        <Link href="/welldoing-charter" target="_blank" prefetch={false}>
          <Button variant="link" fontSize="0.75rem" color="brand.400">
            Welldoing charter
          </Button>
        </Link>
      </Text>
    </Box>
  </Fragment>
);

export default SignUpFooter;
