import { Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const TestimonialBubbleBlok = ({ blok }) => {
  return (
    <Flex
      borderRadius={"10px"}
      pos="relative"
      w={{ base: "90vw", xl: "320px" }}
      maxW={{ base: "300px", xl: "320px" }}
      bgColor={blok.color}
      opacity={0.8}
      color="white"
      lineHeight="26px"
      my="40px"
      p="22px"
      _after={{
        content: `""`,
        position: "absolute",
        bottom: 0,
        left: "50%",
        w: 0,
        h: 0,
        border: "28px solid transparent",
        borderTopColor: blok.color,
        borderRight: 0,
        borderBottom: 0,
        marginLeft: "-40%",
        marginBottom: "-28px",
        zIndex: 2,
      }}
      {...storyblokEditable(blok)}
    >
      {blok.text}
      <Text
        margin="auto"
        fontWeight="bold"
        pos="absolute"
        bottom="-35px"
        left={0}
        textAlign="left"
        pl="22px"
        marginLeft="20%"
      >
        {blok.source}
      </Text>
    </Flex>
  );
};

export default TestimonialBubbleBlok;
