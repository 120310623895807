import { DEFAULT_FILTER_STATE } from '../../redux/reducers/practitionerFilters';
import { getData } from '../axios';
import {createContext, useState, useEffect, useContext} from 'react';
import dayjs from "dayjs";

/**
 * @typedef {Object.<string, any>} TUtilityData
 */

/**
 * @typedef {Object} TUtilityDataContext
 * @property {TUtilityData} utilityData
 * @property {boolean} utilityDataLoaded
 * @property {Function} refreshUtilityData
 */

/**
 * @callback TSelectorCallback
 * @param {TUtilityData} state
 * @returns {any}
 */

/**
 * @type {import('react').Context<TUtilityDataContext>}
 */
export const UtilityDataContext = createContext({
  utilityData: DEFAULT_FILTER_STATE,
  utilityDataLoaded: false,
  refreshUtilityData: () => {},
});

let CACHE_PATH;
let fs;

if(typeof window === 'undefined') {
  const path = require('node:path');
  CACHE_PATH = path.join(__dirname, 'utilityData.json');
}

const fetchData = async () => {
  let cachedData;
  const convertArray = (arr, arrLabels = []) =>
    arr.map((elem) => ({
      ...elem,
      selected: arrLabels.includes(elem.label),
  }));

  if(typeof window === 'undefined') {
    try {
      fs = require('node:fs');
      cachedData = JSON.parse(fs.readFileSync(CACHE_PATH, 'utf-8'));
    } catch (e) {
      console.warn('Cache not initialised');
    }
  }
  else {
    try {
      cachedData = JSON.parse(window.localStorage.getItem('wdUtilCache'));
      if(dayjs(cachedData.expiry) < dayjs() || cachedData.id !== parseInt(process.env.NEXT_PUBLIC_UTILITIES_ID, 10)) {
        cachedData = null;
      }
    } catch (e) {
      console.warn('Cache not initialised');
    }
  }

  if(!cachedData) {
    const utilData = await getData(
      `${process.env.NEXT_PUBLIC_NEW_UTILITIES_URL}utilities`,
      process.env.NEXT_PUBLIC_NEW_UTILITIES_APIKEY
    );
    cachedData = utilData;
    if(typeof window === 'undefined') {
      try {
        await fs.writeFileSync(CACHE_PATH, JSON.stringify(utilData));
      } catch (e) {
        console.error('Error writing cache file');
      }
    }
    else {
      window.localStorage.setItem('wdUtilCache', JSON.stringify({
        ...cachedData,
        expiry: dayjs().add(30, 'm').toISOString(),
      }));
    }
  }
  /**
   * @type {Object.<string, any>}
   */
  return {
    ...cachedData,
    clientGroups: cachedData.clientGroups.map((elem) => ({
      ...elem,
      selected: false,
    })),
    genders: cachedData.genders.map((elem) => ({
      ...elem,
      selected: false,
    })),
    concessions: cachedData.concessions.map((elem) => ({
      ...elem,
      selected: false,
    })),
    therapistPracticeAreas: cachedData.therapistPracticeAreas.map((elem) => ({
      ...elem,
      selected: false,
    })),
    // Default english to selected
    languages: convertArray(cachedData.languages),
    // Default online to selected
    sessionTypes: convertArray(cachedData.sessionTypes),
  };
};

export const useUtilityData = () => {
  const { utilityData } = useContext(UtilityDataContext);
  return utilityData;
}

/**
 *
 * @param {TSelectorCallback} selector Selector callback function
 * @returns {any} Selector callback function return value
 */
export const useUtilityDataSelector = (selector) => {
  const { utilityData } = useContext(UtilityDataContext);
  return selector(utilityData);
}

export function UtilityDataProvider({children}) {
  /**
   * @type {[TUtilityData, React.Dispatch<React.SetStateAction<TUtilityData>>]}
   */
  const [utilityData, setUtilityData] = useState({});
  const [utilityDataLoaded, setUtilityDataLoaded] = useState(false);

  useEffect(() => {
    if (utilityDataLoaded) {
      return;
    }
    fetchData().then(
      (utilityData) => {
        setUtilityData({
          ...utilityData
        });
        setUtilityDataLoaded(true);
      }
    );
  }, [utilityDataLoaded]);

  const refreshUtilityData = () => {
    setUtilityDataLoaded(false);
  }

  return (
    <UtilityDataContext.Provider value={{ utilityData, utilityDataLoaded, refreshUtilityData }}>
      { children }
    </UtilityDataContext.Provider>
  );
};
