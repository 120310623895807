import { useDispatch } from "react-redux";
import { logInUser, signUpUser } from "./api";
import {
  VStack,
  Button,
  Box,
  useToast,
  Tooltip,
  Divider,
} from "@chakra-ui/react";

import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../components/forms/TextInput";
import PasswordInput from "../components/forms/PasswordInput";
import SignUpFooter from "./SignUpFooter";
import * as N from "../utilities/notifications";
import { getUserObject } from "../redux/actions/user";
import { useRouter } from "next/router";

const SignUpClientForm = ({
  noBorder = false,
  emailValidation = "true",
  noRedirect = false,
  loginCallback = null,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const router = useRouter();

  const passwordCriteria =
    "Password must be at least 8 characters long and include an uppercase & lowercase letter, a number and a symbol such as !@£$%^*";

  const handleSubmit = async (data, setSubmitting) => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "form_submit",
        formId: "client-form",
      });

      await dispatch(signUpUser(data));
      setSubmitting(false);
      let token = await logInUser({
        email: data.email,
        password: data.password,
      });

      await dispatch(getUserObject(token));

      if (noRedirect) {
        return true;
      }

      router.push(
        "/accounts/user-account/dashboard?success=true&registration=client"
      );
    } catch (err) {
      setSubmitting(false);
      toast(
        N.signupFail(
          `There was a problem signing up. ${err} Please check your details and try again.`
        )
      );

      return true;
    }
  };

  return (
    <>
      <Formik
        id="client-form"
        initialValues={{
          fullname: "",
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          type: "Client",
          subType: null,
        }}
        validateOnBlur={false}
        validationSchema={Yup.object({
          fullname: Yup.string().required("Full name required"),
          email: Yup.string().email().required("Email required"),
          confirmEmail: Yup.string()
            .email()
            .required("Please confirm your email")
            .oneOf([Yup.ref("email")], "Email does not match"),

          password: Yup.string()
            .required("Password required")
            .min(8, passwordCriteria)
            .matches(
              /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
              passwordCriteria
            ),
          confirmPassword: Yup.string()
            .required("Please confirm your password")
            .min(8, passwordCriteria)
            .matches(
              /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
              passwordCriteria
            )
            .oneOf([Yup.ref("password")], "Your passwords do not match"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const { email, password } = values;

          // Create metadata object
          let metadata = values;
          delete metadata.password;
          delete metadata.confirmPassword;
          metadata.emailValidation = emailValidation;

          // Create data object
          const data = {
            email,
            password,
            metadata,
          };

          handleSubmit(data, setSubmitting);
        }}
      >
        {(formik) => (
          <VStack
            as="form"
            maxW="full"
            py={noBorder ? 2 : 7}
            px={noBorder ? 1 : 5}
            mx="auto"
            spacing={4}
            alignItems="flex-start"
            bg="white"
            borderRadius={{ base: 0, lg: 20 }}
            onSubmit={formik.handleSubmit}
            borderColor="brand.200"
            borderWidth={noBorder ? 0 : { base: 0, lg: 0.5 }}
            overflow="hidden"
            position="relative"
            id="client-registration-form"
          >
            <TextInput
              name="fullname"
              formlabel="Full name"
              type="text"
              placeholder="Robin"
            />
            <TextInput
              name="email"
              formlabel="Email"
              type="email"
              placeholder="e.g. robin@yourmail.com"
            />
            <TextInput
              name="confirmEmail"
              formlabel="Confirm email"
              type="email"
              placeholder="e.g. robin@yourmail.com"
            />
            <Box
              display={{ base: "block", md: "flex" }}
              w="100%"
              justifyContent="space-between"
            >
              <Tooltip
                hasArrow
                label={passwordCriteria}
                bg="primary.200"
                color="brand.300"
                placement="bottom"
              >
                <Box w={{ base: "100%", md: "48%" }}>
                  <PasswordInput
                    name="password"
                    placeholder="8+characters"
                    formlabel="Password"
                  />
                </Box>
              </Tooltip>
              <Box w={{ base: "100%", md: "48%" }} mt={[4, 4, 0]}>
                <PasswordInput
                  name="confirmPassword"
                  formlabel="Confirm password"
                  placeholder="Re-enter password"
                />
              </Box>
            </Box>
            <Box p="1rem 0 0 0" w="100%">
              <Button
                w="full"
                variant="solid"
                type="submit"
                isLoading={formik.isSubmitting}
                isDisabled={Object.keys(formik.errors).length > 0}
                loadingText="Submitting"
              >
                Sign up
              </Button>
            </Box>
            <div className="w-full flex justify-center items-center gap-4">
              <div className="flex-1 border-b border-b-brand-off" />
              <span className="text-sm text-brand-alt py-2">Or</span>
              <div className="flex-1 border-b border-b-brand-off" />
            </div>
            <div className="w-full flex justify-center items-center">
              <Button
                className="border bg-white border-brand rounded-full text-brand hover:text-white hover:bg-brand transition px-4 py-2"
                onClick={loginCallback}
              >
                Log into existing account
              </Button>
            </div>
            <SignUpFooter />
          </VStack>
        )}
      </Formik>
    </>
  );
};

export default SignUpClientForm;
