import { calc, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";

const WorryBlok = ({ blok }) => {
  return (
    <Flex
      borderWidth={1}
      borderColor="brand.400"
      borderRadius={10}
      p={4}
      h={175}
      overflow="hidden"
      flexDir="column"
      {...storyblokEditable(blok)}
    >
      <Text
        textStyle="h5"
        fontWeight="bold"
        mb={2}
        color={blok.colour ?? "brand.400"}
      >
        {blok.title}
      </Text>
      <Text
        textOverflow="ellipsis"
        noOfLines={4}
        color={blok.subTextColour ?? "black"}
      >
        {blok.body}
      </Text>
    </Flex>
  );
};

export default WorryBlok;
