import { Container } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const NestingWrapperBlok = ({ bloks }) => (
  <Container px={0} {...storyblokEditable(bloks)}>
    {bloks &&
      bloks.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
  </Container>
);
export default NestingWrapperBlok;
