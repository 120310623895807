import "../styles/globals.css";
import Layout from "./layout/layout";
import theme from "../styles/chakra";
import { ChakraProvider, useToast } from "@chakra-ui/react";

import { wrapper } from "../src/redux";
import { PersistGate } from "redux-persist/integration/react";
import { useStore, useDispatch, useSelector } from "react-redux";
import {useCallback, useEffect} from "react";
import { storyblokInit, apiPlugin } from "@storyblok/react";

import { resetLayout } from "../src/redux/actions/layout";
import jwtDecode from "jwt-decode";
import { updateUserJWT } from "../src/redux/actions/user";
import * as N from "../src/utilities/notifications";
import {saveTokenToLocalStorage} from "../src/authentication/api";
import {refreshSession} from '../src/authentication/api';
import { SocketContext, socket } from "../src/utilities/socketContext";
import {
  UtilityDataProvider,
} from "../src/utilities/contexts";
import storyblokComponents from "../src/components/storyblok";
import App from "next/app";

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
  use: [apiPlugin],
  apiOptions: { region: "eu" },
  components: storyblokComponents,
});

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.user);

  const checkToken = useCallback(async () => {
    if (user.JWTToken !== null) {
      let tokenExpired =
        Date.now() / 1000 - 24 * 60 * 60 >= jwtDecode(user.JWTToken).iat;
      if (tokenExpired) {
        try {
          let newSession = await refreshSession(user.userDetails.email);
          dispatch(updateUserJWT(newSession.idToken.jwtToken));
          await saveTokenToLocalStorage(newSession.idToken.jwtToken);
        } catch (error) {
          toast(N.expiredToken());
        }
      }
    }
  }, [toast, dispatch, user.JWTToken, user.userDetails?.email]);

  useEffect(() => {
    checkToken();
    dispatch(resetLayout());
  }, [dispatch, checkToken]);

  const store = useStore();

  return (
    <PersistGate persistor={store._persistor} loading={null}>
      <SocketContext.Provider value={socket}>
        <UtilityDataProvider>
          <ChakraProvider theme={theme}>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ChakraProvider>
        </UtilityDataProvider>
      </SocketContext.Provider>
    </PersistGate>
  );
};

/*MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(301, { Location: '/' });
    appContext.ctx.res.end();
  }

  return { ...appProps };
};*/

export default wrapper.withRedux(MyApp);
