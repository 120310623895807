import { Box, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";

const RelatedItemBlok = ({ blok }) => {
  return (
    <>
      <Flex
        flexDir="column"
        mx={[0, blok.pageMargins && "10%"]}
        w="100%"
        cursor="pointer"
        {...storyblokEditable(blok)}
      >
        <Link prefetch={false} href={blok.externalLink || blok.internalLink} legacyBehavior>
          <Box
            overflow="hidden"
            width="100%"
            borderRadius="10px"
            height={blok.isApp ? "170px" : "250px"}
            position="relative"
            border="1px"
            borderColor="brand.300"
          >
            <Image
              src={blok.image.filename}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt="profile header image"
            />
          </Box>
        </Link>
        <Link href={blok.externalLink || blok.internalLink} legacyBehavior>
          <Text fontWeight="bold" pt={5}>
            {blok.text}
          </Text>
        </Link>
      </Flex>
    </>
  );
};

export default RelatedItemBlok;
