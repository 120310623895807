import { Container, Flex } from "@chakra-ui/react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import ArticleSidebar from "../../articles/ArticleSidebar";

const PageBlok = ({ blok }) => {
  return (
    <Flex {...storyblokEditable(blok)} flexDir="column" mx="auto">
      <Flex direction="row">
        <Container
          maxW={{ base: "100%", xl: "100%" }}
          boxSizing="border-box"
          overflowX="hidden"
          px={0}
          flex={2}
          columnGap="120px"
        >
          {blok.breadcrumb &&
            blok.breadcrumb.map((nestedBlok) => (
              <Flex key={nestedBlok._uid} mt="20px" h="60px">
                <StoryblokComponent blok={nestedBlok} />
              </Flex>
            ))}
          {blok.body &&
            blok.body.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Container>

        {blok.sidebar && (
          <Container
            display={{ base: "none", md: "block" }}
            maxW="400px"
            flex={1}
            mr="5%"
            mt="80px"
          >
            <ArticleSidebar
              defaultVideoId="worryVideoId"
              overrideVideoId={blok.overrideSidebarVideoId || null}
              showSuggestedTherapists={false}
            />
          </Container>
        )}
      </Flex>
    </Flex>
  );
};

export default PageBlok;
