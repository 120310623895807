import * as C from '../constants';

const DEFAULT_STATE = {};

export const utilityData = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case C.UPDATE_UTILITY_DATA:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
