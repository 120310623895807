import { Icon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import {
  FaMagnifyingGlass,
  FaCertificate,
  FaComment,
  FaLightbulb,
  FaLock,
  FaStopwatch,
  FaTag,
  FaHandshake,
} from "react-icons/fa6";

const iconMap = {
  magnifyingGlass: FaMagnifyingGlass,
  handshake: FaHandshake,
  speechBubble: FaComment,
  padlock: FaLock,
  stopwatch: FaStopwatch,
  lightbulb: FaLightbulb,
  certificate: FaCertificate,
  tag: FaTag,
};

const ButtonBlok = ({ blok }) => {
  return (
    <Link prefetch={false} href={blok.link} {...storyblokEditable(blok)}>
      <Flex
        bgColor={blok.backgroundColour ?? "brand.200"}
        borderRadius={blok.squareVariant ? "5px" : "20px"}
        h={blok.squareVariant ? "45px" : "40px"}
        w={blok.expandWidth || blok.squareVariant ? "100%" : "fit-content"}
        maxW={blok.maxWidth}
        p={5}
        justifyContent="center"
        alignItems="center"
        borderColor={blok.borderColor}
        borderWidth={blok.borderWidth ?? 0}
        borderStyle="solid"
      >
        {blok.icon && (
          <Icon
            mr={2}
            as={iconMap[blok.icon]}
            boxSize={4}
            color={blok.iconColor ?? "black"}
          />
        )}
        <Text
          noOfLines={1}
          textStyle="button1"
          color={blok.textColor ?? "white"}
        >
          {blok.text}
        </Text>
      </Flex>
    </Link>
  );
};
export default ButtonBlok;
