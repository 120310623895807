import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

const BreadcrumbBlok = ({ blok }) => {
  return (
    <BreadcrumbItem {...storyblokEditable(blok)}>
      <BreadcrumbLink href={blok.link}>{blok.name}</BreadcrumbLink>
    </BreadcrumbItem>
  );
};
export default BreadcrumbBlok;
