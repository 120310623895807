import { useDispatch } from "react-redux";
import {
  VStack,
  Text,
  Button,
  Checkbox,
  Box,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import {Button as HeadlessButton} from '@headlessui/react';

import { getUserObject } from "../redux/actions/user";
import TextInput from "../components/forms/TextInput";

import PasswordInput from "../components/forms/PasswordInput";
import LoadingSpinner from "../components/common/LoadingSpinner";
import React, { useRef, useState } from "react";

import { forgotPassword } from "./api";

import * as N from "../utilities/notifications";
import { useRouter } from "next/router";
import { saveTokenToLocalStorage, logInUser } from "./api";
import {newPasswordChallenge} from "./api/login";
import {FaChevronLeft} from "react-icons/fa6";

const LogInForm = ({
  nextURL = "",
  validatedEmail = '',
  noBorder = false,
  noRedirect = false,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formRef = useRef();
  const [rememberMe, setRememberMe] = useState(true);
  const [updatePassword, setUpdatePassword] = useState(false);

  const handleResetPassword = () => {
    if (
      formRef.current.errors.pwdResetEmail ||
      formRef.current.values.pwdResetEmail === ""
    )
      return;

    forgotPassword(formRef.current.values.pwdResetEmail)
      .then((res) => {
        toast(
          N.sendCodePasswordResetSuccess(formRef.current.values.pwdResetEmail)
        );
        return true;
      })
      .catch((err) => {
        console.error("ERROR", err);
        toast(N.sendCodePasswordResetFail(err));
        return err;
      });
    onClose();
  };

  const handleSetNewPassword = async (data, setSubmitting) => {
    if(data.newPassword !== data.confirmPassword) {
      toast(N.genericFail('Unable to update password', 'Confirm password must match the new password'));
      setSubmitting(false);
    }

    try {
      const passwordUpdated = await newPasswordChallenge(data.newPassword);
      await handleSubmit({...data, password: data.newPassword}, setSubmitting, passwordUpdated);
      setUpdatePassword(false);
    }
    catch(e) {
      toast(N.genericFail('Unable to update password', e));
      setSubmitting(false);
    }
  }

  const handleSubmit = async (data, setSubmitting, token = null) => {
    // Authenticate against Cognito
    try {
      let objUser;
      if(token === null ) {
        const authorisedUserJwtToken = await logInUser(data);
        // User Authenticated, Get user details
        objUser = await dispatch(getUserObject(authorisedUserJwtToken));
      }
      else {
        objUser = await dispatch(getUserObject(token));
      }


      // Set LocalStorage cookie if requested
      if (data.remember) {
        try {
          await saveTokenToLocalStorage(objUser.JWTToken);
        } catch (err) {
          throw new Error(err);
        }
      }

      // Send success toast
      toast(N.loginSuccess);
      if(noRedirect) {
        return true;
      }
      setUpdatePassword(false);
      if (objUser.accountType === 2 && nextURL === "") {
        await router.push("/accounts/practitioner-account/dashboard");
      } else if (nextURL !== "") {
        await router.push(nextURL);
      } else {
        await router.push("/accounts/user-account/dashboard");
      }
      return true;
    } catch (err) {
      if(err === 'newPasswordRequired') {
        setUpdatePassword(true);
        return;
      }
      setUpdatePassword(false);
      // Send fail toast
      console.error(`%cFAILED to log in ${err}`, "color:red; font-weight:bold");
      toast(N.loginFail(JSON.stringify(err)));
      setSubmitting(false);
      return err;
    } finally {
      // Reset formik state
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      validateOnBlur={false}
      initialValues={{
        fullname: "",
        email: validatedEmail,
        password: "",
        newPassword: '',
        confirmPassword: "",
        pwdResetEmail: "",
        remember: rememberMe,
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required("Email required"),
        pwdResetEmail: Yup.string().email("Valid email address required"),
        password: Yup.string()
          .required("Password required")
          .min(8, "Password must be 8 characters or greater."),
        newPassword: updatePassword ? Yup.string()
          .required('New password required')
          .min(8, 'Password must be 8 characters or greater')
          : Yup.string(),
        confirmPassword: Yup.string(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        values.remember = rememberMe;
        if (updatePassword) {
          handleSetNewPassword(values, setSubmitting);
        }
        else {
          handleSubmit(values, setSubmitting);
        }
      }}
    >
      {(formik) => (
        <>
          {formik.isSubmitting && <LoadingSpinner message="logging in..." />}

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Reset your password</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextInput
                  name="pwdResetEmail"
                  formlabel="Email"
                  type="email"
                  placeholder="e.g. robin@yourmail.com"
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  width="100%"
                  onClick={() => handleResetPassword()}
                >
                  Reset password
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <VStack
            as="form"
            maxW="full"
            width='full'
            py={noBorder ? 2 : 7}
            px={noBorder ? 2 : 5}
            mx="auto"
            spacing={4}
            alignItems="flex-start"
            bg="white"
            borderRadius={{ base: 0, lg: 20 }}
            onSubmit={formik.handleSubmit}
            borderColor="brand.200"
            borderWidth={noBorder ? 0 : { base: 0, lg: 0.5 }}
            overflow="hidden"
            position="relative"
          >
            {updatePassword ? (
              <>
                <HeadlessButton className='flex gap-2 items-center text-sm text-brand-alt' onClick={() => setUpdatePassword(false)}>
                  <FaChevronLeft className="h-3 w-3"/>
                  <span>Back to login</span>
                </HeadlessButton>
                <span>
                  Your account has been migrated over from our old system. This requires a password update for security reasons.
                  Please enter a new password below.
                </span>
                <PasswordInput
                  name="newPassword"
                  placeholder="8+characters"
                  formlabel="New password"
                />
                <PasswordInput
                  name="confirmPassword"
                  placeholder="8+characters"
                  formlabel="Confirm new password"
                />
              </>
            ) : (
              <>
                <TextInput
                  name="email"
                  formlabel="Email"
                  type="email"
                  placeholder="e.g. robin@yourmail.com"
                />
                <PasswordInput
                  name="password"
                  placeholder="8+characters"
                  formlabel="Password"
                />
              </>
            )}
            {!updatePassword && (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Checkbox
                  name="remember"
                  textStyle="h7"
                  color="brand.300"
                  size="md"
                  defaultChecked
                  colorScheme="secondary"
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  <Text textStyle="h6">Remember me</Text>
                </Checkbox>

                <Button
                  variant="link"
                  fontSize="sm"
                  bgGradient="linear(to-r, primary.600, primary.500)"
                  bgClip="text"
                  onClick={onOpen}
                >
                  Forgot my password
                </Button>
              </Box>
            )}
            <Box p="1rem 0 0 0" w="100%">
              <Button w="full" variant="solid" type="submit">
                {updatePassword ? 'Set new password' : 'Log in'}
              </Button>
            </Box>
          </VStack>
        </>
      )}
    </Formik>
  );
};

export default LogInForm;
