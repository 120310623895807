import { HStack, Stack } from "@chakra-ui/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const SimpleFlexBlok = ({ blok }) => {
  return (
    <Stack
      direction={blok.direction ?? "row"}
      justifyContent={blok.justify ?? "center"}
      alignItems={blok.alignment ?? "center"}
      mx={[0, blok.pageMargins && "10%"]}
      {...storyblokEditable(blok)}
      py={blok.paddingVertical ?? 0}
      maxW={"100%"}
    >
      {blok &&
        blok.items.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
    </Stack>
  );
};

export default SimpleFlexBlok;
