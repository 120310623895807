export default {
  variants: {
    filled: {
      field: {
        bg: "brand.100", //sand dune
        color: "brand.300", // masala
        _focus: {
          bgColor: "brand.100",
        },
      },
    },
    smallOutline: {
      field: {
        fontSize: "sm",
        border: "1px solid",
        borderColor: "inherit",
        bg: "inherit",
        _hover: {
          borderColor: "brand.100",
        },
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },
        _invalid: {
          borderColor: "brand.100",
          boxShadow: `0 0 0 1px brand.300`,
        },
        _disabled: {
          //borderColor: "red",
          bg: "gray.50",
          color: "gray.300",
        },
        _focusVisible: {
          zIndex: 1,
          borderColor: "brand.100",
          boxShadow: `0 0 0 1px brand.300`,
        },
      },
    },
    addon: {
      border: "1px solid",
      borderColor: "inherit",
      bg: "gray.100",
    },
  },
  baseStyle: {
    field: {
      fontSize: "30px",
      paddingBottom: "0.5rem",
    },
  },
  defaultProps: {},
};
