export default {
  variants: {
    filled: {
      field: {
        bg: "brand.100", //sand dune
        color: "brand.300", // masala
        _focus: {
          bg: "brand.100",
        },
        _hover: {
          bg: "brand.100",
        },

        _focusVisible: {
          bg: "transparent",
          borderColor: "brand.200",
          borderWidth: "0.5px",
        },
      },
      addon: {
        bg: "brand.100",
        border: "2px solid",
        borderColor: "transparent",
      },
    },
    small: {
      field: {
        fontSize: "sm",
        bg: "brand.100", //sand dune
        color: "brand.300", // masala
        _focus: {
          bg: "brand.100",
        },
        _hover: {
          bg: "brand.100",
        },

        _focusVisible: {
          bg: "transparent",
          borderColor: "brand.200",
          borderWidth: "0.5px",
        },
      },
      addon: {
        bg: "brand.100",
        border: "2px solid",
        borderColor: "transparent",
      },
    },
    smallOutline: {
      field: {
        fontSize: "sm",
        border: "1px solid",
        borderColor: "inherit",
        bg: "inherit",
        _hover: {
          borderColor: "brand.100",
        },
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },
        _invalid: {
          borderColor: "brand.100",
          boxShadow: `0 0 0 1px brand.300`,
        },
        _disabled: {
          //borderColor: "red",
          bg: "brand.100",
          color: "brand.200",
        },
        _focusVisible: {
          zIndex: 1,
          borderColor: "brand.100",
          boxShadow: `0 0 0 1px brand.300`,
        },
      },
      addon: {
        border: "1px solid",
        borderColor: "inherit",
        bg: "gray.100",
      },
    },
  },

  defaultProps: {},
};
